import ReconAppPage from '../ReconAppPage'

const ReconHome = () => {
  console.log('ReconHome')
  return (
    <>
      <ReconAppPage header='Recon Home'>
        <div>Recon Dashboard Goes Here</div>
      </ReconAppPage>
    </>
  )
}

export default ReconHome
