// ui/src/components/MiniCalendarNav/WeeksView.tsx

import React from 'react'

import useCalendarLogic from './useCalendarLogic'
import useDisplayMonthYear from './useDisplayMonthYear'
import WeekRow from './WeekRow'

import type { SelectionMode } from './calendarTypes'

interface WeeksViewProps {
  selectionMode: SelectionMode
}

const WeeksView: React.FC<WeeksViewProps> = ({ selectionMode }) => {
  const { year, month } = useDisplayMonthYear(false)

  const weeks = useCalendarLogic({
    displayedYear: year,
    displayedMonth: month,
  })

  // Grab the 6-week matrix for the current displayed month/year

  return (
    <>
      {weeks.map(weekWithDates => {
        return <WeekRow key={weekWithDates.id} selectionMode={selectionMode} monthNumber={month} {...weekWithDates} />
      })}
    </>
  )
}

export default WeeksView
