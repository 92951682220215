import { ButtonGroup } from '@atlaskit/button'

import DateRangeActions from '@/components/DateRangeActions'
import DownloadFinancialsButton from './DownloadFinancialsButton'

const linkToSetter = (startDate: string, endDate: string) => `/recon/financials/work-order/${startDate}/${endDate}`

const FinancialsByWorkOrderActions = ({
  fallbackStartDate,
  fallbackEndDate,
  startDate,
  endDate,
}: {
  fallbackStartDate?: string
  fallbackEndDate?: string
  startDate: string
  endDate: string
}) => {
  return (
    <div style={{ display: 'flex', gap: 12, alignItems: 'flex-end' }}>
      <DateRangeActions linkToSetter={linkToSetter} fallbackStartDate={fallbackStartDate} fallbackEndDate={fallbackEndDate} />
      <ButtonGroup label='Recon Actions'>
        {Boolean(startDate && endDate) && <DownloadFinancialsButton startDate={startDate} endDate={endDate} />}
      </ButtonGroup>
    </div>
  )
}

export default FinancialsByWorkOrderActions
