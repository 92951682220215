import { Temporal } from '@js-temporal/polyfill'
import { useMemo } from 'react'
import styled from 'styled-components'

import { GetInstallerPayrollQuery } from '@/gql/graphql'

import HrMinCell from '@/components/HrMinCell'
import useSelectedWeekYear from '@/components/MiniCalendarNav/useSelectedWeekYear'
import NumberCell from '@/components/NumberCell'

import usePaystubQuery from '../usePaystubQuery'

import { getPieceRateAddonPayouts, NEUTRAL_300 } from './paystubUtils'
import PrintTableDispatches from './PrintPaystubTableDispatches'

type InstallerPayroll = NonNullable<GetInstallerPayrollQuery['installerPayroll']>
type InstallerDispatch = NonNullable<InstallerPayroll['installerDispatches']>[number]

const PrintInstallerPaystub = () => {
  const { data } = usePaystubQuery()
  const { mondayDate } = useSelectedWeekYear()

  const installerPayroll = data?.installerPayroll
  const weekYearTitle = `${installerPayroll?.weekYear}-w${installerPayroll?.weekNumber}`
  const weekStartDate = !mondayDate ? 'No Week' : mondayDate.toLocaleString('en-US', { weekday: 'short', month: 'short', day: 'numeric' })
  const weekEndDate = !mondayDate
    ? 'No Week'
    : mondayDate.add({ days: 6 }).toLocaleString('en-US', { weekday: 'short', month: 'short', day: 'numeric' })

  const paystubAttributes: Record<string, string>[] = [
    {
      label: 'Week Start',
      value: weekStartDate,
    },
    {
      label: 'Week End',
      value: weekEndDate,
    },
    {
      label: 'Installer',
      value: installerPayroll?.installer?.fullName ?? '',
    },
  ]

  const title = `${weekYearTitle} • ${installerPayroll?.installer?.fullName}`

  const installerDispatches = installerPayroll?.installerDispatches
  const minDriveSum: number = !installerDispatches
    ? 0
    : installerDispatches.reduce((acc, dispatch) => {
        const { weekDriveNumber } = getPieceRateAddonPayouts(dispatch)
        if (weekDriveNumber === null) return acc
        return (acc ?? 0) + weekDriveNumber
      }, 0)

  const isHourlyInstaller = installerPayroll?.installerWeeklyPayroll?.isHourlyInstaller ?? false
  const payroll = installerPayroll?.installerWeeklyPayroll

  const minManual = Number(payroll?.minutesAdjustmentManual ?? 0)
  const minDispatch = Number(payroll?.totalDispatchesMinutesTotal ?? 0) - minDriveSum

  const payOvertime = Number(payroll?.payoutOvertimeUplift ?? 0)
  const payNonDispatch = Number(payroll?.totalDayPayoutNonDispatch ?? 0)
  const payAdjustments = Number(payroll?.payoutAdjustment ?? 0) + Number(payroll?.payoutMinimumWageCorrection ?? 0)

  const payoutActual = Number(payroll?.payoutActual ?? 0)

  const { dispatches, dbDispatches } = useMemo(() => {
    const davisBacons: InstallerDispatch[] = []
    const rest: InstallerDispatch[] = []
    if (installerDispatches)
      installerDispatches.sort(sortDispatches).forEach(d => {
        if (d.isDavisBacon) davisBacons.push(d)
        else rest.push(d)
      })
    return { dispatches: rest, dbDispatches: davisBacons }
  }, [installerDispatches])

  return (
    <PageWrapper>
      <title>{title}</title>
      <PrintableWrapper>
        <div className='main-row-header'>
          <div className='logo-title-column'>
            <div className='title-info-column'>
              <div className='work-order-category'>Weekly Paystub</div>

              <h2 className='work-order-title'>{title}</h2>
            </div>
          </div>

          <div className='sheet-properties-column'>
            <div className='attribute-column'>
              {paystubAttributes.map(pa => (
                <div className='attribute'>{pa.label}</div>
              ))}
            </div>

            <div className='values-column'>
              {paystubAttributes.map(pa => (
                <div className='value'>{pa.value}</div>
              ))}
            </div>
          </div>
        </div>

        {dbDispatches.length > 0 ? (
          <>
            <h4 style={{ margin: '8px 0 4px 0' }}>Davis Bacon Dispatches</h4>
            <PrintTableDispatches installerPayroll={installerPayroll} dispatches={dbDispatches} isDavisBacon={true} />
          </>
        ) : null}
        <PrintTableDispatches installerPayroll={installerPayroll} dispatches={dispatches} isDavisBacon={false} />

        <div className='totals-section'>
          <div className='totals-minutes-column'>
            <table>
              <thead>
                <tr>
                  <th colSpan={2} style={{ padding: '4px 8px' }}>
                    Hours Totals
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Dispatch Hours</td>
                  <td>
                    <HrMinCell value={minDispatch} />
                  </td>
                </tr>

                <tr>
                  <td>Warehouse Hours</td>
                  <td>
                    <HrMinCell value={payroll?.totalDayMinutesNonDispatchPayable ?? 0} />
                  </td>
                </tr>

                {!isHourlyInstaller || minDriveSum === 0 ? null : (
                  <tr>
                    <td>Drive Hours</td>
                    <td>
                      <HrMinCell value={minDriveSum} />
                    </td>
                  </tr>
                )}

                {minManual === 0 ? null : (
                  <tr>
                    <td>Adjustment</td>
                    <td>
                      <HrMinCell value={payroll?.minutesAdjustmentManual ?? 0} />
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td>Total Hours</td>
                  <td>
                    <HrMinCell value={payroll?.minutesTotal ?? 0} />
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          {/* Payout Totals */}
          <div className='totals-payout-column'>
            <table>
              <thead>
                <tr>
                  <th colSpan={2} style={{ padding: '4px 8px' }}>
                    Payout Totals
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Dispatch Payout</td>
                  <td>
                    <NumberCell value={payroll?.totalDispatchesPayoutTotal ?? 0} prefix='$' readOnly />
                  </td>
                </tr>

                {payOvertime === 0 ? null : (
                  <tr>
                    <td>Overtime Pay</td>
                    <td>
                      <NumberCell value={payOvertime} prefix='$' readOnly />
                    </td>
                  </tr>
                )}

                <tr>
                  <td>Warehouse Hrs</td>
                  <td>
                    <NumberCell value={payNonDispatch} prefix='$' readOnly />
                  </td>
                </tr>

                {payAdjustments === 0 ? null : (
                  <tr>
                    <td>Adjustment</td>
                    <td>
                      <NumberCell value={payAdjustments} prefix='$' readOnly />
                    </td>
                  </tr>
                )}
              </tbody>

              <tfoot>
                <tr>
                  <td>Paycheck</td>
                  <td>
                    <NumberCell value={payoutActual} prefix='$' readOnly />
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </PrintableWrapper>
    </PageWrapper>
  )
}

export default PrintInstallerPaystub
function sortDispatches(a: InstallerDispatch, b: InstallerDispatch) {
  const aDate = a?.vehicleDispatch?.dispatch?.date ? Temporal.PlainDate.from(a.vehicleDispatch.dispatch.date) : Temporal.Now.plainDateISO()

  const bDate = b?.vehicleDispatch?.dispatch?.date ? Temporal.PlainDate.from(b.vehicleDispatch.dispatch.date) : Temporal.Now.plainDateISO()

  return Temporal.PlainDate.compare(aDate, bDate)
}
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  position: relative;
  color: #000;
  background-color: #fff;
  overflow: auto;
`
const PrintableWrapper = styled.div`
  flex: 1;
  /* border: 3px solid blue; */
  display: flex;
  flex-direction: column;
  align-items: stretch;

  position: relative;
  padding: 24px;
  max-width: 800px;
  background-color: #fff;

  .totals-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 24px;
    margin-bottom: 32px;

    table {
      border: 1px solid ${NEUTRAL_300};
      border-radius: 4px;
      box-sizing: border-box;
      * {
        box-sizing: border-box;
      }

      tr > td {
        &:first-child {
          padding: 4px 8px;
        }
      }

      tfoot {
        td {
          font-weight: 600;
        }
      }
    }
  }

  .material-note-column {
    min-width: 140px;
    max-width: 200px;
    white-space: normal;
    word-wrap: break-word;
  }

  .work-order-category {
    font-size: 13px;
    font-weight: 600;
    margin: 0;
    padding-bottom: 4px;
    text-transform: uppercase;
    color: #999;
  }

  .logo-title-column {
    display: flex;
    flex: 1 1 auto;
  }

  .logo-wrapper {
    flex: 0 0 auto;
    width: 72px;
    height: 72px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title-info-column {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .work-order-title {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .installers-section {
    margin-bottom: 4px;
    font-size: 13px;
  }

  .sheet-properties-column {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 0 0 auto;
    width: 180px;
  }

  .attribute-column,
  .values-column {
    position: relative;
    display: flex;
    flex-direction: column;
    /* gap: 4px; */
    margin-left: 8px;
    flex: 0 0 auto;
    overflow: hidden;
  }

  .attribute {
    font-weight: 600;
    text-align: right;
    margin-bottom: 4px;
  }

  .value {
    text-align: left;
    margin-bottom: 4px;
  }
  .main-row-header {
    position: relative;
    width: 100%;
    max-width: 100%;

    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .main-row-vehicles {
    position: relative;
    width: 100%;
    max-width: 100%;

    display: flex;
    justify-content: space-between;

    padding: 0;
    margin-bottom: 8px;
  }

  .vehicle-column {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 6px 8px;
    border: 1px solid #999;
    border-radius: 4px;
  }

  .vehicle-title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  .vehicle-text {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 4px;
  }

  .main-row-subheader {
    position: relative;
    width: 100%;
    max-width: 100%;

    display: flex;
    justify-content: space-between;

    padding: 0 4px;
    margin-bottom: 4px;
  }

  .subheader-column {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    min-width: 200px;
    margin-right: 8px;
  }

  .subheader-title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  .subheader-text {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 8px;
  }

  .main-row-notes {
    position: relative;
    width: 100%;
    max-width: 100%;

    display: flex;
    flex-direction: column;
    align-items: stretch;

    padding: 0 4px;
    margin-bottom: 8px;
  }

  .notes-text {
    font-size: 12px;
    font-weight: 400;
    width: 100%;
    min-width: 100%;
  }
`
