import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'

import { IconButton } from '@atlaskit/button/new'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'
import MoreIcon from '@atlaskit/icon/glyph/more'
import { token } from '@atlaskit/tokens'

import useGraphQLClient from '@/utils/useAuthRequest'

import { graphql } from '@/gql'

import DeleteEstimateModal, { JobQueryEstimateType } from './DeleteEstimateModal'
import { GET_JOB_QUERY_KEY } from './JobPage'

const EstimateTableDropdown = ({ estimate }: { estimate: JobQueryEstimateType }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const graphQLClient = useGraphQLClient()
  const queryClient = useQueryClient()
  const workOrdersCount = estimate.workOrders?.length ?? 0
  const { mutate: createWorkOrders, isPending } = useMutation({
    mutationFn: async () => {
      const result = await graphQLClient.request(CREATE_DEFAULT_WORK_ORDERS, { estimateId: estimate.id })
      if (!result.createWorkOrders.success) {
        console.error(result.createWorkOrders.message)
        throw new Error(result.createWorkOrders.message)
      }
      return result
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_JOB_QUERY_KEY] })
    },
  })

  return (
    <>
      <DropdownMenu
        placement='bottom-end'
        trigger={({ triggerRef, ...props }) => <IconButton {...props} icon={MoreIcon} label='more' ref={triggerRef} />}
      >
        <DropdownItemGroup>
          <DropdownItem isDisabled={isPending || workOrdersCount > 0} onClick={() => createWorkOrders()}>
            Create Work Orders
          </DropdownItem>

          <DropdownItem onClick={() => setShowDeleteModal(true)}>
            <span style={{ color: token('color.text.danger') }}>Delete Estimate</span>
          </DropdownItem>
        </DropdownItemGroup>
      </DropdownMenu>

      <DeleteEstimateModal estimate={estimate} onClose={() => setShowDeleteModal(false)} isOpen={showDeleteModal} />
    </>
  )
}

export default EstimateTableDropdown

const CREATE_DEFAULT_WORK_ORDERS = graphql(/* GraphQL */ `
  mutation CreateWorkOrders($estimateId: UUID!) {
    createWorkOrders(estimateId: $estimateId) {
      success
      message
    }
  }
`)
