import { AccessorFnColumnDef, createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'

import { token } from '@atlaskit/tokens'

import { Staff } from '@/utils/queryHooks/useStaffListQuery'

import FieldNameCellWithTooltip from '../components/FieldNameCellWithTooltip'
import PayrollCell from '../components/PayrollCell'
import { COL_1_WIDTH, COL_2_WIDTH, ReconTableRowDef } from '../reconPayrollTypes'
import useHiddenInstallerIds from './useHiddenInstallerIds'
import useInstallersList from './useInstallersList'

const useReconPayrollColumnDefs = () => {
  const { installers } = useInstallersList()

  const hiddenInstallerIds = useHiddenInstallerIds()
  const filteredInstallers = useMemo(
    () => installers.filter(installer => !hiddenInstallerIds.includes(installer.id)),
    [installers, hiddenInstallerIds]
  )
  const result = useMemo(() => getColumnDefs(filteredInstallers), [filteredInstallers])

  return result
}

export default useReconPayrollColumnDefs

const columnHelper = createColumnHelper<ReconTableRowDef>()

const rowHeaderColumns = [
  columnHelper.group({
    header: ' ',
    columns: [
      columnHelper.accessor('topHeader', {
        id: 'topHeader',
        header: 'Section',
        size: COL_1_WIDTH,
        cell: info => (
          <div>
            <span style={{ fontWeight: '500', color: token('color.text.subtlest') }}>{info.getValue()}</span>
          </div>
        ),
      }),
      columnHelper.accessor('fieldTitle', {
        id: 'fieldTitle',
        header: 'Field',
        size: COL_2_WIDTH,
        cell: info => (
          <FieldNameCellWithTooltip
            installerPayrollTooltip={info.row.original.installerPayrollTooltip}
            lookupTooltip={info.row.original.lookupTooltip}
            fieldTitle={info.getValue()}
          />
        ),
      }),
    ],
  }),
]

function getColumnDefs(installers: Staff[] | undefined) {
  const hourlyColumnsList: AccessorFnColumnDef<ReconTableRowDef, React.ReactNode>[] = []
  const pieceRateColumnsList: AccessorFnColumnDef<ReconTableRowDef, React.ReactNode>[] = []

  if (!installers || installers.length === 0) return rowHeaderColumns

  installers.forEach(installer => {
    const isHourly = installer.isHourly ?? false
    const installerName = installer.lastName + ', ' + installer.firstName
    const installerId = installer.id

    const mainColumn = columnHelper.accessor(row => row.id, {
      header: installerName,
      id: installerId,
      cell: ({ row: { original } }) => <PayrollCell id={original.id} installerId={installerId} isLookup={false} />,
    }) as AccessorFnColumnDef<ReconTableRowDef, React.ReactNode>

    if (isHourly) hourlyColumnsList.push(mainColumn)
    else pieceRateColumnsList.push(mainColumn)

    // const secondColumn = columnHelper.accessor(row => row.id, {
    //   header: 'Live Stats',
    //   meta: { isLookup: true },
    //   id: installerId + '-Live',
    //   cell: ({ row: { original } }) => <PayrollCell id={original.id} installerId={installerId} isLookup={true} />,
    // }) as AccessorFnColumnDef<ReconTableRowDef, React.ReactNode>

    // if (isHourly) hourlyColumnsList.push(secondColumn)
    // else pieceRateColumnsList.push(secondColumn)
  })

  const hourlyColumns =
    hourlyColumnsList.length === 0 ? [] : [columnHelper.group({ header: 'Hourly Installers', columns: hourlyColumnsList })]

  const pieceRateColumns =
    pieceRateColumnsList.length === 0 ? [] : [columnHelper.group({ header: 'Piece Rate Installers', columns: pieceRateColumnsList })]

  const result = [...rowHeaderColumns, ...hourlyColumns, ...pieceRateColumns]
  console.log('Recalculated column definitions', result)
  return result
}
