import { Temporal } from '@js-temporal/polyfill'
import { toast } from 'sonner'

import { GetInstallerPayrollQuery } from '@/gql/graphql'

import HoursMinutesInput from '@/components/HoursMinutesInput'
import HrMinCell from '@/components/HrMinCell'
import NumberCell from '@/components/NumberCell'

import useSubmitInstallerPayroll from '../ReconPayroll/hooks/useSubmitInstallerPayroll'

type SingleInstallerPayroll = NonNullable<GetInstallerPayrollQuery['installerPayroll']>
type InstallerWeeklyPayroll = NonNullable<SingleInstallerPayroll['installerWeeklyPayroll']>
type InstallerDate = NonNullable<InstallerWeeklyPayroll['installerDates']>[number]

const InstallerWeekDateRow = ({
  date,
  installerDate,
  isDisabled,
  weekOf,
}: {
  date: string
  installerDate?: InstallerDate
  isDisabled: boolean
  weekOf?: string
}) => {
  const payoutTotal = Number(installerDate?.dispatchesMinutesTotal ?? 0) + Number(installerDate?.payoutNonDispatch ?? 0)
  const dateString = Temporal.PlainDate.from(date).toLocaleString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  })

  const { mutate } = useSubmitInstallerPayroll()
  return (
    <tr>
      <td>{dateString}</td>
      <td className='minutes-cell'>
        <HrMinCell value={installerDate?.dispatchesMinutesDrive} />
      </td>
      <td className='minutes-cell'>
        <HrMinCell value={installerDate?.dispatchesMinutesWorked} />
      </td>
      <td className='minutes-cell'>
        <HrMinCell value={installerDate?.dispatchesMinutesTotal} />
      </td>
      <td className='minutes-cell'>
        {isDisabled ? (
          <HrMinCell value={installerDate?.minutesNonDispatch} />
        ) : (
          <HoursMinutesInput
            minutes={installerDate?.minutesNonDispatch ?? null}
            onChangeCommit={handleMinutesActualChange}
            isDisabled={isDisabled}
          />
        )}
      </td>
      <td className='minutes-cell'>
        {isDisabled ? (
          <HrMinCell value={installerDate?.minutesActual} />
        ) : (
          <HoursMinutesInput
            minutes={installerDate?.minutesActual ?? null}
            onChangeCommit={handleMinutesActualChange}
            isDisabled={isDisabled}
          />
        )}
      </td>
      <td className='minutes-cell'>
        <HrMinCell value={installerDate?.minutesLunch} />
      </td>
      <td className='minutes-cell'>
        <HrMinCell value={installerDate?.minutesPayable} />
      </td>
      <td className='payout-cell'>
        <NumberCell value={installerDate?.dispatchesPayoutTotal} readOnly prefix='$' />
      </td>
      <td className='payout-cell'>
        <NumberCell value={installerDate?.payoutNonDispatch} readOnly prefix='$' />
      </td>
      <td className='payout-cell'>
        <NumberCell value={payoutTotal} readOnly prefix='$' />
      </td>
    </tr>
  )

  function handleMinutesActualChange(minutes: number | null) {
    if (!installerDate || !weekOf) return
    const newMinutes = minutes ?? null
    if (newMinutes) {
      const dispatchMinutes = Number(installerDate.dispatchesMinutesTotal ?? 0)
      if (minutes && minutes < dispatchMinutes) {
        toast.warning(
          `Total hours must be at least equal to dispatch hours (${Math.floor(dispatchMinutes / 60)}:${String(dispatchMinutes % 60).padStart(2, '0')})`
        )
        return
      }
    }
    mutate({
      installerId: installerDate.installerId,
      date: installerDate.date,
      attribute: 'minutesActual',
      value: `${newMinutes}`,
      weekOf: weekOf,
    })
  }
}

export default InstallerWeekDateRow
