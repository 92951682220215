import { Staff } from '@/utils/queryHooks/useStaffListQuery'

import HrMinCell from '../../../../components/HrMinCell'
import { usePayrollFieldValue } from '../hooks/useReconPayrollSelectors'
import { ColumnDef } from '../hooks/useTimesheetColumnDefs'

import EditTimecardCell from './EditTimecardCell'

interface WeeklyTimecardCellProps {
  installer: Staff
  col: ColumnDef
}

const WeeklyTimecardCell = ({ installer, col }: WeeklyTimecardCellProps) => {
  const { accessor: selector, useNumberCell } = col
  const installerId = installer.id
  const value = usePayrollFieldValue({ installerId, selector })

  if (col.field && col.dayIndex) return <EditTimecardCell dayIndex={col.dayIndex} fieldName={col.field} installerId={installer.id} />

  if (useNumberCell) return <HrMinCell value={`${value ?? 0}`} />

  return <div>{value}</div>
}

export default WeeklyTimecardCell
