import { useLocation, useNavigate } from 'react-router'

import { ButtonItem, NestingItem, Section } from '@atlaskit/side-navigation'

const RenderNavLayer = ({ navLayer }: { navLayer: NavLayer }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const path = location.pathname

  // Recursive function to render nested navigation
  const renderLinks = (links: LinkItem[]) => {
    return links.map(linkItem => {
      const baseComponent = (
        <ButtonItem
          key={linkItem.link}
          isSelected={linkItem?.isSelected ? linkItem.isSelected(path) : path === linkItem.link}
          onClick={() => navigate(linkItem.link)}
          description={linkItem.description}
          iconBefore={linkItem.iconBefore}
        >
          {linkItem.label}
        </ButtonItem>
      )

      // If there's a nested layer, wrap the button in a NestingItem component
      if (linkItem.nestedLayer) {
        return (
          <NestingItem
            key={linkItem.link}
            id={linkItem.nestedLayer.nestingId ?? linkItem.label}
            title={linkItem.label}
            onClick={() => navigate(linkItem.link)}
            iconBefore={linkItem.iconBefore}
          >
            {/* {linkItem.nestedLayer.backLabel && (
              <BackComponent
                backLabel={linkItem.nestedLayer.backLabel}
                backNavigateTo={linkItem.nestedLayer.backNavigateTo + '/' + paramValues.toString()}
              />
            )} */}
            {linkItem.nestedLayer.linkSections.map((nestedSection, index) => (
              <Section key={index} title={nestedSection.sectionHeader || undefined} hasSeparator isList>
                {renderLinks(nestedSection.links)}
              </Section>
            ))}
            {!linkItem.nestedLayer.customComponent ? null : (
              <Section title={linkItem.nestedLayer.customComponentTitle ?? 'Section'}>{linkItem.nestedLayer.customComponent}</Section>
            )}
          </NestingItem>
        )
      }

      return baseComponent
    })
  }

  return navLayer.linkSections.map((section, ind) => (
    <Section title={section?.sectionHeader || undefined} key={ind} hasSeparator isList>
      {renderLinks(section.links)}
    </Section>
  ))
}

export default RenderNavLayer

export type LinkItem = {
  label: string
  link: string
  isSelected?: (pathname: string) => boolean
  iconBefore?: React.ReactNode
  nestedLayer?: NavLayer
  description?: string
}
export type LinkSection = {
  sectionHeader: string
  links: LinkItem[]
}

export type NavLayer = {
  headerTitle: string
  headerDescription: string
  linkSections: LinkSection[]
  customComponent?: React.ReactNode
  customComponentTitle?: string
  backNavigateTo?: string
  backLabel?: string
  stack?: string[]
  nestingId?: string
}
