import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { Checkbox } from '@atlaskit/checkbox'
import { token } from '@atlaskit/tokens'

import { GET_PAYROLL_DAY_VEHICLES_QUERY_KEY } from '@/utils/api/getPayrollDayVehicles'
import { GET_VEHICLE_DATE_QUERY_KEY } from '@/utils/api/getVehicleDate'
import useGraphQLClient from '@/utils/useAuthRequest'
import useCurrentUser from '@/utils/useCurrentUser'
import { CardColorOptions, COLOR_OPTIONS } from '@/utils/utilities'

import { GetDispatchPayoutDetailsQuery, SubmitInstallerPayoutValueMutationVariables } from '@/gql/graphql'

import { graphql } from '@/gql'
import { RECON_MONTH_STATS_QUERY_KEY } from '@/pages/recon/ReconCalendar/useReconMonthStats'
import useReconSearchParams from '@/pages/recon/useReconSearchParams'

import HoursMinutesInput from '@/components/HoursMinutesInput'
import NumberCell from '@/components/NumberCell'
import TableCellNumberTextField from '@/components/TableCellNumberTextField'

import { GET_DISPATCH_PAYOUT_DETAILS_QUERY_KEY } from '../DispatchSection'

import InstallersTableNameCell from './InstallersTableNameCell'
import PayoutModeCell from './PayoutModeCell'

type WorkOrder = GetDispatchPayoutDetailsQuery['workOrder']
type Dispatch = WorkOrder['dispatches'][number]
type InstallerAssignments = Dispatch['installerAssignments'][number]

const CELL_PAD = '4px 8px'

interface InstallerProperties {
  percentPieceRate?: string
  minutesWorked?: number | null
  isPercentLocked?: boolean
  isPayrollLocked?: boolean
  canHourlyReceiveAddons?: boolean
  isChanged?: boolean
  isHourlyPayout?: boolean
  hourlyRate?: string
  minutesDrivePayable?: string
  hourlyRateDrive?: string
  isCompleted?: boolean
  isApproved?: boolean
}

interface InstallersTableRowProps {
  installerDispatch: InstallerAssignments
  isLastOfVehicle: boolean
  isFirstOfVehicle: boolean
  vehicleInstallerCount: number
  showAddonsToggle?: boolean
  showPrGap?: boolean
}

const InstallersTableRow = ({
  installerDispatch,
  isLastOfVehicle = false,
  isFirstOfVehicle = true,
  vehicleInstallerCount,
  showAddonsToggle = false,
  showPrGap = false,
}: InstallersTableRowProps) => {
  const { canApprovePayout } = useCurrentUser()
  const { paramValues } = useReconSearchParams()
  const dateId = paramValues.get('dateId')

  const graphQLClient = useGraphQLClient()
  const queryClient = useQueryClient()
  const [installerProps, setInstallerAmounts] = useState<InstallerProperties>({})

  const { mutate } = useMutation({
    mutationFn: async (variables: SubmitInstallerPayoutValueMutationVariables) => {
      const response = await graphQLClient.request(SUBMIT_INSTALLER_PAYOUT_VALUE, variables)
      if (!response.submitInstallerPayoutValue?.success)
        throw new Error(response.submitInstallerPayoutValue?.message ?? 'Failed to submit addon')
      return response.submitInstallerPayoutValue
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: [GET_DISPATCH_PAYOUT_DETAILS_QUERY_KEY] })
      console.log('onSuccess: ', { variables })
      if (['isCompleted', 'isApproved'].includes(variables.attribute)) {
        console.log('onSuccess: invalidating reconMonthStats')

        queryClient.invalidateQueries({ queryKey: [RECON_MONTH_STATS_QUERY_KEY] })
        queryClient.invalidateQueries({ queryKey: [GET_PAYROLL_DAY_VEHICLES_QUERY_KEY, dateId] })
      }
      if (variables.attribute === 'isCompleted' || variables.attribute === 'isApproved') {
        console.log('onSuccess: invalidating getVehicleDate')
        queryClient.invalidateQueries({ queryKey: [GET_VEHICLE_DATE_QUERY_KEY] })
      }
    },
  })

  const {
    id: installerDispatchId,
    vehicleName,
    isHourlyInstaller,
    isPayrollLocked,
    isDavisBacon,
    calculatedPayoutPieceRate,
    payoutDrive,
    payoutHourly,
    payoutDavisBacon,
    payoutPieceRateDavisBaconGap,
    calculatedPayoutAddons,
    payoutTotal,
  } = installerDispatch

  useEffect(() => {
    if (installerProps?.percentPieceRate && installerDispatch.percentPieceRate === installerProps.percentPieceRate)
      setInstallerAmounts(prev => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { percentPieceRate: _, ...rest } = prev
        return rest
      })
  }, [installerProps?.percentPieceRate, installerDispatch.percentPieceRate])

  const hourlyRate = installerProps?.hourlyRate ?? installerDispatch.hourlyRate
  const minutesWorked: number | null = installerProps?.minutesWorked ?? installerDispatch.minutesWorked ?? null
  const percentPieceRate = installerProps?.percentPieceRate ?? installerDispatch.percentPieceRate

  const isHourlyPayout = installerProps?.isHourlyPayout ?? installerDispatch.isHourlyPayout
  const payoutPieceRate = calculatedPayoutPieceRate
  const isPercentLocked = installerProps?.isPercentLocked ?? installerDispatch.isPercentLocked
  const canHourlyReceiveAddons = installerProps?.canHourlyReceiveAddons ?? installerDispatch.canHourlyReceiveAddons

  const hourlyRateDrive = installerProps?.hourlyRateDrive ?? installerDispatch.hourlyRateDrive
  const minutesDrivePayable =
    installerProps?.minutesDrivePayable ?? installerDispatch.minutesDrivePayableOverride ?? installerDispatch.minutesDrivePayable

  const isCompleted = installerProps?.isCompleted ?? installerDispatch.isCompleted
  const isApproved = installerProps?.isApproved ?? installerDispatch.isApproved

  console.log('InstallerDispatchRow: ', { installerDispatch, installerProps })
  const vehicleColor = (
    COLOR_OPTIONS.includes((installerDispatch.vehicleColor ?? '') as CardColorOptions)
      ? (installerDispatch.vehicleColor as CardColorOptions)
      : 'gray'
  ) as CardColorOptions
  const vehicleBackground = !vehicleColor ? token('elevation.surface') : token(`color.background.accent.${vehicleColor}.subtlest`)
  const color = token(`color.text.accent.${vehicleColor}`)

  return (
    <tr key={installerDispatch.id}>
      <td
        style={{
          background: vehicleBackground,
          color,
          borderBottomColor: isLastOfVehicle ? undefined : vehicleBackground,
          whiteSpace: vehicleInstallerCount === 1 ? 'nowrap' : 'normal',
          fontWeight: 'bold',
        }}
        className={isFirstOfVehicle ? 'vehicle-name-cell' : ''}
      >
        {isFirstOfVehicle ? vehicleName : ''}
      </td>

      <td>
        <InstallersTableNameCell installer={installerDispatch.installer} isHourlyInstaller={isHourlyInstaller === true} />
      </td>

      <ButtonTd>
        <PayoutModeCell
          isDavisBacon={isDavisBacon}
          isHourlyPayout={isHourlyPayout}
          isCompleted={isCompleted}
          onClick={handlePayoutModeChange}
        />
      </ButtonTd>

      <td>
        <HoursMinutesInput
          usedWithReadOnly
          minutes={minutesWorked ? Number(minutesWorked) : null}
          isDisabled={isCompleted}
          onChangeCommit={(newMinutes: number | null) => {
            console.log('onChangeCommit: ', { newMinutes })
            const value = newMinutes === null ? '' : newMinutes.toString()
            setInstallerAmounts(prev => ({ ...prev, minutesWorked: newMinutes }))
            mutate({ installerDispatchId, attribute: 'minutesWorked', value })
          }}
        />
      </td>

      <td>
        <TableCellNumberTextField
          isCompact
          isDisabled={(!isHourlyPayout && !isDavisBacon) || isCompleted}
          value={hourlyRate ?? undefined}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInstallerAmounts(prev => ({ ...prev, hourlyRate: e.target.value }))}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            const value = e.target.value === '' ? '' : Number(e.target.value).toFixed(2)
            setInstallerAmounts(prev => ({ ...prev, hourlyRate: value }))
            mutate({ installerDispatchId, attribute: 'hourlyRate', value })
          }}
          onKeyDown={handleReturn}
          onFocus={handleFocus}
          suffix='/ hr'
          prefix='$'
        />
      </td>

      <td>
        <NumberCell padding={CELL_PAD} readOnly value={isDavisBacon ? payoutDavisBacon : payoutHourly} prefix='$' />
      </td>

      <td>
        <HoursMinutesInput
          usedWithReadOnly
          minutes={minutesDrivePayable === null ? null : Number(minutesDrivePayable)}
          isDisabled={isCompleted}
          onChangeCommit={(newMinutes: number | null) => {
            const value = !newMinutes ? '' : newMinutes.toString()
            setInstallerAmounts(prev => ({ ...prev, minutesDrivePayable: value }))
            mutate({ installerDispatchId, attribute: 'minutesDrivePayable', value })
          }}
        />
      </td>

      <td>
        <TableCellNumberTextField
          isCompact
          isDisabled={Boolean(isHourlyPayout) || isCompleted}
          value={isHourlyPayout ? (hourlyRate ?? undefined) : (hourlyRateDrive ?? undefined)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInstallerAmounts(prev => ({ ...prev, hourlyRateDrive: e.target.value }))}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            const value = e.target.value === '' ? '' : Number(e.target.value).toFixed(2)
            setInstallerAmounts(prev => ({ ...prev, hourlyRateDrive: value }))
            mutate({ installerDispatchId, attribute: 'hourlyRateDrive', value })
          }}
          onKeyDown={handleReturn}
          onFocus={handleFocus}
          suffix='/ hr'
          prefix='$'
        />
      </td>

      <td>
        <NumberCell padding={CELL_PAD} readOnly value={payoutDrive} prefix='$' />
      </td>

      <td>
        <TableCellNumberTextField
          isCompact
          isDisabled={isPercentLocked || isPayrollLocked || isCompleted}
          value={percentPieceRate ?? undefined}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setInstallerAmounts(prev => ({ ...prev, percentPieceRate: e.target.value }))
          }
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            let value: number | string = e.target.value === '' ? Number(0) : Number(e.target.value)
            if (value > 100) value = Number('100.00')
            value = value.toFixed(2)
            setInstallerAmounts(prev => ({ ...prev, percentPieceRate: value }))
            mutate({ installerDispatchId, attribute: 'percentPieceRate', value })
          }}
          onKeyDown={handleReturn}
          onFocus={handleFocus}
          suffix='%'
        />
      </td>

      <td className='no-padding'>
        <div className='checkbox-wrapper'>
          <Checkbox isChecked={isPercentLocked || isCompleted} onChange={handleCheckboxChange} isDisabled={isCompleted} />
        </div>
      </td>

      <td>
        <NumberCell
          padding={CELL_PAD}
          readOnly
          value={payoutPieceRate}
          prefix='$'
          strikethrough={isHourlyPayout === true || isDavisBacon === true}
        />
      </td>

      {!showPrGap ? null : (
        <td>
          <NumberCell padding={CELL_PAD} readOnly value={payoutPieceRateDavisBaconGap} prefix='$' />
        </td>
      )}

      {!showAddonsToggle ? null : (
        <td>
          <div className='checkbox-wrapper'>
            <Checkbox
              isChecked={canHourlyReceiveAddons || (!isDavisBacon && !isHourlyPayout)}
              onChange={handleAddonsCheckboxChange}
              isDisabled={isCompleted || (!isDavisBacon && !isHourlyPayout)}
            />
          </div>
        </td>
      )}

      <td>
        <NumberCell padding={CELL_PAD} readOnly value={calculatedPayoutAddons} prefix='$' strikethrough={!canHourlyReceiveAddons} />
      </td>

      <td style={{ boxShadow: 'inset 1px 0px 0px transparent' }}>
        <NumberCell padding={CELL_PAD} readOnly value={payoutTotal} prefix='$' />
      </td>

      {isFirstOfVehicle && (
        <>
          <td rowSpan={vehicleInstallerCount} style={{ background: vehicleBackground }}>
            <div className='checkbox-wrapper'>
              <Checkbox isChecked={isCompleted} onChange={handleIsCompletedChange} isDisabled={isApproved && !canApprovePayout} />
            </div>
          </td>

          <td rowSpan={vehicleInstallerCount} style={{ background: vehicleBackground }}>
            <div className='checkbox-wrapper'>
              <Checkbox isChecked={isApproved} onChange={handleIsApprovedChange} isDisabled={!canApprovePayout} />
            </div>
          </td>
        </>
      )}
    </tr>
  )
  function handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInstallerAmounts(prev => ({ ...prev, isLocked: e.target.checked }))
    mutate({ installerDispatchId, attribute: e.target.checked ? 'shouldLock' : 'shouldUnlock', value: null })
  }
  function handleAddonsCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInstallerAmounts(prev => ({ ...prev, canHourlyReceiveAddons: e.target.checked }))
    mutate({ installerDispatchId, attribute: 'canHourlyReceiveAddons', value: e.target.checked ? 'true' : 'false' })
  }

  function handleFocus(e: React.FocusEvent<HTMLInputElement>) {
    setTimeout(() => {
      e.target.select()
    }, 1) // Delay until the next event loop cycle
  }

  function handleReturn(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') (e.target as HTMLInputElement).blur()
  }

  function handlePayoutModeChange() {
    if (isDavisBacon) return
    const newValue = !isHourlyPayout
    setInstallerAmounts(prev => ({ ...prev, isHourlyPayout: newValue }))
    mutate({ installerDispatchId, attribute: 'isHourlyPayout', value: newValue ? 'true' : 'false' })
  }

  function handleIsCompletedChange() {
    const newValue = !isCompleted
    setInstallerAmounts(prev => ({ ...prev, isCompleted: newValue, isApproved: !newValue ? false : prev.isApproved }))
    mutate({ installerDispatchId, attribute: 'isCompleted', value: newValue ? 'true' : 'false' })
  }

  function handleIsApprovedChange() {
    const newValue = !isApproved
    setInstallerAmounts(prev => ({ ...prev, isApproved: newValue, isCompleted: newValue ? true : prev.isCompleted }))
    mutate({ installerDispatchId, attribute: 'isApproved', value: newValue ? 'true' : 'false' })
  }
}

export default InstallersTableRow

const SUBMIT_INSTALLER_PAYOUT_VALUE = graphql(/* GraphQL */ `
  mutation SubmitInstallerPayoutValue($installerDispatchId: ID!, $attribute: String!, $value: String) {
    submitInstallerPayoutValue(installerDispatchId: $installerDispatchId, attribute: $attribute, value: $value) {
      success
      message
    }
  }
`)

const ButtonTd = styled.td`
  padding: 0;
`
