import styled from 'styled-components'

import { IconButton } from '@atlaskit/button/new'
import { token } from '@atlaskit/tokens'

import CopyIcon from '@atlaskit/icon/glyph/copy'
import SelectClearIcon from '@atlaskit/icon/glyph/select-clear'

import useIsModifierPressed from '@/utils/useIsModifierPressed'

import useInstallerState from '../hooks/useInstallerState'

import Tooltip from '@atlaskit/tooltip'
import {
  DRAGGABLE_INSTALLER_CARD_BORDER_RADIUS,
  DRAGGABLE_INSTALLER_CARD_HEIGHT,
  DRAGGABLE_INSTALLER_CARD_WIDTH,
} from '../../dispatchConstants'

interface InstallerCardProps {
  installerId: string
  vehicleDispatchId?: string | null
  vehicleId?: string | null
  isPending?: boolean
  isOverlapped?: boolean
}

const InstallerCard = ({ installerId, vehicleDispatchId, vehicleId, isOverlapped = false, isPending = false }: InstallerCardProps) => {
  const isOptionKeyPressed = useIsModifierPressed('alt')
  const {
    ref,
    isDragging,
    shouldHighlight,
    isCopyMode,
    name,
    initials,
    isHourly,
    isDriver,
    assignmentCount,
    isMouseOver,
    handleMouseEnter,
    handleMouseLeave,
  } = useInstallerState({
    installerId,
    vehicleDispatchId,
    vehicleId,
  })

  return (
    <StyledInstallerCard
      ref={ref}
      $isDragging={isDragging}
      $isCopyMode={isCopyMode}
      $shouldHighlight={shouldHighlight}
      $isPending={isPending}
      $isMouseOver={isMouseOver}
      $isOverlapped={isOverlapped}
      $isHourly={isHourly ?? false}
      $assignmentCount={assignmentCount}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className='installer-card-section-indicators'>
        {isCopyMode ? (
          <CopyIcon label='Copy Installer' primaryColor={token('color.icon')} />
        ) : (
          <div className='primary-circle'>
            <div className='primary-circle-text'>{initials}</div>
            {!isDriver && <div className='primary-circle-warning' />}
          </div>
        )}
      </div>

      <div className='installer-card-title'>
        <Tooltip content={name} position='top-start'>
          <div className='ellipsis-wrapper'>
            <span>{name}</span>
          </div>
        </Tooltip>
      </div>

      {!!assignmentCount && <div className='job-count-new'>{assignmentCount}</div>}

      {isOptionKeyPressed && isMouseOver && (
        <div className='installer-card-option-key-pressed'>
          <IconButton
            label='Clear Installer'
            icon={iconProps => <SelectClearIcon {...iconProps} size='small' primaryColor={token('color.icon.accent.orange')} />}
          />
        </div>
      )}
    </StyledInstallerCard>
  )
}

interface StyledInstallerCardProps {
  $isDragging: boolean
  $isCopyMode: boolean
  $shouldHighlight: boolean
  $isPending: boolean
  $isMouseOver: boolean
  $isOverlapped: boolean
  $isHourly: boolean
  $assignmentCount: number
}

const StyledInstallerCard = styled.div<StyledInstallerCardProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  position: relative;
  cursor: grab;
  user-select: none;

  border-radius: ${DRAGGABLE_INSTALLER_CARD_BORDER_RADIUS}px;
  width: ${DRAGGABLE_INSTALLER_CARD_WIDTH}px;
  height: ${DRAGGABLE_INSTALLER_CARD_HEIGHT}px;

  box-sizing: border-box;
  border-style: solid;
  border-width: 1px 1px 2px 1px;
  border-color: ${token('color.border')};
  background: ${token('elevation.surface.raised')};

  z-index: ${({ $shouldHighlight }) => ($shouldHighlight ? '3' : '1')};

  opacity: ${({ $isDragging }) => ($isDragging ? 0.5 : 1)};

  &::before {
    content: '';
    position: absolute;
    inset: -1px -1px -2px -1px;
    background: transparent;
    border: 1px solid transparent;
    border-bottom-width: 2px;
    border-radius: ${DRAGGABLE_INSTALLER_CARD_BORDER_RADIUS}px;

    transition: all 140ms cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 0;
    pointer-events: none;
  }

  ${({ $shouldHighlight, $isMouseOver }) => {
    if ($isMouseOver) {
      return `
        &::before {
          background: ${token('color.background.selected.hovered')};
          border-color: ${token('color.border.accent.blue')};
        }
      `
    }
    if ($shouldHighlight) {
      return `
        &::before {
          background: ${token('color.background.selected')};
          border-color: ${token('color.border.accent.blue')};
        }
      `
    }
    return ''
  }}

  > div {
    transition: opacity 0.1s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: ${({ $isPending }) => ($isPending ? 0.5 : 1)};
  }

  .job-count-new {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;

    height: 16px;
    border-radius: 8px;
    min-width: 16px;

    background: ${token('color.icon.brand')};
    color: ${token('color.text.inverse')};
    font-size: 11px;
    line-height: 1;
    font-weight: 300;
    padding: 0 0px;

    transition: transform 140ms cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateX(-${({ $isOverlapped }) => ($isOverlapped ? '112' : '8')}px)
      translateY(${({ $isOverlapped }) => ($isOverlapped ? '1' : '9')}px);

    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
    ${({ $isOverlapped }) =>
      $isOverlapped &&
      `
      box-shadow: ${token('elevation.shadow.raised')};
    `}
  }

  .installer-card-section-indicators {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    z-index: 0;
    flex: 0 0 auto;
    width: ${24 + 12 + 12}px;

    .primary-circle {
      position: relative;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 auto;
      /* overflow: hidden; */
      background: ${({ $isHourly }) => ($isHourly ? token('color.icon.accent.purple') : token('color.icon.subtle'))};
      color: ${token('color.text.inverse')};
      z-index: 0;

      .primary-circle-text {
        font-size: 11px;
        letter-spacing: 0.08em;
        font-weight: 300;
        text-align: center;
        padding-left: 0.08em;
      }

      .primary-circle-warning {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 6px;
        width: 6px;
        flex: 0 0 auto;
        border-radius: 3px;
        background: ${token('color.icon.accent.orange')};
        z-index: 1;
      }
    }
  }

  .installer-card-title {
    padding-right: ${({ $isOverlapped, $assignmentCount }) => ($isOverlapped || $assignmentCount === 0 ? '4' : '24')}px;
    font-weight: 500;
    user-select: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    z-index: 1;

    .ellipsis-wrapper {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .installer-card-option-key-pressed {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
`

export default InstallerCard
