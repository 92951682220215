import { useMemo } from 'react'

import useStaffListQuery, { Staff } from '@/utils/queryHooks/useStaffListQuery'

const useStaffById = (staffId?: string): Staff | null => {
  const { staffMap } = useStaffListQuery()
  return useMemo(() => (staffId ? staffMap[staffId] : null), [staffMap, staffId])
}

export default useStaffById
