import { graphql } from '@/gql'
export const GET_VEHICLE_DATE_QUERY_KEY = 'GetVehicleDate'
const GET_VEHICLE_DATE = graphql(/* GraphQL */ `
  query GetVehicleDate($vehicleDateId: ID!) {
    vehicleDate(pk: $vehicleDateId) {
      ...VehicleDateReconFragment
    }
  }
`)

export default GET_VEHICLE_DATE
