import Button from '@atlaskit/button/new'
import { Temporal } from '@js-temporal/polyfill'
import { useSearchParams } from 'react-router'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import ReconAppPage from '../ReconAppPage'
import useReconSearchParams from '../useReconSearchParams'

import InstallerWeekDates from './InstallerWeekDates'
import InstallerWeekDispatchTable from './InstallerWeekDispatchTable'
import usePaystubQuery from './usePaystubQuery'

const ReconInstallerPaystubPage = () => {
  const { weekNumber, year, installerId } = useWeekYearParams()
  const [searchParams] = useSearchParams()

  const { data, isLoading } = usePaystubQuery()

  const installerPayroll = data?.installerPayroll
  const installer = installerPayroll?.installer

  const headerText = `Paystub • ${year}-w${weekNumber}` + (!installer?.fullName ? '' : ` • ${installer?.fullName}`)

  const handlePrintClick = () => {
    const currentUrl = window.location.pathname
    const printUrl = `${currentUrl}/print?${searchParams.toString()}`
    window.open(printUrl, '_blank')
  }

  return (
    <ReconAppPage header={headerText} isLoading={isLoading} actions={<Button onClick={handlePrintClick}>Print</Button>}>
      <title>{headerText}</title>

      {installerId ? (
        <PageWrapper>
          <InstallerWeekDispatchTable installerPayroll={installerPayroll} />

          <InstallerWeekDates installerPayroll={installerPayroll} isLoading={isLoading} />
        </PageWrapper>
      ) : (
        <PageContainer>No installer selected</PageContainer>
      )}
    </ReconAppPage>
  )
}

export default ReconInstallerPaystubPage

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const useWeekYearParams = () => {
  const { paramValues } = useReconSearchParams()
  const installerId = paramValues.get('installerId')
  const today = Temporal.Now.plainDateISO()
  const weekId = paramValues.get('weekId')
  const todayWeekNumber = today.weekOfYear
  const todayYear = today.year
  let todayWeekYear = todayYear
  const todayMonthNumber = today.month
  if (todayMonthNumber === 1 && todayWeekNumber > 50) todayWeekYear -= 1

  const year = Number(weekId?.split('-')?.[0] ?? todayWeekYear)
  const weekNumber = Number(weekId?.split('-')?.[1] ?? todayWeekNumber)

  return { weekNumber, year, installerId }
}

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  width: 100%; // Changed from align-self: stretch
  max-width: 100%;
  .header {
    font-size: 1.5rem;
    font-weight: 600;
    color: ${token('color.text')};
  }
`
