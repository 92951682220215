import { useAtomValue } from 'jotai'
import { useMemo } from 'react'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import { FONT_FAMILY, MONOSPACE_FONT_FAMILY } from '@/utils/constants'

import { CELL_PADDING_HORIZONTAL, CELL_PADDING_VERTICAL } from '@/pages/jobs/EstimateEditor/editorConstants'

import { showMinutesAsHrDecimalAtom } from '../utils/appAtoms'

import NumberCell from './NumberCell'

export type HrMinCellValue = number | string | null | undefined
interface HrMinCellProps {
  value: HrMinCellValue
  padding?: string
  appearance?: 'default' | 'warning' | 'danger'
  strikethrough?: boolean
  monoPrefixSuffix?: boolean
}

const DIM_VALUES = ['-', '0', '00']

const HrMinCell = ({ value, padding, appearance = 'default', strikethrough = false, monoPrefixSuffix = false }: HrMinCellProps) => {
  const showMinutesAsHrDecimal = useAtomValue(showMinutesAsHrDecimalAtom)
  const { hoursText, minutesText } = useMemo(() => {
    if (value === null || value === undefined || value === '') return { hoursText: '-', minutesText: '-' }
    const numberValue = parseInt(value.toString())
    const hours = Math.floor(numberValue / 60)
    const minutes = numberValue % 60
    return { hoursText: hours.toString(), minutesText: minutes.toString() }
  }, [value])

  const { dimHours, dimMinutes } = useMemo(() => {
    return { dimHours: DIM_VALUES.includes(hoursText), dimMinutes: DIM_VALUES.includes(minutesText) && DIM_VALUES.includes(hoursText) }
  }, [hoursText, minutesText])

  const hoursDecimal = useMemo(() => {
    const hoursInt = parseInt(hoursText)
    const minutesInt = parseInt(minutesText)
    const decimal = hoursInt + minutesInt / 60
    if (isNaN(decimal)) return null
    return decimal.toFixed(2)
  }, [hoursText, minutesText])

  if (showMinutesAsHrDecimal) return <NumberCell value={hoursDecimal} readOnly suffix='hr' decimalPlaces={2} />
  return (
    <StyledHrMinCell style={{ padding }} $appearance={appearance} $strikethrough={strikethrough} className='styled-number-cell'>
      <div className='num-cell-with-suffix'>
        <span className='num-cell-value' style={dimHours ? { opacity: 0.3 } : undefined}>
          {hoursText}
        </span>

        <span
          className={`hr-min-cell-suffix hr-min-cell-suffix-hours${monoPrefixSuffix ? ' mono' : ''}`}
          style={dimHours ? { opacity: 0.2 } : undefined}
        >
          h
        </span>
      </div>

      <div className='num-cell-with-suffix num-cell-with-suffix-minutes'>
        <span className='num-cell-value' style={dimMinutes ? { opacity: 0.3 } : undefined}>
          {minutesText}
        </span>

        <span
          className={`hr-min-cell-suffix hr-min-cell-suffix-minutes${monoPrefixSuffix ? ' mono' : ''}`}
          style={dimMinutes ? { opacity: 0.2 } : undefined}
        >
          m
        </span>
      </div>
    </StyledHrMinCell>
  )
}

export default HrMinCell

interface StyledHrMinCellProps {
  $appearance: 'default' | 'warning' | 'danger'
  $strikethrough: boolean
}

export const HR_MIN_MINUTES_WIDTH = 36

const StyledHrMinCell = styled.div<StyledHrMinCellProps>`
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 2px;
  font-family: ${MONOSPACE_FONT_FAMILY};
  /* opacity: 0.9; */
  padding: ${`${CELL_PADDING_VERTICAL}px ${CELL_PADDING_HORIZONTAL - 1}px`};
  white-space: nowrap;
  text-align: right;

  ${({ $appearance }) =>
    $appearance === 'warning' &&
    `
  color: ${token('color.text.warning')};
  background: ${token('color.background.warning')};
  `}

  ${({ $appearance }) =>
    $appearance === 'danger' &&
    `
  color: ${token('color.text.danger')};
  background: ${token('color.background.danger')};
  `}

  ${({ $appearance }) =>
    $appearance === 'default' &&
    `
  color: ${token('color.text')};
  `}

  > div.num-cell-with-suffix {
    white-space: nowrap;

    flex: 1 0 auto;
    min-width: ${HR_MIN_MINUTES_WIDTH}px;

    &.num-cell-with-suffix-minutes {
      flex: 0 0 ${HR_MIN_MINUTES_WIDTH}px;
      max-width: ${HR_MIN_MINUTES_WIDTH}px;
      width: ${HR_MIN_MINUTES_WIDTH}px;
    }

    > span {
      &.hr-min-cell-suffix {
        padding-left: 2px;
        font-weight: 500;
        opacity: 0.4;
        font-family: ${FONT_FAMILY};
        white-space: nowrap;
        flex: 0 0 auto;

        &.mono {
          font-family: ${MONOSPACE_FONT_FAMILY};
        }
      }

      &.num-cell-value {
        flex: 1 1 auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        ${({ $strikethrough }) => ($strikethrough === true ? 'text-decoration: line-through;' : '')}
      }
    }
  }
`
