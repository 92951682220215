import styled from 'styled-components'

import Heading from '@atlaskit/heading'
import InlineEdit from '@atlaskit/inline-edit'
import Textfield from '@atlaskit/textfield'

import { GetJobQuery } from '@/gql/graphql'
import { useSubmitJobValue } from '@/utils/queryHooks/useSubmitJobValue'
import { formatDateTimeString } from '@/utils/utilities'

import AppRightSidebarAttributeRow from '@/components/AppRightSidebarAttributeRow'

import { GET_JOB_QUERY_KEY } from './JobPage'

type JobType = GetJobQuery['job']
interface JobPageRightSidebarProps {
  job?: JobType | null
}

const JobPageRightSidebar = ({ job }: JobPageRightSidebarProps) => {
  const projectSite = job?.projectSite ?? null

  return (
    <div>
      <Heading size='small'>Job Site</Heading>
      <AttributesSection>
        <div>
          <EditableJobSite jobId={job?.id} projectSite={projectSite} />
        </div>
      </AttributesSection>

      <Heading size='small'>Job Details</Heading>
      <AttributesSection>
        <AppRightSidebarAttributeRow label={'Customer'} value={job?.customer?.name} />
        <AppRightSidebarAttributeRow label={'Created By'} value={job?.createdBy?.fullName ?? null} />
        <AppRightSidebarAttributeRow label={'Created At'} value={formatDateTimeString(job?.createdAt)} />
      </AttributesSection>
    </div>
  )
}
export default JobPageRightSidebar

const AttributesSection = styled.div`
  margin-bottom: 32px;
  margin-top: 8px;
`
type ProjectSiteType = JobType['projectSite']
interface EditableJobSiteProps {
  jobId: string
  projectSite: ProjectSiteType
}
const EditableJobSite = ({ jobId, projectSite }: EditableJobSiteProps) => {
  const { mutate, isPending } = useSubmitJobValue({ queryKeysToInvalidate: [GET_JOB_QUERY_KEY] })

  const onConfirm = (value: string) => mutate({ jobId, attribute: 'project_site_name', value })

  return (
    <InlineEdit
      readView={() => <StyledSiteName>{projectSite?.name ?? 'TBD' + (isPending ? '...' : '')}</StyledSiteName>}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      editView={({ errorMessage, ...fieldProps }) => <Textfield {...fieldProps} autoFocus />}
      defaultValue={projectSite?.name ?? ''}
      onConfirm={onConfirm}
    />
  )
}

const StyledSiteName = styled.h5`
  margin-block: 0px;
  padding: 4px 0;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.003em;
  line-height: 16px;
`
