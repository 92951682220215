import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import styled from 'styled-components'

import Button from '@atlaskit/button/new'
import { ErrorMessage, Label } from '@atlaskit/form'
import { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog'
import Select from '@atlaskit/select'
import Textfield from '@atlaskit/textfield'

import useGraphQLClient from '@/utils/useAuthRequest'

import { AddonReasonEnum, GetDispatchPayoutDetailsQuery, SubmitAddonMutationVariables as MutationVariables } from '@/gql/graphql'

import NumberTextField from '@/components/NumberTextField'
import SUBMIT_ADDON from '@/utils/api/submitAddon'

type SelectOption = { label: string; value: string }
const reasonOptions: readonly SelectOption[] = Object.values(AddonReasonEnum).map(reason => ({ label: reason, value: reason }))

type FormInputs = {
  reason: SelectOption
  payoutAmount: string
  note: string
}

type QueryDispatch = GetDispatchPayoutDetailsQuery['workOrder']['dispatches'][0]
export type Addon = NonNullable<QueryDispatch['payoutAddons']>[0]
interface AddOnFormProps {
  setIsOpen: (isOpen: boolean) => void
  selectedAddon?: Addon
  dispatchId: string
}
export default function AddOnForm({ setIsOpen, selectedAddon, dispatchId }: AddOnFormProps) {
  const graphQLClient = useGraphQLClient()
  const queryClient = useQueryClient()

  const { mutate, isPending } = useMutation({
    mutationFn: async (variables: MutationVariables) => {
      const response = await graphQLClient.request(SUBMIT_ADDON, variables)
      if (!response.submitAddon.success) {
        throw new Error(response.submitAddon.message)
      }
      return response.submitAddon
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['GetDispatchPayoutDetails'] })
      closeModal()
    },
  })

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
      reason: selectedAddon?.reason ? { label: selectedAddon.reason, value: selectedAddon.reason } : undefined,
      payoutAmount: selectedAddon?.payoutAmount ?? '',
      note: selectedAddon?.note ?? '',
    },
  })

  const closeModal = useCallback(() => {
    reset()
    setIsOpen(false)
  }, [reset, setIsOpen])

  const onSubmit: SubmitHandler<FormInputs> = data => {
    const variables = {
      payoutAmount: data.payoutAmount,
      reason: data.reason.value as AddonReasonEnum,
      note: data.note,
      dispatchId,
      addonId: selectedAddon?.id,
    }
    console.log('onSubmit variables', { variables, data })
    mutate(variables, { onSuccess: closeModal })
  }

  const formIsInvalid = Object.keys(errors).length > 0
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader>
        <ModalTitle>Submit Addon</ModalTitle>
      </ModalHeader>

      <ModalBody>
        <StyledModalContent>
          <FieldWrapper>
            <Controller
              name='reason'
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <>
                  <Label htmlFor='basic-textfield'>Reason</Label>
                  <Select
                    placeholder='Select Reason'
                    menuPosition={'fixed'}
                    options={reasonOptions}
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                    value={value}
                    ref={ref}
                  />

                  {errors?.reason?.type === 'required' ? <ErrorMessage>Must select a reason.</ErrorMessage> : null}
                </>
              )}
            />
          </FieldWrapper>

          <FieldWrapper style={{ marginBottom: 24 }}>
            <Controller
              name='payoutAmount'
              control={control}
              defaultValue=''
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <Label htmlFor='basic-textfield'>Payout Amount</Label>
                  <NumberTextField value={value} onChange={onChange} onBlur={onBlur} isMoney />

                  {errors?.payoutAmount?.type === 'required' ? <ErrorMessage>Must include a payout amount.</ErrorMessage> : null}
                </>
              )}
            />
          </FieldWrapper>

          <FieldWrapper>
            <Controller
              name='note'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <>
                  <Label htmlFor='basic-textfield'>Note</Label>
                  <Textfield {...field} />
                </>
              )}
            />
          </FieldWrapper>
        </StyledModalContent>
      </ModalBody>
      <ModalFooter>
        <Button appearance='subtle' onClick={closeModal} isDisabled={isPending}>
          Close
        </Button>

        <Button isLoading={isPending} appearance='primary' type='submit' isDisabled={formIsInvalid}>
          {selectedAddon ? 'Update' : 'Create'}
        </Button>
      </ModalFooter>
    </form>
  )
}
const FieldWrapper = styled.div`
  margin-bottom: 18px;
`
const StyledModalContent = styled.div`
  padding-bottom: 100px;
`
