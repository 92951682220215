import { useQuery } from '@tanstack/react-query'

import GET_VEHICLE_DATE, { GET_VEHICLE_DATE_QUERY_KEY } from '@/utils/api/getVehicleDate'
import useGraphQLClient from '@/utils/useAuthRequest'

import { GetPayrollDayVehiclesQuery } from '@/gql/graphql'

import VehicleContainer from './VehicleContainer'

type Vehicle = GetPayrollDayVehiclesQuery['vehiclesByDate'][number]

interface VehicleSectionProps {
  vehicle: Vehicle
}

const VehicleSection = ({ vehicle }: VehicleSectionProps) => {
  const graphQLClient = useGraphQLClient()
  const vehicleDateId = vehicle?.vehicleDate?.id ?? null

  const { data } = useQuery({
    queryKey: [GET_VEHICLE_DATE_QUERY_KEY, vehicleDateId],
    queryFn: async () => await graphQLClient.request(GET_VEHICLE_DATE, { vehicleDateId: vehicleDateId ?? '' }),
    enabled: !!vehicleDateId,
  })

  const vehicleDate = data?.vehicleDate ?? null

  return vehicleDate ? <VehicleContainer vehicle={vehicle} vehicleDate={vehicleDate} /> : null
}

export default VehicleSection
