import { useMutation } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { toast } from 'sonner'

import useGraphQLClient from '@/utils/useAuthRequest'

import { graphql } from '@/gql'
import { SubmitInstallerPayrollValueMutationVariables } from '@/gql/graphql'

import { installerWeeklyLookupsByIdAtom } from '../reconPayrollAtoms'

import { titleCaseFromCamelCase } from '@/utils/utilities'
import { parseInstallerWithWeeklyPayroll } from './useReconPayrollQuery'

const useSubmitInstallerPayroll = () => {
  const graphQLClient = useGraphQLClient()
  const setPayrollByInstallerId = useSetAtom(installerWeeklyLookupsByIdAtom)
  const { mutate, mutateAsync, isPending } = useMutation({
    mutationFn: async (variables: SubmitInstallerPayrollValueMutationVariables) => {
      const fieldName = titleCaseFromCamelCase(variables.attribute)
      const toastId = `${variables.attribute}-${variables.value}-${variables.installerId}`

      const result = await graphQLClient.request(SUBMIT_INSTALLER_PAYROLL_VALUE, variables)
      if (!result.submitInstallerPayrollValue.success) {
        console.error('## submitInstallerPayrollValue error', result.submitInstallerPayrollValue)
        toast.error(result.submitInstallerPayrollValue.message, { id: toastId })
        throw new Error(result.submitInstallerPayrollValue.message)
      }

      toast.loading(`Updating field: ${fieldName}`, { id: toastId })

      return result
    },
    onSuccess: (result, variables) => {
      const fieldName = titleCaseFromCamelCase(variables.attribute)
      const toastId = `${variables.attribute}-${variables.value}-${variables.installerId}`
      toast.success(result.submitInstallerPayrollValue.message || `Successfully updated field: ${fieldName}`, { id: toastId })
      const newWeeklyStats = result.submitInstallerPayrollValue?.installerWithWeeklyPayroll
      if (newWeeklyStats) {
        setPayrollByInstallerId(prev => ({
          ...prev,
          [variables.installerId]: parseInstallerWithWeeklyPayroll(newWeeklyStats),
        }))
      }
    },
  })

  return { mutate, mutateAsync, isPending }
}

export default useSubmitInstallerPayroll

const SUBMIT_INSTALLER_PAYROLL_VALUE = graphql(/* GraphQL */ `
  mutation SubmitInstallerPayrollValue($weekOf: Date!, $installerId: ID!, $attribute: String!, $value: String, $date: Date) {
    submitInstallerPayrollValue(weekOf: $weekOf, installerId: $installerId, attribute: $attribute, value: $value, date: $date) {
      success
      message
      installerWithWeeklyPayroll {
        ...InstallersWithWeeklyPayrollFragment
      }
    }
  }
`)
