import { format } from 'date-fns'
import { useNavigate, useParams } from 'react-router'
import styled from 'styled-components'

import { DatePicker } from '@atlaskit/datetime-picker'
import { Label } from '@atlaskit/form'
import { token } from '@atlaskit/tokens'

const DateRangeActions = ({
  linkToSetter,
  startDateKey = 'startDate',
  endDateKey = 'endDate',
  fallbackStartDate,
  fallbackEndDate,
}: {
  linkToSetter: (startDate: string, endDate: string) => string
  startDateKey?: string
  endDateKey?: string
  fallbackStartDate?: string
  fallbackEndDate?: string
}) => {
  const navigate = useNavigate()
  const params = useParams()

  const startDate = params[startDateKey] ?? fallbackStartDate
  const endDate = params[endDateKey] ?? fallbackEndDate
  return (
    <>
      <DateWrapper>
        <Label id='week-range-start' htmlFor='datepicker-format'>
          From
        </Label>
        <DatePicker
          // appearance='subtle'
          spacing='compact'
          onChange={handleStartDateChange}
          value={startDate}
          formatDisplayLabel={dateString => {
            const dateObj = new Date(dateString + 'T00:00:00')
            const formattedDate = format(dateObj, 'MMM do')
            return formattedDate
          }}
        />
      </DateWrapper>

      <DateWrapper>
        <Label id='week-range-end' htmlFor='datepicker-format'>
          To
        </Label>
        <DatePicker
          // appearance='subtle'
          spacing='compact'
          onChange={handleEndDateChange}
          value={endDate}
          formatDisplayLabel={date => format(new Date(date), 'MMM do')}
        />
      </DateWrapper>
    </>
  )
  function handleStartDateChange(date: string) {
    navigate(linkToSetter(date, endDate ?? date))
  }
  function handleEndDateChange(date: string) {
    navigate(linkToSetter(startDate ?? date, date))
  }
}

export default DateRangeActions

const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -12px;
  /* gap: 4px; */
  > div {
    width: 80px;
  }
  > div > div > div > div:nth-child(2) {
    display: none;
  }
  label {
    color: ${token('color.text.disabled')};
    transition: color 0.1s;
    padding-left: 9px;
    margin-bottom: 0px;
  }

  &:hover {
    label {
      color: ${token('color.text.subtlest')};
    }
  }
`
