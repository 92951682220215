import { useMemo } from 'react'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import { FONT_FAMILY, MONOSPACE_FONT_FAMILY } from '@/utils/constants'
import { formatNumber } from '@/utils/utilities'

import { CELL_PADDING_HORIZONTAL, CELL_PADDING_VERTICAL } from '@/pages/jobs/EstimateEditor/editorConstants'

export type NumberCellValue = number | string | null | undefined
interface NumberCellProps {
  value: NumberCellValue
  decimalPlaces?: number
  prefix?: string
  suffix?: string
  readOnly?: boolean
  padding?: string
  monoPrefixSuffix?: boolean
  appearance?: 'default' | 'warning' | 'danger'
  strikethrough?: boolean
}

const NumberCell = ({
  value,
  decimalPlaces = 2,
  prefix = '',
  suffix = '',
  readOnly = false,
  padding,
  monoPrefixSuffix = false,
  appearance = 'default',
  strikethrough = false,
}: NumberCellProps) => {
  const { valueToUse, shouldDim } = useMemo(() => {
    let valueToUse = value
    if (value === null || value === undefined || value === '') valueToUse = '-'
    else if (typeof value === 'string') valueToUse = parseFloat(value).toFixed(decimalPlaces)
    else if (typeof value === 'number') valueToUse = value.toFixed(decimalPlaces)
    else valueToUse = 'INVALID'

    let shouldDim = false
    if (valueToUse === '-') shouldDim = true
    else if (typeof valueToUse === 'string' && valueToUse !== 'INVALID' && valueToUse.includes('0')) {
      const parts = valueToUse.split('.')
      const leftOfDecimal = parts[0]
      const rightOfDecimal = parts[1]
      if (parseInt(leftOfDecimal) === 0 && (!rightOfDecimal || parseInt(rightOfDecimal) === 0)) shouldDim = true
    }

    if (typeof valueToUse === 'number' && (valueToUse >= 1000 || valueToUse <= -1000)) valueToUse = formatNumber(valueToUse, decimalPlaces)
    else if (
      typeof valueToUse === 'string' &&
      !isNaN(parseFloat(valueToUse)) &&
      (parseFloat(valueToUse) >= 1000 || parseFloat(valueToUse) <= -1000)
    )
      valueToUse = formatNumber(parseFloat(valueToUse), decimalPlaces)

    return { valueToUse, shouldDim }
  }, [value, decimalPlaces])

  return (
    <StyledNumberCell
      style={{ padding }}
      $readOnly={readOnly}
      $shouldDim={shouldDim}
      $appearance={appearance}
      $strikethrough={strikethrough}
      className='styled-number-cell'
    >
      {!prefix ? <span /> : <span className={`num-cell-prefix ${monoPrefixSuffix ? 'mono' : ''}`}>{prefix}</span>}

      <span className='num-cell-value-suffix'>
        <span className='num-cell-value'>{valueToUse}</span>

        {!!suffix && <span className={`num-cell-suffix ${monoPrefixSuffix ? 'mono' : ''}`}>{suffix}</span>}
      </span>
    </StyledNumberCell>
  )
}

export default NumberCell

interface StyledNumberCellProps {
  $readOnly: boolean
  $shouldDim: boolean
  $appearance: 'default' | 'warning' | 'danger'
  $strikethrough: boolean
}
const StyledNumberCell = styled.div<StyledNumberCellProps>`
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: ${MONOSPACE_FONT_FAMILY};
  opacity: ${({ $readOnly }) => ($readOnly ? 0.9 : 1)};
  padding: ${`${CELL_PADDING_VERTICAL}px ${CELL_PADDING_HORIZONTAL - 1}px`};
  white-space: nowrap;

  ${({ $appearance }) =>
    $appearance === 'warning' &&
    `
  color: ${token('color.text.warning')};
  background: ${token('color.background.warning')};
  `}

  ${({ $appearance }) =>
    $appearance === 'danger' &&
    `
  color: ${token('color.text.danger')};
  background: ${token('color.background.danger')};
  `}

  ${({ $appearance }) =>
    $appearance === 'default' &&
    `
  color: ${token('color.text')};
  `}


  .num-cell-prefix {
    margin-right: 4px;
    ${({ $readOnly }) => ($readOnly ? undefined : 'padding-left: 6px')};

    white-space: nowrap;
    flex: 0 0 auto;
    opacity: 0.4;
    &.mono {
      font-family: ${MONOSPACE_FONT_FAMILY};
    }
  }

  .num-cell-value-suffix {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .num-cell-suffix {
      padding-left: 6px;
      font-weight: 500;
      opacity: 0.4;
      padding-right: ${({ $readOnly }) => ($readOnly ? undefined : '6px')};
      font-family: ${FONT_FAMILY};
      white-space: nowrap;
      /* overflow: hidden;
      text-overflow: ellipsis; */
      flex: 0 0 auto;
      &.mono {
        font-family: ${MONOSPACE_FONT_FAMILY};
      }
    }

    .num-cell-value {
      flex: 1 1 auto;
      opacity: ${({ $shouldDim, $strikethrough }) => ($shouldDim || $strikethrough ? 0.4 : 1)};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      ${({ $strikethrough }) => ($strikethrough === true ? 'text-decoration: line-through;' : '')}
    }
  }
`
