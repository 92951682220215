import {
  Cell,
  Column,
  Content,
  Heading,
  IllustratedMessage,
  Row,
  Selection,
  SpectrumColumnProps,
  TableBody,
  TableHeader,
  TableView,
} from '@adobe/react-spectrum'
import { LoadingState } from '@react-types/shared'
import { CSSProperties, JSX, StrictMode, useMemo } from 'react'
import { NavigateOptions } from 'react-router'
import styled from 'styled-components'

import { TABLE_LOADING_STATUS_CONVERSION } from '@/utils/constants'

import SpectrumTableProvider from './SpectrumTableProvider'

export interface MySpectrumColumnProps<T> extends SpectrumColumnProps<T> {
  // header: string
  key: string
  cell: (item: T) => JSX.Element
}
export interface ColumnDefsType<T> {
  key: string
  title: string
  align?: 'start' | 'center' | 'end'
  cell?: (item: T) => JSX.Element | string
  valueGetter?: (item: T) => string | null
  width?: number
  cellStyle?: CSSProperties
  allowsResizing?: boolean
}

type MySpectrumTableProps<T> = {
  columns: ColumnDefsType<T>[]
  rows: Iterable<T>
  status?: 'error' | 'success' | 'pending'
  density?: 'compact' | 'regular' | 'spacious'
  absolute?: boolean
  linkSelector?: (row: T) => string
  ariaLabel?: string
  selectionMode?: 'none' | 'single' | 'multiple'
  onSelectionChange?: (keys: Selection) => void
}

function renderEmptyState() {
  return (
    <IllustratedMessage>
      {/* <NotFound /> */}
      <Heading>No results</Heading>
      <Content>No results found</Content>
    </IllustratedMessage>
  )
}

declare module '@adobe/react-spectrum' {
  interface RouterConfig {
    routerOptions: NavigateOptions
  }
}

const SpectrumTable = <T,>({
  columns,
  rows,
  density = 'regular',
  status,
  absolute = false,
  linkSelector,
  ariaLabel = 'Table',
  selectionMode,
  onSelectionChange,
}: MySpectrumTableProps<T>) => {
  const columnKeyIndex = useMemo(() => {
    const columnKeyIndex: { [key: string]: number } = {}
    columns.forEach((column, index) => {
      columnKeyIndex[`${column.key}`] = index
    })
    return columnKeyIndex
  }, [columns])

  const loadingState = (!status ? undefined : TABLE_LOADING_STATUS_CONVERSION[status]) as LoadingState
  return (
    <StrictMode>
      <SpectrumTableProvider>
        <StyledGridWrapper $absolute={absolute}>
          <TableView
            minWidth='size-2000'
            UNSAFE_style={{ backgroundColor: 'transparent' }}
            minHeight={'size-2000'}
            density={density}
            flex
            renderEmptyState={renderEmptyState}
            aria-label={ariaLabel}
            selectionMode={selectionMode}
            selectionStyle={selectionMode === 'single' ? 'highlight' : 'checkbox'}
            onSelectionChange={onSelectionChange}
          >
            <TableHeader columns={columns}>
              {column => (
                <Column
                  key={column.key}
                  align={column?.align ?? 'start'}
                  defaultWidth={column.width}
                  allowsResizing={column?.allowsResizing ?? true}
                >
                  {column.title}
                </Column>
              )}
            </TableHeader>

            <TableBody items={rows} loadingState={loadingState}>
              {item => {
                const href = linkSelector === undefined ? undefined : linkSelector(item)

                return (
                  <Row href={href} routerOptions={{ state: { ...item } }}>
                    {columnKey => {
                      const col = columns[columnKeyIndex[columnKey]]
                      const baseStyle = col?.cellStyle ?? {}
                      const cellStyle = { opacity: 1, ...{ baseStyle } }

                      let finalValue = null
                      if (col.cell !== undefined) finalValue = col.cell(item)
                      else if (col.valueGetter !== undefined) finalValue = col.valueGetter(item)
                      else finalValue = item[columnKey]

                      if (finalValue === undefined) finalValue = 'UNDEFINED'
                      else if (finalValue === null || finalValue === '') {
                        finalValue = '-'
                        cellStyle.opacity = 0.5
                      }

                      return (
                        <Cell>
                          <span style={cellStyle}>{finalValue}</span>
                        </Cell>
                      )
                    }}
                  </Row>
                )
              }}
            </TableBody>
          </TableView>
        </StyledGridWrapper>
      </SpectrumTableProvider>
    </StrictMode>
  )
}
type StyledGridWrapperProps = {
  $absolute?: boolean
}
const StyledGridWrapper = styled.div<StyledGridWrapperProps>`
  position: ${({ $absolute }) => ($absolute ? 'absolute' : 'relative')};
  ${({ $absolute }) => ($absolute ? 'inset: 0; padding: 0 32px 8px 32px;' : '')};
  /* border: 3px solid blue; */
  display: flex;
  flex: 1 1 auto;
  & > div[role='presentation'],
  & div[role='rowgroup'],
  & div[role='presentation'] {
    background: transparent !important;
  }

  & div[role='columnheader'] {
    background: transparent !important;
  }
`

export default SpectrumTable
