import DeleteIcon from '@atlaskit/icon/core/delete'
import EditIcon from '@atlaskit/icon/glyph/edit'

import Button, { IconButton } from '@atlaskit/button/new'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback, useState } from 'react'

import Modal, { ModalTransition } from '@atlaskit/modal-dialog'
import { token } from '@atlaskit/tokens'

import SUBMIT_ADDON from '@/utils/api/submitAddon'
import useGraphQLClient from '@/utils/useAuthRequest'

import { AddonReasonEnum, SubmitAddonMutationVariables as MutationVariables } from '@/gql/graphql'

import AddOnForm, { Addon } from './AddOnForm'

const AddOnModal = ({ dispatchId, selectedAddon, isDisabled }: { dispatchId: string; selectedAddon?: Addon; isDisabled?: boolean }) => {
  const [isOpen, setIsOpen] = useState(false)

  const openModal = useCallback(() => setIsOpen(true), [])
  const closeModal = useCallback(() => setIsOpen(false), [])
  const { mutate, isPending } = useDeleteAddon()
  return (
    <>
      {!selectedAddon ? (
        <Button onClick={openModal} appearance='subtle' shouldFitContainer isDisabled={isDisabled}>
          + Addon
        </Button>
      ) : (
        <div style={{ textAlign: 'center', display: 'flex', gap: 12, justifyContent: 'center' }}>
          <IconButton
            icon={iconProps => <EditIcon {...iconProps} primaryColor={token('color.icon.subtle')} />}
            label='Edit'
            onClick={openModal}
            appearance='subtle'
            isDisabled={isPending || isDisabled}
            isTooltipDisabled={false}
          />
          <IconButton
            icon={iconProps => <DeleteIcon {...iconProps} color={token('color.icon.danger')} />}
            label='Delete'
            onClick={handleDeleteAddon}
            appearance='subtle'
            isDisabled={isPending || isDisabled}
            isLoading={isPending}
            isTooltipDisabled={false}
          />
        </div>
      )}

      <ModalTransition>
        {isOpen ? (
          <Modal onClose={closeModal}>
            <AddOnForm setIsOpen={setIsOpen} dispatchId={dispatchId} selectedAddon={selectedAddon} />
          </Modal>
        ) : null}
      </ModalTransition>
    </>
  )

  function handleDeleteAddon() {
    if (selectedAddon)
      mutate({
        dispatchId,
        addonId: selectedAddon?.id,
        shouldDelete: true,
        reason: selectedAddon.reason as AddonReasonEnum,
        payoutAmount: selectedAddon?.payoutAmount,
        note: selectedAddon?.note,
      })
  }
}

export default AddOnModal

const useDeleteAddon = () => {
  const graphQLClient = useGraphQLClient()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: async (variables: MutationVariables) => {
      const response = await graphQLClient.request(SUBMIT_ADDON, variables)
      if (!response.submitAddon.success) {
        throw new Error(response.submitAddon.message)
      }
      return response.submitAddon
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['GetDispatchPayoutDetails'] })
    },
  })

  return mutation
}
