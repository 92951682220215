import { graphql } from '@/gql'

const SUBMIT_ADDON = graphql(/* GraphQL */ `
  mutation SubmitAddon(
    $dispatchId: ID!
    $addonId: ID
    $reason: AddonReasonEnum!
    $payoutAmount: String!
    $shouldDelete: Boolean
    $note: String
  ) {
    submitAddon(
      dispatchId: $dispatchId
      addonId: $addonId
      payoutAmount: $payoutAmount
      reason: $reason
      shouldDelete: $shouldDelete
      note: $note
    ) {
      success
      message
    }
  }
`)

export default SUBMIT_ADDON
