import { Route, Routes, useLocation } from 'react-router'

import { Content } from '@atlaskit/page-layout'

import ReconAppLeftNavigation from './ReconAppLeftNavigation'
import ReconDatePage from './ReconDate/ReconDatePage'
import ReconHome from './ReconHome/ReconHome'
import PrintInstallerPaystub from './ReconInstallerPaystub/PrintPaystub/PrintPaystub'
import ReconInstallerPaystubPage from './ReconInstallerPaystub/ReconInstallerPaystubPage'
import ReconPayroll from './ReconPayroll/ReconPayrollPage'
import FinancialsByWorkOrder from './reports/FinancialsByWorkOrder'

const ReconRouter = () => {
  const location = useLocation()
  const path = location.pathname
  const isPrint = path.includes('/print')
  return (
    <Content>
      {isPrint ? null : <ReconAppLeftNavigation />}

      <Routes>
        <Route index element={<ReconHome />} />

        <Route path='daily' element={<ReconDatePage />} />

        <Route path='payroll'>
          <Route index element={<ReconPayroll />} />
          <Route path='timesheets' element={<ReconPayroll />} />
          <Route path='installer-paystubs' element={<ReconInstallerPaystubPage />} />
          <Route path='installer-paystubs/print' element={<PrintInstallerPaystub />} />
        </Route>

        <Route path='financials/work-order/:startDate?/:endDate?' element={<FinancialsByWorkOrder />} />
      </Routes>
    </Content>
  )
}

export default ReconRouter
