import { Temporal } from '@js-temporal/polyfill'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import useGraphQLClient from '@/utils/useAuthRequest'

import { ReconMonthStatsQuery } from '@/gql/graphql'

import { graphql } from '@/gql'

import { DotIndicator, IndicatorById } from '@/components/MiniCalendarNav/calendarTypes'
import useDisplayMonthYear from '@/components/MiniCalendarNav/useDisplayMonthYear'

type MonthStats = NonNullable<ReconMonthStatsQuery['reconMonthStats']>
type WeekStats = NonNullable<MonthStats['weekStats']>[number]
type DateStats = NonNullable<MonthStats['dateStats']>[number]

const useReconMonthStats = () => {
  const { month: monthNumber, year } = useDisplayMonthYear(false)
  const variables = useMemo(() => ({ monthNumber, year }), [monthNumber, year])

  const graphqlClient = useGraphQLClient()
  const { data } = useQuery({
    queryKey: [RECON_MONTH_STATS_QUERY_KEY, variables],
    queryFn: () => graphqlClient.request(RECON_MONTH_STATS, variables),
  })

  const weekIndicatorsByWeekId: IndicatorById = useMemo(() => {
    return (data?.reconMonthStats?.weekStats ?? ([] as WeekStats[])).reduce((acc, weekStat) => {
      const weekId = `${weekStat.year}-${weekStat.weekNumber}`
      const indicators: DotIndicator[] = []
      if (weekStat.installerTimecardCountIncomplete > 0) {
        indicators.push({
          color: 'red',
          tooltipNumber: weekStat.installerTimecardCountIncomplete,
          tooltipLabel: 'Incomplete',
        })
      }

      if (weekStat.installerTimecardCountCompleted > 0) {
        indicators.push({
          color: 'orange',
          tooltipNumber: weekStat.installerTimecardCountCompleted,
          tooltipLabel: 'Completed',
        })
      }
      if (weekStat.installerTimecardCountApproved > 0) {
        indicators.push({
          color: 'green',
          tooltipNumber: weekStat.installerTimecardCountApproved,
          tooltipLabel: 'Approved',
        })
      }
      acc[weekId] = indicators
      return acc
    }, {} as IndicatorById)
  }, [data?.reconMonthStats?.weekStats])

  const dateIndicatorsByDateId: IndicatorById = useMemo(() => {
    return (data?.reconMonthStats?.dateStats ?? ([] as DateStats[])).reduce((acc, dateStat) => {
      const dateId = Temporal.PlainDate.from(dateStat.date).toString()
      const indicators: DotIndicator[] = []
      if (dateStat.installerDispatchCountIncomplete > 0) {
        indicators.push({
          color: 'red',
          tooltipNumber: dateStat.installerDispatchCountIncomplete,
          tooltipLabel: 'Incomplete',
        })
      }

      if (dateStat.installerDispatchCountCompleted > 0) {
        indicators.push({
          color: 'orange',
          tooltipNumber: dateStat.installerDispatchCountCompleted,
          tooltipLabel: 'Completed',
        })
      }
      if (dateStat.installerDispatchCountApproved > 0) {
        indicators.push({
          color: 'green',
          tooltipNumber: dateStat.installerDispatchCountApproved,
          tooltipLabel: 'Approved',
        })
      }
      acc[dateId] = indicators
      return acc
    }, {} as IndicatorById)
  }, [data?.reconMonthStats?.dateStats])

  // console.log(`${monthNumber}/${year}`, stats)
  // console.log('data?.reconMonthStats', data?.reconMonthStats)

  return { weekIndicatorsByWeekId, dateIndicatorsByDateId }
}

export default useReconMonthStats

export const RECON_MONTH_STATS_QUERY_KEY = 'RECON_MONTH_STATS'
const RECON_MONTH_STATS = graphql(/* GraphQL */ `
  query ReconMonthStats($monthNumber: Int!, $year: Int!) {
    reconMonthStats(monthNumber: $monthNumber, year: $year) {
      monthNumber
      year
      weekStats {
        weekNumber
        year
        installerTimecardCountApproved
        installerTimecardCountCompleted
        installerTimecardCountIncomplete
      }
      dateStats {
        date
        installerDispatchCountApproved
        installerDispatchCountCompleted
        installerDispatchCountIncomplete
      }
    }
  }
`)
