import { useSetAtom } from 'jotai'
import { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { token } from '@atlaskit/tokens'
import Tooltip from '@atlaskit/tooltip'

import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle'

import { CardColorOptions, TRIP_COLORS } from '@/utils/utilities'

import SpinnerAbsolute from '@/components/SpinnerAbsolute'

import useIsPendingRemoval from '../hooks/useIsPendingRemoval'
import useWorkOrderState from '../hooks/useWorkOrderState'

import { workOrderPickedUpAtom, workOrderSelectedAtom } from '../atoms'
import { CalendarWorkOrderType } from '../types'

import SchedulerCardHover from './SchedulerCardHover'

interface SchedulerCardProps {
  calendarDispatch: CalendarWorkOrderType
  dateString?: string
}

const SchedulerCard = ({ calendarDispatch, dateString }: SchedulerCardProps) => {
  const ref = useRef<HTMLDivElement | null>(null)

  const setSelectedWorkOrder = useSetAtom(workOrderSelectedAtom)
  const setPickedUpWorkOrder = useSetAtom(workOrderPickedUpAtom)
  const handleClick = useCallback(() => setSelectedWorkOrder(calendarDispatch), [calendarDispatch, setSelectedWorkOrder])

  const [isDragging, setDragging] = useState(false)

  const workOrderId = calendarDispatch?.workOrderId ?? null
  const { isSelected, isPending, isPlaceholder } = useWorkOrderState({
    workOrderId,
    workOrderDateString: calendarDispatch?.dateString,
    dayCellDateString: dateString,
  })
  const tripName = calendarDispatch?.workOrder?.tripName ?? 'None'
  const accentColor: CardColorOptions = TRIP_COLORS?.[tripName] ?? 'orange'
  const isPendingRemoval = useIsPendingRemoval(workOrderId)
  const isWorkCompleted = calendarDispatch?.workOrder?.isWorkCompleted ?? false
  const isDavisBacon = calendarDispatch?.workOrder?.isDavisBacon ?? false

  useEffect(() => {
    if (ref.current) {
      const initialData: Record<string, string | null> = {
        workOrderId: calendarDispatch?.workOrderId,
        tripName: calendarDispatch?.workOrder?.tripName ?? null,
        dispatchId: calendarDispatch?.dispatchId ?? null,
        dateString: calendarDispatch?.dateString ?? null,
      }

      return draggable({
        element: ref.current,
        canDrag: () => !isWorkCompleted,
        onDragStart: () => {
          setDragging(true)
          setPickedUpWorkOrder(calendarDispatch)
        },
        onDrop: () => setDragging(false),
        getInitialData: () => initialData,
      })
    }
  }, [calendarDispatch, setDragging, setPickedUpWorkOrder, isWorkCompleted])

  return (
    <OuterCardContainer
      ref={ref}
      onClick={handleClick}
      $isHoverPlaceholder={Boolean(isPlaceholder)}
      $isDragging={isDragging || isPendingRemoval}
      $isSelected={isSelected}
      $isPending={isPending}
      $accentColor={accentColor}
      $isConfirmed={Boolean(calendarDispatch?.workOrder?.isConfirmed)}
      $isWorkCompleted={isWorkCompleted}
    >
      <Tooltip
        component={props => <SchedulerCardHover calendarDispatch={calendarDispatch} accentColor={accentColor} {...props} />}
        content='Click to select'
        position='auto-start'
      >
        {({ ref: tooltipRef, ...tooltipProps }) => (
          <div ref={tooltipRef} className='erh-inner-card-container' {...tooltipProps}>
            {isPending && <SpinnerAbsolute size={'medium'} blanket />}
            {isWorkCompleted && (
              <div className='erh-work-completed-section'>
                <CheckCircleIcon
                  label='Work Completed'
                  size='large'
                  primaryColor={token('color.background.accent.green.subtler.pressed')}
                  secondaryColor={token('color.text.accent.green.bolder')}
                />
              </div>
            )}
            <div className='erh-customer-section'>{calendarDispatch?.workOrder?.customerName ?? 'No Customer'}</div>
            <div className='erh-address-section'>
              {isDavisBacon ? <span className='erh-davis-bacon-address'>DB </span> : null}
              {calendarDispatch?.workOrder?.projectSiteAddress ?? 'TBD'}
            </div>
          </div>
        )}
      </Tooltip>
    </OuterCardContainer>
  )
}

export default SchedulerCard

interface CardContainerProps {
  $accentColor: CardColorOptions
  $isDragging: boolean
  $isSelected: boolean
  $isHoverPlaceholder: boolean
  $isPending: boolean
  $isConfirmed?: boolean
  $isWorkCompleted?: boolean
}

const OuterCardContainer = styled.div<CardContainerProps>`
  box-shadow: ${token('elevation.shadow.raised')};
  position: relative;

  box-sizing: border-box;

  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 6px;
  padding: 0;
  max-width: 200px;
  z-index: 0;

  transition:
    box-shadow 0.12s,
    opacity 0.06s;

  opacity: ${({ $isDragging, $isPending }) => ($isDragging || $isPending ? '0.5' : '1')};

  &:hover {
    box-shadow: ${token('elevation.shadow.raised')};
    cursor: ${({ $isWorkCompleted }) => ($isWorkCompleted ? 'pointer' : 'grab')};
  }

  .erh-inner-card-container {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 0px 4px;
    height: 52px;

    border-radius: 4px;
    border: 2px solid
      ${({ $isSelected, $isHoverPlaceholder }) => ($isSelected || $isHoverPlaceholder ? token('color.border.selected') : 'transparent')};
    background: ${({ $accentColor, $isHoverPlaceholder, $isConfirmed }) =>
      $isHoverPlaceholder
        ? token('color.background.selected')
        : token(
            !$accentColor ? 'elevation.surface.raised' : `color.background.accent.${$accentColor}.${$isConfirmed ? 'subtle' : 'subtlest'}`
          )};

    color: ${({ $isPending }) => ($isPending ? token('color.text.subtlest') : token('color.text'))};

    transition:
      border 0.06s,
      background 0.12s;

    &:hover {
      border: 2px solid ${({ $isSelected }) => ($isSelected ? token('color.border.selected') : token('color.border'))};
      background: ${({ $accentColor, $isConfirmed }) =>
        token(
          !$accentColor
            ? 'elevation.surface.raised'
            : `color.background.accent.${$accentColor}.${$isConfirmed ? 'subtle' : 'subtlest'}.hovered`
        )};
    }
  }

  .erh-customer-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 4px 2px;
    border-bottom: 1px solid ${token('color.border')};

    font-weight: bold;
    font-size: 13px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .erh-address-section {
    display: -webkit-box;
    padding: 4px 2px;
    -webkit-box-orient: vertical;

    font-size: 11px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .erh-davis-bacon-address {
      font-weight: 800;
      letter-spacing: -0.08em;
      margin-right: 1px;
      color: ${({ $accentColor }) =>
        // @ts-expect-error known issue with token function
        !$accentColor ? token(`color.text.sublest`) : token(`color.text.accent.${$accentColor}`)};
    }
  }

  .erh-work-completed-section {
    background: ${token('color.blanket')};
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    span {
      /* border: 3px solid red; */
      border-radius: 50%;
      box-shadow:
        0 1px 4px rgba(0, 0, 0, 0.3),
        0 0px 1px rgba(0, 0, 0, 0.8);
      svg > g {
        transform: scale(1.2);
        transform-origin: center;
      }
    }
  }
`
