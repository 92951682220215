import { useAtomValue } from 'jotai'
import { useEffect, useMemo, useRef, useState } from 'react'

import { showMinutesAsHrDecimalAtom } from '../utils/appAtoms'

import HoursMinutesSeparatedInput from './HoursMinutesSeparatedInput'
import TableCellNumberTextField from './TableCellNumberTextField'

interface HoursMinutesInputProps {
  minutes: number | null
  onChangeCommit: (newMinutes: number | null) => void
  isDisabled?: boolean
  width?: number
  usedWithReadOnly?: boolean
}

const HoursMinutesInput = ({
  minutes = null,
  onChangeCommit,
  isDisabled = false,
  width,
  usedWithReadOnly = false,
}: HoursMinutesInputProps) => {
  // Refs for handling Escape key and focus switching.
  const showMinutesAsHrDecimal = useAtomValue(showMinutesAsHrDecimalAtom)

  const escapePressedRef = useRef(false)

  const hoursDecimalString: undefined | string = useMemo(() => {
    let result = undefined
    let decimal = undefined
    if (minutes !== null && minutes !== undefined) {
      decimal = minutes / 60
      if (minutes === 0) result = '0.00'
      else if (!isNaN(decimal)) result = decimal.toFixed(2)
    }
    return result
  }, [minutes])
  const [hoursDecimalText, setHoursDecimalText] = useState<string | undefined>(hoursDecimalString)

  // Update local state when prop minutes changes.
  useEffect(() => {
    setHoursDecimalText(hoursDecimalString)
  }, [hoursDecimalString, minutes])

  // Handle key presses: Escape resets fields without committing; Enter blurs.
  // Also, prevent a decimal character in the minutes field.
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      escapePressedRef.current = true

      setHoursDecimalText(hoursDecimalString ?? undefined)
      e.currentTarget.blur()
      return
    }
    if (e.key === 'Enter') {
      e.currentTarget.blur()
      return
    }
  }

  return showMinutesAsHrDecimal ? (
    <TableCellNumberTextField
      isCompact
      value={hoursDecimalText}
      isDisabled={isDisabled}
      suffix='hr'
      onBlur={handleDecimalBlur}
      onChange={handleDecimalChange}
      onKeyDown={handleKeyDown}
    />
  ) : (
    <HoursMinutesSeparatedInput
      minutes={minutes}
      onChangeCommit={onChangeCommit}
      isDisabled={isDisabled}
      width={width}
      usedWithReadOnly={usedWithReadOnly}
    />
  )

  function handleDecimalChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value
    console.log('handleDecimalChange', value)
    setHoursDecimalText(value)
  }

  function handleDecimalBlur() {
    if (hoursDecimalText === '' || hoursDecimalText === undefined) return onChangeCommit(null)
    const hoursDecimal = parseFloat(hoursDecimalText)

    console.log('handleDecimalBlur', { hoursDecimal, hoursDecimalText })
    if (isNaN(hoursDecimal)) return onChangeCommit(null)
    onChangeCommit(Math.round(hoursDecimal * 60))
  }
}

export default HoursMinutesInput
