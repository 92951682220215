import { flexRender, Row } from '@tanstack/react-table'

import NumberCell from '@/components/NumberCell'

import { DispatchRowType } from '../PaystubStatusCell'

import { getPieceRateAddonPayouts } from './paystubUtils'

const PrintTableDispatchesRow = ({ installerDispatchRow, rowIdx }: { installerDispatchRow: Row<DispatchRowType>; rowIdx: number }) => {
  const installerDispatch = installerDispatchRow.original
  const { newRowNumber } = getPieceRateAddonPayouts(installerDispatch)
  const cells = installerDispatchRow.getVisibleCells()
  const rowsLength = cells.length
  const rowIndex = rowIdx
  const rowClass = rowIndex % 2 === 0 ? 'installer-paystub-row-even' : 'installer-paystub-row-odd'
  return (
    <>
      <tr className={rowClass}>
        {cells.map(cell => (
          <td key={cell.id} style={cell.column.columnDef.meta?.style}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        ))}
      </tr>
      {newRowNumber === null ? null : (
        <tr key={newRowNumber} className={`installer-paystub-row-bonus ${rowClass}`}>
          <td colSpan={rowsLength - 1} className='installer-paystub-row-bonus-text'>
            Bonus
          </td>
          <td colSpan={rowsLength}>
            <NumberCell value={newRowNumber} prefix='$' readOnly />
          </td>
        </tr>
      )}
    </>
  )
}

export default PrintTableDispatchesRow
