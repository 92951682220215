// ui/src/components/MiniCalendarNav/IndicatorDot.tsx

import { token } from '@atlaskit/tokens'
import styled from 'styled-components'
import { DotIndicatorColor } from './calendarTypes'

const IndicatorDot = ({ color }: { color: DotIndicatorColor }) => {
  const mappedColor = mapIndicatorColor(color)
  return <Dot $dotColor={mappedColor} />
}

export default IndicatorDot

const Dot = styled.span<{ $dotColor: string }>`
  width: 6px;
  height: 6px;
  max-width: 6px;
  max-height: 6px;
  min-width: 6px;
  min-height: 6px;
  border-radius: 50%;
  background-color: ${({ $dotColor }) => $dotColor};
  display: inline-block;
`

function mapIndicatorColor(color: string) {
  switch (color) {
    case 'lime':
      return token('color.chart.success.bold', 'lime')
    case 'red':
      return token('color.background.accent.red.subtle')
    case 'orange':
      return token('color.background.accent.orange.subtle')
    case 'yellow':
      return token('color.background.accent.yellow.subtle', 'yellow')
    case 'green':
      return token('color.background.accent.green.subtle')
    case 'teal':
      return token('color.chart.discovery.bold', 'teal')
    case 'blue':
      return token('color.chart.brand', 'blue')
    case 'purple':
      return token('color.chart.discovery.bold', 'purple')
    case 'magenta':
      return token('color.chart.danger.bold', 'magenta')
    case 'gray':
      return token('color.chart.neutral', 'gray')
    default:
      return token('color.chart.neutral', 'gray')
  }
}

export const IndicatorDotTooltipRow = ({ value, label, color }: { value: number; label: string; color: DotIndicatorColor }) => {
  return (
    <TooltipRowWrapper>
      <div>
        <IndicatorDot color={color} />
      </div>
      <div>{value}</div>
      <div>{label}</div>
    </TooltipRowWrapper>
  )
}

const TooltipRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  :nth-child(2) {
    width: 16px;
    text-align: right;
  }
`
