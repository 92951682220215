import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useCallback, useMemo, useState } from 'react'

import { DispatchAssignmentAction as Actions, GetDispatchDateQuery, SubmitDispatchAssignmentMutationVariables } from '@/gql/graphql'
import useGraphQLClient from '@/utils/useAuthRequest'

import { GET_DISPATCH_DATE_QUERY_KEY } from '../hooks/useDispatchDateQuery'

import SUBMIT_DISPATCH_ASSIGNMENT from '@/utils/api/submitDispatchAssignment'
import { isSavingAssignmentAtom, sortedVehicleDispatchIdsByVehicleIdAtom, vehicleDispatchIdsByVehicleIdAtom } from '../../dispatchTypes'

type SortedVehicleDispatchIds = GetDispatchDateQuery['vehiclesByDate'][number]['sortedVehicleDispatchIds']

const useSelectedVehicleDispatchIds = (vehicleId: string, dateString?: string) => {
  const [optimisticIds, setOptimisticIds] = useState<string[] | null>(null)
  const graphQLClient = useGraphQLClient()
  const queryClient = useQueryClient()

  const setIsSavingAssignment = useSetAtom(isSavingAssignmentAtom)

  const sortedVehicleDispatchIdsAtom = useMemo(
    () => selectAtom(sortedVehicleDispatchIdsByVehicleIdAtom, state => state?.[vehicleId] as SortedVehicleDispatchIds),
    [vehicleId]
  )
  const vehicleDispatchIdsAtom = useMemo(
    () => selectAtom(vehicleDispatchIdsByVehicleIdAtom, state => (state?.[vehicleId] ?? []) as string[]),
    [vehicleId]
  )

  const ids = useAtomValue(vehicleDispatchIdsAtom)
  const sortedIds = useAtomValue(sortedVehicleDispatchIdsAtom)

  const result = useMemo(() => {
    const sortedIdsLength = sortedIds?.length ?? 0
    const idsLength = ids?.length ?? 0
    return {
      vehicleDispatchIds: optimisticIds !== null ? optimisticIds : sortedIdsLength < idsLength ? ids : (sortedIds ?? ids ?? []),
      hasSortedVehicleDispatchIds: !!sortedIds || optimisticIds !== null,
    }
  }, [sortedIds, ids, optimisticIds])

  const { mutate } = useMutation({
    mutationFn: async (variables: SubmitDispatchAssignmentMutationVariables) => {
      setIsSavingAssignment(true)
      const result = await graphQLClient.request(SUBMIT_DISPATCH_ASSIGNMENT, variables)
      if (!result.submitDispatchAssignment.success) throw new Error(result.submitDispatchAssignment.message)
      return result
    },
    onSuccess: () => console.info('mutation success'),
    onError: error => console.error('mutation error: ', error),
    onSettled: () => setIsSavingAssignment(false),
  })

  const moveOrder = useCallback(
    ({ currentIndex, newIndex }: { currentIndex: number; newIndex: number }) => {
      const newIds = [...result.vehicleDispatchIds]
      const [movedId] = newIds.splice(currentIndex, 1)
      newIds.splice(newIndex, 0, movedId)
      setOptimisticIds(newIds)
      if (dateString)
        mutate({ sortedVehicleDispatchIds: newIds, targetVehicleId: vehicleId, action: Actions.UpdateSortOrder, date: dateString })
    },
    [result.vehicleDispatchIds, setOptimisticIds, mutate, vehicleId, dateString]
  )

  const clearVehicle = useCallback(
    (vehicleId: string, dateString: string) => {
      mutate(
        { originVehicleId: vehicleId, action: Actions.RemoveVehicleDispatchAll, date: dateString },
        { onSuccess: () => queryClient.invalidateQueries({ queryKey: [GET_DISPATCH_DATE_QUERY_KEY, dateString] }) }
      )
    },
    [mutate, queryClient]
  )
  return { ...result, moveOrder, clearVehicle }
}

export default useSelectedVehicleDispatchIds
