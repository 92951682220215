import { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import Textfield from '@atlaskit/textfield'
import { token } from '@atlaskit/tokens'

import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'

import { HR_MIN_MINUTES_WIDTH } from './HrMinCell'

interface HoursMinutesSeparatedInputProps {
  minutes: number | null
  onChangeCommit: (newMinutes: number | null) => void
  isDisabled?: boolean
  width?: number
  usedWithReadOnly?: boolean
}

const HoursMinutesSeparatedInput = ({
  minutes = null,
  onChangeCommit,
  isDisabled = false,
  width,
  usedWithReadOnly = false,
}: HoursMinutesSeparatedInputProps) => {
  // Refs for handling Escape key and focus switching.

  const escapePressedRef = useRef(false)
  const hasCommittedRef = useRef(false)
  const hoursInputRef = useRef<HTMLInputElement>(null)
  const minutesInputRef = useRef<HTMLInputElement>(null)

  // Derive the “database” (initial) text values from the prop minutes.
  const { databaseHoursText, databaseMinutesText } = useMemo(() => {
    const databaseHours = minutes === null ? null : Math.floor(minutes / 60)
    const databaseMinutes = minutes === null ? null : minutes % 60
    return {
      databaseHoursText: databaseHours === null ? '' : String(databaseHours),
      databaseMinutesText: databaseMinutes === null ? '' : String(databaseMinutes),
    }
  }, [minutes])

  // Local state holds the text in each field.
  const [hoursText, setHoursText] = useState<string>(databaseHoursText)
  const [minutesText, setMinutesText] = useState<string>(databaseMinutesText)

  // Update local state when prop minutes changes.
  useEffect(() => {
    setHoursText(databaseHoursText)
    setMinutesText(databaseMinutesText)
  }, [databaseHoursText, databaseMinutesText])

  // Reset commit flag on focus so we know we are in a new editing session.
  const handleFocus = () => (hasCommittedRef.current = false)

  // Handle key presses: Escape resets fields without committing; Enter blurs.
  // Also, prevent a decimal character in the minutes field.
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      escapePressedRef.current = true
      setHoursText(databaseHoursText)
      setMinutesText(databaseMinutesText)
      e.currentTarget.blur()
      return
    }
    if (e.key === 'Enter') {
      e.currentTarget.blur()
      return
    }
    if (e.key === '.' && e.currentTarget === minutesInputRef.current) e.preventDefault()
  }

  // Update local state as the user types.
  // Hours field allows decimals.
  const handleHoursChange = (e: React.ChangeEvent<HTMLInputElement>) => setHoursText(e.target.value)

  // Minutes field: strip out any accidental decimals.
  const handleMinutesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value
    if (value.includes('.')) value = value.split('.')[0]
    setMinutesText(value)
  }

  /**
   * commitChanges now accepts optional override values.
   * If provided, they override the current state values.
   */
  const commitChanges = (overrideHours?: string, overrideMinutes?: string) => {
    let localHoursText = (overrideHours !== undefined ? overrideHours : hoursText).trim()
    let localMinutesText = (overrideMinutes !== undefined ? overrideMinutes : minutesText).trim()

    // If hours field contains a decimal, convert its fractional part into minutes.
    if (localHoursText.includes('.')) {
      const [integerPart, decimalPartRaw] = localHoursText.split('.')
      const integerPartTrimmed = integerPart.trim() || '0'
      const decimalPart = decimalPartRaw.trim()
      const hoursNumber = parseInt(integerPartTrimmed, 10) || 0
      let minutesNumber: number

      if (decimalPart === '' || /^[0]+$/.test(decimalPart)) {
        // If the decimal portion is effectively zero, then leave the minutes field as is (default to 0 if blank).
        minutesNumber = localMinutesText === '' ? 0 : parseInt(localMinutesText, 10) || 0
      } else {
        const decimalValue = parseFloat('0.' + decimalPart)
        minutesNumber = Math.round(decimalValue * 60)
      }
      localHoursText = String(hoursNumber)
      localMinutesText = String(minutesNumber)
      // Update state with the computed values.
      setHoursText(localHoursText)
      setMinutesText(localMinutesText)
    }

    let newMinutes: number | null
    if (localHoursText === '' && localMinutesText === '') newMinutes = null
    else {
      const hrs = localHoursText === '' ? 0 : parseInt(localHoursText, 10)
      const mins = localMinutesText === '' ? 0 : parseInt(localMinutesText, 10)
      newMinutes = hrs * 60 + mins
    }

    // If the computed minutes equal the original minutes, do not fire onChangeCommit.
    if (newMinutes === minutes) {
      hasCommittedRef.current = true
      return
    }
    hasCommittedRef.current = true
    onChangeCommit(newMinutes)
  }

  /**
   * onBlurHours handles the case when focus leaves the hours field.
   * If focus is moving to the minutes field and a decimal is present,
   * it converts the fractional portion to minutes without immediately committing.
   */
  const onBlurHours = () => {
    if (escapePressedRef.current) {
      escapePressedRef.current = false
      return
    }
    setTimeout(() => {
      if (document.activeElement === minutesInputRef.current) {
        // Focus moved to the minutes field.
        if (hoursText.includes('.')) {
          const [integerPart, decimalPartRaw] = hoursText.split('.')
          const integerPartTrimmed = integerPart.trim() || '0'
          const decimalPart = decimalPartRaw.trim()
          const hoursNumber = parseInt(integerPartTrimmed, 10) || 0
          let newMinutes: number
          if (decimalPart === '' || /^[0]+$/.test(decimalPart)) {
            newMinutes = minutesText.trim() === '' ? 0 : parseInt(minutesText, 10) || 0
          } else {
            const decimalValue = parseFloat('0.' + decimalPart)
            newMinutes = Math.round(decimalValue * 60)
          }
          setHoursText(String(hoursNumber))
          setMinutesText(String(newMinutes))
        }
      } else {
        if (!hasCommittedRef.current) {
          commitChanges()
        }
      }
    }, 0)
  }

  /**
   * onBlurMinutes handles the minutes field blur.
   * Here we implement the new behavior:
   *
   * - If the entered minutes (after trimming) are greater than 60 and
   *   the hours field is empty, then convert the minutes value to hours and minutes.
   * - If the entered minutes are greater than 60 but the hours field already has a value,
   *   then keep only the last two digits of the minutes input.
   */
  const onBlurMinutes = () => {
    if (escapePressedRef.current) {
      escapePressedRef.current = false
      return
    }
    setTimeout(() => {
      const localMinutesStr = minutesText.trim()
      const parsedMinutes = parseInt(localMinutesStr, 10)
      let overrideHours: string | undefined = undefined
      let overrideMinutes: string | undefined = undefined

      if (!isNaN(parsedMinutes) && parsedMinutes > 60) {
        if (hoursText.trim() === '') {
          // Convert the total minutes to hours and minutes.
          const computedHours = Math.floor(parsedMinutes / 60)
          const computedMinutes = parsedMinutes % 60
          overrideHours = String(computedHours)
          overrideMinutes = String(computedMinutes)
          setHoursText(overrideHours)
          setMinutesText(overrideMinutes)
        } else {
          // If hours field is non-empty, only keep the last two digits.
          if (localMinutesStr.length > 2) {
            overrideMinutes = localMinutesStr.slice(-2)
            // If the resulting minutes are "00", convert them to "0".
            if (overrideMinutes === '00') {
              overrideMinutes = '0'
            }
            setMinutesText(overrideMinutes)
          }
        }
      }
      // Commit changes using any override values (if provided).
      if (!hasCommittedRef.current) {
        commitChanges(overrideHours, overrideMinutes)
      }
    }, 0)
  }

  return (
    <DualFieldWrapper $mono={true} className={usedWithReadOnly ? 'hours-minutes-input-readonly' : ''}>
      <Textfield
        appearance='subtle'
        isCompact
        isDisabled={isDisabled}
        elemAfterInput={suffixHr}
        width={width}
        type='number'
        placeholder='-'
        value={hoursText}
        onChange={handleHoursChange}
        onKeyDown={handleKeyDown}
        onBlur={onBlurHours}
        onFocus={handleFocus}
        ref={hoursInputRef}
      />
      <Textfield
        appearance='subtle'
        isCompact
        isDisabled={isDisabled}
        elemAfterInput={suffixMin}
        width={width}
        type='number'
        placeholder='-'
        value={minutesText}
        onChange={handleMinutesChange}
        onKeyDown={handleKeyDown}
        onBlur={onBlurMinutes}
        onFocus={handleFocus}
        ref={minutesInputRef}
      />
    </DualFieldWrapper>
  )
}

const SuffixText = styled.span`
  font-weight: 500;
  color: ${token('color.text.disabled')};
  margin-right: 8px;
`

const suffixHr = <SuffixText>h</SuffixText>
const suffixMin = <SuffixText>m</SuffixText>

const DualFieldWrapper = styled.div<{ $mono: boolean }>`
  width: 100%;
  height: 100%;
  min-width: 80px; /* Prevent the container from shrinking too much */
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-end;
  box-sizing: border-box;
  padding-left: 4px;

  * {
    box-sizing: border-box;
  }

  &.hours-minutes-input-readonly {
    > div {
      &:first-child {
        padding-right: 0;
        > span {
          margin-right: 2px;
        }
      }
      &:last-child {
        max-width: ${HR_MIN_MINUTES_WIDTH + 8}px;
        width: ${HR_MIN_MINUTES_WIDTH + 8}px;
      }
      > input {
        padding-right: 2px !important;
      }
      > span {
        margin-left: 0;
      }
    }
  }

  /* Container for each Textfield */
  > div {
    flex: 1 1 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 0 0px; /* Optional horizontal padding */

    &:first-child {
      min-width: ${HR_MIN_MINUTES_WIDTH + 8}px;
      max-width: ${HR_MIN_MINUTES_WIDTH + 8}px;
      width: ${HR_MIN_MINUTES_WIDTH + 8}px;
    }

    &:last-child {
      min-width: ${HR_MIN_MINUTES_WIDTH + 8}px;
      max-width: ${HR_MIN_MINUTES_WIDTH + 8}px;
      width: ${HR_MIN_MINUTES_WIDTH + 8}px;
    }
    /* The actual text input field */
    > input {
      min-width: 50px; /* Ensures a clickable input even in compact layouts */
      flex: 1 1 auto;
      text-align: right !important;
      appearance: none;
      -moz-appearance: textfield;
      font-family: ${({ $mono }) => ($mono ? MONOSPACE_FONT_FAMILY : 'inherit')};
      padding: 0 4px !important;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* The suffix text */
    > span {
      flex: 0 0 auto;
      margin-left: 2px; /* Space between input and suffix */
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`

export default HoursMinutesSeparatedInput
