import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useAtomValue, useSetAtom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'

import { graphql } from '@/gql'
import { GetDispatchDateQuery } from '@/gql/graphql'
import useGraphQLClient from '@/utils/useAuthRequest'

import {
  assignedVehicleDispatchIdsByInstallerIdAtom,
  Dispatch,
  dispatchesByIdAtom,
  dispatchIdsByTripAtom,
  dispatchIdsByVehicleIdAtom,
  IdListById,
  NullableIdListById,
  pendingMutationsAtom,
  sortedVehicleDispatchIdsByVehicleIdAtom,
  VehicleDispatch,
  vehicleDispatchesByIdAtom,
  vehicleDispatchIdsByVehicleIdAtom,
} from '../../dispatchTypes'
import { GET_CALCULATED_LOAD_SHEET_QUERY_KEY } from '../components/LoadSheetSection'

export const useAvailableDispatchesCount = (): number => {
  const dispatchesByIdLengthAtom = useMemo(() => selectAtom(dispatchesByIdAtom, dispatchesById => Object.keys(dispatchesById).length), [])
  return useAtomValue(dispatchesByIdLengthAtom)
}

const useDispatchDateQuery = (dateString: string) => {
  const graphQLClient = useGraphQLClient()
  const queryClient = useQueryClient()
  const setAssignedVehicleDispatchIdsByInstallerId = useSetAtom(assignedVehicleDispatchIdsByInstallerIdAtom)
  const setDispatchesById = useSetAtom(dispatchesByIdAtom)
  const setDispatchIdsByTrip = useSetAtom(dispatchIdsByTripAtom)
  const setDispatchIdsByVehicleId = useSetAtom(dispatchIdsByVehicleIdAtom)
  const setSortedVehicleDispatchIdsByVehicleId = useSetAtom(sortedVehicleDispatchIdsByVehicleIdAtom)
  const setVehicleDispatchesById = useSetAtom(vehicleDispatchesByIdAtom)
  const setVehicleDispatchIdsByVehicleId = useSetAtom(vehicleDispatchIdsByVehicleIdAtom)

  const setPendingMutations = useSetAtom(pendingMutationsAtom)

  const { data, error, isPending, refetch } = useQuery({
    queryKey: [GET_DISPATCH_DATE_QUERY_KEY, dateString],
    queryFn: async () => {
      const result = await graphQLClient.request(GET_DISPATCH_DATE, { date: dateString })
      if (!result || result.dispatchesForDate === undefined) throw new Error('Dispatches Database Query Failed')

      const {
        assignedVehicleDispatchIdsByInstallerId,
        dispatchesById,
        dispatchIdsByTrip,
        dispatchIdsByVehicleId,
        sortedVehicleDispatchIdsByVehicleId,
        vehicleDispatchesById,
        vehicleDispatchIdsByVehicleId,
      } = parseDispatchDateQueryResponse(result, dateString)

      setAssignedVehicleDispatchIdsByInstallerId(assignedVehicleDispatchIdsByInstallerId)
      setDispatchesById(dispatchesById)
      setDispatchIdsByTrip(dispatchIdsByTrip)
      setDispatchIdsByVehicleId(dispatchIdsByVehicleId)
      setSortedVehicleDispatchIdsByVehicleId(sortedVehicleDispatchIdsByVehicleId)
      setVehicleDispatchesById(vehicleDispatchesById)
      setVehicleDispatchIdsByVehicleId(vehicleDispatchIdsByVehicleId)

      setPendingMutations({})
      queryClient.invalidateQueries({ queryKey: [GET_CALCULATED_LOAD_SHEET_QUERY_KEY, dateString] })
      return result
    },
  })

  const dispatches = data?.dispatchesForDate ?? []

  return { dispatches, error, isPending, refetch }
}

export default useDispatchDateQuery

export const GET_DISPATCH_DATE_QUERY_KEY = 'dispatchDate'
const GET_DISPATCH_DATE = graphql(/* GraphQL */ `
  query GetDispatchDate($date: Date!) {
    vehiclesByDate(date: $date) {
      id
      sid
      date
      sortedVehicleDispatchIds
    }

    dispatchesForDate(date: $date, confirmedOnly: true) {
      id
      sid
      date
      prefetchedAssignedVehicleIds

      workOrderId
      workOrder {
        id
        name
        isConfirmed
        isWorkCompleted
        tripName
        jobId
        jobTitle
        projectSiteAddress
        customerName
        estimateId
        estimateTitle
        isDavisBacon
      }

      prefetchedVehicleAssignments {
        id
        sid
        dispatchId
        filterDate
        vehicleId
        prefetchedAssignedInstallerIds
      }
    }
  }
`)

function parseDispatchDateQueryResponse(result: GetDispatchDateQuery, dateString: string) {
  const sortedVehicleDispatchIdsByVehicleId: NullableIdListById = result.vehiclesByDate.reduce((acc, vehicle) => {
    const vehicleId = vehicle.id
    acc[vehicleId] = vehicle.sortedVehicleDispatchIds
    return acc
  }, {} as NullableIdListById)

  const assignedVehicleDispatchIdsByInstallerId: IdListById = {}
  const dispatchesById: Record<string, Dispatch> = {}
  const dispatchIdsByTrip: IdListById = {}
  const dispatchIdsByVehicleId: IdListById = {}
  const vehicleDispatchesById: Record<string, VehicleDispatch> = {}
  const vehicleDispatchIdsByVehicleId: IdListById = {}

  if (dateString) {
    assignedVehicleDispatchIdsByInstallerId.dateString = dateString
    dispatchIdsByTrip.dateString = dateString
    vehicleDispatchIdsByVehicleId.dateString = dateString
    sortedVehicleDispatchIdsByVehicleId.dateString = dateString
  }

  result.dispatchesForDate.forEach(dispatch => {
    const { id: dispatchId, workOrder, prefetchedVehicleAssignments } = dispatch
    dispatchesById[dispatchId] = dispatch

    const tripName = workOrder?.tripName ?? ''
    dispatchIdsByTrip[tripName] = [...(dispatchIdsByTrip[tripName] ?? []), dispatchId]

    prefetchedVehicleAssignments.forEach(vehicleDispatch => {
      const { id: vehicleDispatchId, prefetchedAssignedInstallerIds } = vehicleDispatch
      vehicleDispatchIdsByVehicleId[vehicleDispatchId] = [...(vehicleDispatchIdsByVehicleId[vehicleDispatchId] ?? []), vehicleDispatchId]

      vehicleDispatchesById[vehicleDispatchId] = vehicleDispatch

      dispatchIdsByVehicleId[vehicleDispatchId] = [...(dispatchIdsByVehicleId[vehicleDispatchId] ?? []), dispatchId]

      if (prefetchedAssignedInstallerIds)
        prefetchedAssignedInstallerIds.forEach(installerId => {
          assignedVehicleDispatchIdsByInstallerId[installerId] = [
            ...(assignedVehicleDispatchIdsByInstallerId[installerId] ?? []),
            vehicleDispatchId,
          ]
        })
    })
  })
  const toReturn = {
    assignedVehicleDispatchIdsByInstallerId,
    dispatchesById,
    dispatchIdsByTrip,
    dispatchIdsByVehicleId,
    sortedVehicleDispatchIdsByVehicleId,
    vehicleDispatchesById,
    vehicleDispatchIdsByVehicleId,
  }
  console.log('parseDispatchDateQueryResponse: ', { result, ...toReturn })

  return toReturn
}
