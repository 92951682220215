import { Temporal } from '@js-temporal/polyfill'
import { ColumnMeta, createColumnHelper } from '@tanstack/react-table'
import { useMemo } from 'react'

import HrMinCell from '@/components/HrMinCell'
import NumberCell from '@/components/NumberCell'

import { DispatchRowType } from '../PaystubStatusCell'

import { getPieceRateAddonPayouts } from './paystubUtils'

const columnHelper = createColumnHelper<DispatchRowType>()

const usePrintDispatchesColumns = ({ isHourlyInstaller, isDavisBacon }: { isHourlyInstaller: boolean; isDavisBacon: boolean }) => {
  return useMemo(() => getPrintDispatchesColumns({ isHourlyInstaller, isDavisBacon }), [isHourlyInstaller, isDavisBacon])
}

export default usePrintDispatchesColumns

const meta: ColumnMeta<DispatchRowType, string> = {
  align: 'right',
  style: {
    padding: 0,
  },
}

export const getPrintDispatchesColumns = ({ isHourlyInstaller, isDavisBacon }: { isHourlyInstaller: boolean; isDavisBacon: boolean }) => {
  const columnDefs = [
    columnHelper.accessor('vehicleDispatch.dispatch.date', {
      header: 'Date',
      cell: info => {
        const date = info.row.original.vehicleDispatch.dispatch.date
        return Temporal.PlainDate.from(date).toLocaleString('en-US', {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
        })
      },
    }),
    columnHelper.accessor('vehicleDispatch.dispatch.workOrder.customerName', {
      header: 'Customer Name',
    }),
    columnHelper.accessor('vehicleDispatch.dispatch.workOrder.projectSiteAddress', {
      header: 'Job Site Address',
      cell: info => (!!info.getValue() && info.getValue()?.includes(', IA') ? info.getValue()?.split(', IA')[0] : info.getValue()),
    }),
    columnHelper.accessor('vehicleDispatch.dispatch.workOrder.name', {
      header: 'Work Order',
      cell: info => {
        const workOrder = info.row.original.vehicleDispatch.dispatch.workOrder
        return (
          <span>
            <span className='work-order-id'>#{workOrder?.id}-</span>
            {workOrder?.name}
          </span>
        )
      },
    }),
  ]

  if (isDavisBacon) {
    columnDefs.push(
      columnHelper.accessor('hourlyRate', {
        header: 'Hourly Rate',
        meta,
        cell: info => (!info.row.original.isDavisBacon ? '' : <NumberCell value={info.getValue()} readOnly prefix='$' suffix='/ hr' />),
      })
    )
  }

  if (isDavisBacon || isHourlyInstaller) {
    columnDefs.push(
      // @ts-expect-error - push is not defined on columnDefs
      columnHelper.accessor('minutesWorked', {
        header: 'Hours Worked',
        meta,
        cell: info => <HrMinCell value={info.getValue()} />,
      })
    )
  }

  const minutesColumn = columnHelper.accessor('minutesTotal', {
    header: 'Hours Total',
    meta,
    cell: info => <HrMinCell value={info.getValue()} />,
  })

  const remainingColumn = columnHelper.accessor('payoutTotal', {
    header: 'Payout',
    meta,
    cell: info => {
      const payoutNumber = getPieceRateAddonPayouts(info.row.original).payoutNumber

      return <NumberCell value={payoutNumber} readOnly prefix='$' />
    },
  })

  // @ts-expect-error - push is not defined on columnDefs
  if (isHourlyInstaller) columnDefs.push(minutesColumn)
  columnDefs.push(remainingColumn)

  console.log('columnDefs', columnDefs)
  return columnDefs
}
