import Link from '@atlaskit/link'

import { GetInstallerPayrollQuery } from '@/gql/graphql'

import useReconSearchParams from '../useReconSearchParams'

type InstallerPayrollType = NonNullable<GetInstallerPayrollQuery['installerPayroll']>
export type DispatchRowType = NonNullable<InstallerPayrollType['installerDispatches']>[number]

const PaystubStatusCell = ({ installerDispatch }: { installerDispatch: DispatchRowType }) => {
  const status = !installerDispatch.isCompleted ? 'Incomplete' : !installerDispatch.isApproved ? 'Unapproved' : 'Approved'
  const { paramValues } = useReconSearchParams()
  const params = new URLSearchParams(paramValues)
  const dateId = installerDispatch.vehicleDispatch.dispatch.date
  const vehicleDateId = installerDispatch.vehicleDispatch.vehicleDateId
  params.set('dateId', dateId)
  params.set('vehicleDispatchId', installerDispatch.vehicleDispatch.id)
  params.set('vehicleDateId', vehicleDateId)
  const href = `/recon/daily?${params.toString()}`
  return (
    <Link href={href} target='_blank'>
      {status}
    </Link>
  )
}

export default PaystubStatusCell
