import styled from 'styled-components'

import Link from '@atlaskit/link'
import { token } from '@atlaskit/tokens'

import { GetDispatchPayoutDetailsQuery } from '@/gql/graphql'
import { CardColorOptions, TRIP_COLORS } from '@/utils/utilities'

type WorkOrderType = NonNullable<GetDispatchPayoutDetailsQuery['workOrder']>

interface WorkOrderCellProps {
  workOrder: WorkOrderType | undefined
}
export const WORK_ORDER_CELL_WIDTH = 213
const WorkOrderCell = ({ workOrder, ...rest }: WorkOrderCellProps) => {
  const jobId = workOrder?.jobId ?? null
  const estimateId = workOrder?.estimateId ?? null
  const tripName = workOrder?.tripName ?? 'None'
  const accentColor: CardColorOptions = TRIP_COLORS?.[tripName] ?? 'orange'
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        inset: 0,
      }}
    >
      <CellWrapper $accentColor={accentColor} {...rest}>
        <div className='erh-tooltip-customer-section'>
          <div className='erh-tripName'>
            <span className='erh-davis-bacon'>{workOrder?.isDavisBacon && 'DB'}</span>#{workOrder?.id}-{workOrder?.name ?? '-'}
          </div>

          <div className='erh-customerName'>{workOrder?.customerName ?? 'No Customer Name'}</div>
        </div>

        <div className='erh-tooltip-address-section'>
          <div className='erh-address'>{workOrder?.projectSiteAddress ?? 'TBD'}</div>

          <Link href={`/jobs/${jobId}/estimates/${estimateId}`} target='_blank'>
            {workOrder?.estimateTitle ?? 'Link to Estimate'}
          </Link>
        </div>
      </CellWrapper>
    </div>
  )
}

export default WorkOrderCell

interface CellWrapperProps {
  $accentColor: string
}

const CellWrapper = styled.div<CellWrapperProps>`
  background: ${({ $accentColor }) =>
    token(
      // @ts-expect-error known issue with token function
      !$accentColor ? 'elevation.surface.raised' : `color.background.accent.${$accentColor}.subtler`
    )};

  transition:
    background 0.2s ease-in-out,
    border-color 0.2s ease-in-out;

  padding: 0px 4px;
  position: relative;
  color: ${token('color.text')};
  box-sizing: border-box;
  border: 2px solid
    ${({ $accentColor }) =>
      !$accentColor
        ? 'transparent'
        : token(
            // @ts-expect-error known issue with token function
            `color.border.accent.${$accentColor}`
          )};
  border-left-width: 3px;
  border-right-width: 1px;
  border-radius: 6px 0 0 6px;
  overflow: hidden;
  min-width: ${WORK_ORDER_CELL_WIDTH}px;
  width: ${WORK_ORDER_CELL_WIDTH}px;
  max-width: ${WORK_ORDER_CELL_WIDTH}px;
  height: 100%;

  display: flex;
  flex-direction: column;

  .erh-tooltip-customer-section {
    display: flex;
    flex-direction: column;

    padding: 4px 2px;
    border-bottom: 1px solid ${token('color.border')};
    .erh-customerName {
      font-weight: bold;
      font-size: 13px;
    }
    .erh-tripName {
      font-size: 11px;
      color: ${({ $accentColor }) =>
        // @ts-expect-error known issue with token function
        !$accentColor ? token(`color.text.sublest`) : token(`color.text.accent.${$accentColor}`)};
      text-transform: uppercase;
      font-weight: 600;
      .erh-davis-bacon {
        font-weight: 800;
        margin-right: 3px;
        text-transform: uppercase;
      }
    }
  }

  .erh-tooltip-address-section {
    display: flex;
    flex-direction: column;
    font-size: 11px;
    padding: 4px 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .erh-address {
      margin-bottom: 4px;
    }
  }
`
