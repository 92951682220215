import EditorCollapseIcon from '@atlaskit/icon/glyph/editor/collapse'
import EditorExpandIcon from '@atlaskit/icon/glyph/editor/expand'

import { IconButton } from '@atlaskit/button/new'
import { useAtom } from 'jotai'

import { token } from '@atlaskit/tokens'

import { isShowLookupsAtom } from '../reconPayrollAtoms'

const ToggleShowLookups = () => {
  const [isShowLookups, setIsShowLookups] = useAtom(isShowLookupsAtom)

  return (
    <div style={{ transform: 'rotate(90deg)' }}>
      <IconButton
        icon={iconProps =>
          isShowLookups ? (
            <EditorCollapseIcon {...iconProps} label='Collapse' primaryColor={token('color.icon.subtlest')} />
          ) : (
            <EditorExpandIcon {...iconProps} label='Expand' primaryColor={token('color.icon.subtlest')} />
          )
        }
        label='Edit'
        onClick={() => setIsShowLookups(prev => !prev)}
      />
    </div>
  )
}

export default ToggleShowLookups
