import Select, { CSSObjectWithLabel, Theme } from 'react-select'
import styled from 'styled-components'

import { GetStaffListQuery } from '@/gql/graphql'
import useStaffListQuery from '@/utils/queryHooks/useStaffListQuery'

import useEstimateAttribute from '../hooks/useEstimateAttribute'
import useSelectColors from '../hooks/useSelectColors'
import useSubmitEstimateValues from '../hooks/useSubmitEstimateValues'

type SalesPerson = GetStaffListQuery['users'][0]
type SalesPersonOption = {
  label: string
  value: string
}

interface SalesPersonDropdownProps {
  estimateId: string
}

const UNASSIGNED_SALESPERSON_OPTION = { label: 'No Salesperson Assigned', value: null }

const SalesPersonDropdown = ({ estimateId }: SalesPersonDropdownProps) => {
  const salespersonId = useEstimateAttribute('salespersonId')

  const { staffList } = useStaffListQuery()

  const options = staffList
    .map((salesPerson: SalesPerson) => ({ label: salesPerson.fullName, value: salesPerson.id }))
    .concat(UNASSIGNED_SALESPERSON_OPTION)
  const selectedSalesperson = options.find((option: SalesPersonOption) => option.value === salespersonId)
  const colors = useSelectColors()
  const submitEstimateValues = useSubmitEstimateValues()
  const handleChange = (option: SalesPersonOption | null) => {
    console.log('Selected salesperson:', option)
    // @ts-expect-error - TODO: not a concern
    if (option && option.value) submitEstimateValues({ estimateId, values: [{ attribute: 'salespersonId', value: option.value }] })
  }

  return (
    <StyledWrapper>
      <Select
        options={options}
        value={!selectedSalesperson ? UNASSIGNED_SALESPERSON_OPTION : selectedSalesperson}
        placeholder='Select Salesperson'
        inputId='salesperson-selector'
        classNamePrefix='salesperson-selector'
        onChange={handleChange}
        theme={(theme: Theme) => ({
          ...theme,
          colors,
        })}
        styles={{
          container: (base: CSSObjectWithLabel) =>
            ({
              ...base,
              // transform: 'scale(0.86)',
              // transformOrigin: 'top right',
              height: '32px',
            }) as CSSObjectWithLabel,
          control: (base: CSSObjectWithLabel) => ({
            ...base,
            maxHeight: '32px',
            minHeight: '32px',
            height: '32px',
          }),
          dropdownIndicator: (base: CSSObjectWithLabel) =>
            ({
              ...base,
              padding: '0px 8px',
            }) as CSSObjectWithLabel,
          // indicatorSeparator: (base: CSSObjectWithLabel) =>
          //   ({
          //     ...base,
          //     margin: '0px',
          //   }) as CSSObjectWithLabel,
        }}
      />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  /* transform: scale(0.86);
  transform-origin: top right; */
`
export default SalesPersonDropdown
