import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right'

import { Temporal } from '@js-temporal/polyfill'
import { useQuery } from '@tanstack/react-query'
import { useNavigate, useSearchParams } from 'react-router'
import styled from 'styled-components'

import Link from '@atlaskit/link'
import { token } from '@atlaskit/tokens'

import GET_PAYROLL_DAY_VEHICLES, { GET_PAYROLL_DAY_VEHICLES_QUERY_KEY } from '@/utils/api/getPayrollDayVehicles'
import useGraphQLClient from '@/utils/useAuthRequest'
import { CardColorOptions, COLOR_OPTIONS, TRIP_COLORS } from '@/utils/utilities'

import { GetPayrollDayVehiclesQuery } from '@/gql/graphql'

import { DotIndicatorColor } from '@/components/MiniCalendarNav/calendarTypes'
import IndicatorDot from '@/components/MiniCalendarNav/IndicatorDot'
import PickupTruckSvg from '@/components/PickupTruckSvg'
import TruckIcon from '@/components/TruckIcon'

import useIsModifierPressed from '../../../utils/useIsModifierPressed'
import useReconSearchParams from '../useReconSearchParams'

type VehicleByDate = NonNullable<GetPayrollDayVehiclesQuery['vehiclesByDate']>[number]
type VehicleDate = NonNullable<VehicleByDate['vehicleDate']>
type VehicleDispatchAssignment = VehicleDate['vehicleDispatchAssignments'][number]

const DailyReconNav = () => {
  const { paramValues } = useReconSearchParams()
  const [, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const dateId = paramValues.get('dateId')

  const vehicleDispatchId = paramValues.get('vehicleDispatchId')
  const vehicleDateId = paramValues.get('vehicleDateId')
  const graphQLClient = useGraphQLClient()

  const date = dateId ?? Temporal.Now.plainDateISO().toString()

  const { data, isLoading } = useQuery({
    queryKey: [GET_PAYROLL_DAY_VEHICLES_QUERY_KEY, date],
    queryFn: async () => await graphQLClient.request(GET_PAYROLL_DAY_VEHICLES, { date }),
  })
  const vehicles = data?.vehiclesByDate ?? ([] as VehicleByDate[])

  const vehiclesWithAssignments: VehicleByDate[] = vehicles.filter(
    vehicle => (vehicle.vehicleDate?.sortedVehicleDispatchIds ?? '').length > 0
  )
  const vehicleDispatchesById = vehiclesWithAssignments.reduce(
    (acc, { vehicleDate }) => {
      const assignments = vehicleDate?.vehicleDispatchAssignments ?? []
      assignments.forEach(vehicleDispatch => {
        acc[vehicleDispatch.id] = vehicleDispatch
      })
      return acc
    },
    {} as Record<string, VehicleDispatchAssignment>
  )

  const handleJobCellClick = (e: React.MouseEvent, href: string) => {
    e.preventDefault()
    if (e.ctrlKey || e.metaKey) {
      window.open(href, '_blank')
    } else {
      navigate(href, { preventScrollReset: true })
    }
  }

  const handleVehicleClick = (vdId: string) => {
    setSearchParams(
      prev => {
        prev.set('vehicleDateId', vdId)
        return prev
      },
      { replace: true, preventScrollReset: true }
    )
  }

  const isOptionKeyPressed = useIsModifierPressed('alt')

  return (
    <DailyReconNavContainer>
      {isLoading ? (
        <div style={{ padding: '16px', color: token('color.text.subtlest') }}>Loading...</div>
      ) : vehiclesWithAssignments.length === 0 ? (
        <div style={{ padding: '16px', color: token('color.text.disabled') }}>No vehicles dispatched on this date</div>
      ) : null}
      {vehiclesWithAssignments.map(vehicle => {
        const rootParams = new URLSearchParams(paramValues)
        rootParams.delete('vehicleDispatchId')
        rootParams.delete('vehicleDateId')
        rootParams.set('vehicleDateId', vehicle.vehicleDate?.id ?? '')
        const vehicleDateLink = '/recon/daily?' + rootParams.toString()
        const isVehicleSelected = vehicle.vehicleDate?.id === vehicleDateId
        // @ts-expect-error - vehicle.color is not always a CardColorOptions
        const color = (COLOR_OPTIONS.includes(vehicle?.color ?? '') ? vehicle.color : 'gray') as CardColorOptions
        return (
          <div key={vehicle.id} className='vehicle-nav-wrapper'>
            <VehicleContainerHeaderCard
              $color={color}
              $isSelected={isVehicleSelected}
              onClick={() => handleVehicleClick(vehicle.vehicleDate?.id ?? '')}
            >
              <div className='vehicle-header-section-left'>
                <div className='vehicle-icon'>
                  {vehicle.name.includes('150') ? (
                    <PickupTruckSvg color={token('color.icon.subtle')} size={32} />
                  ) : (
                    <TruckIcon color={token('color.icon.subtle')} size={32} />
                  )}
                </div>

                <div className='vehicle-card-info'>
                  <div className='vehicle-card-title'>{vehicle.name}</div>
                </div>
              </div>

              <div className='vehicle-header-section-right'>
                <ChevronRightIcon label='Select Vehicle' />
              </div>
            </VehicleContainerHeaderCard>
            <div className='vehicle-nav-assignments'>
              {vehicle.vehicleDate?.sortedVehicleDispatchIds?.map((dispatchId, currentIndex) => {
                const vehicleDispatch = vehicleDispatchesById[dispatchId]
                const workOrder = vehicleDispatch?.dispatch?.workOrder ?? null
                const tripName = workOrder?.tripName ?? 'No Trip Name'
                const address = workOrder?.projectSiteAddress ?? 'No Job Site Address'
                const isDavisBacon = workOrder?.isDavisBacon ?? false

                const accentColor: CardColorOptions = TRIP_COLORS?.[tripName] ?? 'orange'
                const vehicleDispatchLink = vehicleDateLink + '&vehicleDispatchId=' + vehicleDispatch.id
                const approvedCount = vehicleDispatch.installerAssignments.filter(ia => ia.isApproved).length
                const completedCount = vehicleDispatch.installerAssignments.filter(ia => ia.isCompleted && !ia.isApproved).length
                const incompleteCount = vehicleDispatch.installerAssignments.filter(ia => !ia.isApproved && !ia.isCompleted).length
                const customerName = workOrder?.customerName ?? 'No Customer Name'
                const workOrderName = '#' + (workOrder?.id ?? '#id') + '-' + (workOrder?.name ?? 'No Work Order Name')
                const topRowText = isOptionKeyPressed ? workOrderName : customerName

                return (
                  <JobCell
                    key={vehicleDispatch.id}
                    $isSelected={vehicleDispatchId === vehicleDispatch.id}
                    $accentColor={accentColor}
                    onClick={e => handleJobCellClick(e, vehicleDispatchLink)}
                    role='link'
                    tabIndex={0}
                  >
                    <div className='vehicle-dispatch-card-index'>{currentIndex + 1}</div>

                    <div className='job-card-info'>
                      <div className='customer-name'>{topRowText}</div>

                      <div className='job-site-address'>
                        <Link
                          appearance='subtle'
                          href={`/jobs/${workOrder?.jobId}/estimates/${workOrder?.estimateId}`}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                            {isDavisBacon ? <span className='erh-davis-bacon-address'>DB&nbsp;</span> : null}
                            <span className='erh-address-link'>{address}</span>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className='vehicle-dispatch-card-status'>
                      {incompleteCount === 0 ? null : <IndicatorDotRow color='red' count={incompleteCount} />}
                      {completedCount === 0 ? null : <IndicatorDotRow color='orange' count={completedCount} />}
                      {approvedCount === 0 ? null : <IndicatorDotRow color='green' count={approvedCount} />}
                    </div>
                  </JobCell>
                )
              })}
            </div>
          </div>
        )
      })}
    </DailyReconNavContainer>
  )
}

export default DailyReconNav
const JOB_CELL_WIDTH = 240

interface VehicleContainerHeaderCardProps {
  $color: CardColorOptions
  $isSelected: boolean
}

const IndicatorDotRow = ({ color, count }: { color: DotIndicatorColor; count: number }) => {
  return (
    <StyledIndicatorDotRow $color={color}>
      <IndicatorDot color={color} />
      {count}
    </StyledIndicatorDotRow>
  )
}

const StyledIndicatorDotRow = styled.div<{ $color: DotIndicatorColor }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 12px;
  flex: 1;
  padding: 0 8px 0 4px;
  color: ${({ $color }) => token(`color.text.accent.${$color}`)};
`
const VehicleContainerHeaderCard = styled.div<VehicleContainerHeaderCardProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 4px;
  padding-right: 12px;
  cursor: pointer;

  background: ${({ $color, $isSelected }) =>
    !$color
      ? token('elevation.surface')
      : $isSelected
        ? token(`color.background.accent.${$color}.subtler`)
        : token(`color.background.accent.${$color}.subtlest`)};
  transition: background 0.12s ease-in-out;
  &:hover {
    background: ${({ $color }) => (!$color ? token('elevation.surface.hovered') : token(`color.background.accent.${$color}.subtler`))};
  }
  &:active {
    background: ${({ $color }) => (!$color ? token('elevation.surface.pressed') : token(`color.background.accent.${$color}.subtle`))};
  }
  border-bottom: 1px solid ${({ $color }) => (!$color ? token('color.border') : token(`color.border.accent.${$color}`))};

  .vehicle-header-section-left {
    display: flex;
    align-items: center;
  }
  .vehicle-header-section-right {
    display: flex;
    align-items: center;
    gap: 12px;
    opacity: 0.5;
    transition: opacity 500ms;
    &:hover {
      opacity: 1;
    }
  }

  .vehicle-icon {
    padding: 4px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    svg {
      stroke: ${({ $color }) => (!$color ? token('color.icon.subtle') : token(`color.text.accent.${$color}`))};
      opacity: 0.8;
    }
  }

  .vehicle-card-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 4px 12px 4px 0;
    z-index: 1;

    .vehicle-card-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      color: ${({ $color }) => (!$color ? token('elevation.surface') : token(`color.text.accent.${$color}`))};
      opacity: 0.8;
    }
  }
`

const JobCell = styled.div<{ $accentColor: CardColorOptions; $isSelected: boolean }>`
  cursor: pointer;
  color: ${token('color.text')};
  height: 100%;
  min-height: 100%;
  text-decoration: none;
  transition: background 0.1s ease-in-out;
  &:hover {
    background: ${({ $accentColor, $isSelected }) =>
      token(
        !$accentColor
          ? `elevation.surface.hovered`
          : `color.background.accent.${$accentColor}.${$isSelected ? 'subtle' : 'subtler'}.hovered`
      )};
  }

  &:focus {
    outline: 2px solid ${token('color.border.focused')};
    outline-offset: 2px;
  }

  .vehicle-dispatch-card-status {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  padding: 0;
  width: ${JOB_CELL_WIDTH}px;
  min-width: ${JOB_CELL_WIDTH}px;

  border-color: ${({ $accentColor }) => token(!$accentColor ? `color.border` : `color.border.accent.${$accentColor}`)};
  border-bottom-width: 5px;
  border-left: 2px solid ${({ $accentColor }) => token(!$accentColor ? `color.border` : `color.border.accent.${$accentColor}`)};
  border-bottom: 1.5px solid ${({ $accentColor }) => token(!$accentColor ? `color.border` : `color.border.accent.${$accentColor}`)};
  background: ${({ $accentColor, $isSelected }) =>
    token(!$accentColor ? `elevation.surface.raised` : `color.background.accent.${$accentColor}.${$isSelected ? 'subtle' : 'subtler'}`)};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex: 0 0 none;

  .vehicle-dispatch-card-index {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 24px;
    font-size: 18px;
    font-weight: 800;

    flex: 0 0 auto;
    color: ${({ $accentColor }) =>
      // @ts-expect-error known issue with token function
      !$accentColor ? token(`color.text.sublest`) : token(`color.text.accent.${$accentColor}`)};
  }

  .job-card-info {
    flex: 1 1 auto;
    padding: 4px 8px 2px 0px;
    overflow: hidden;

    .customer-name {
      padding-bottom: 4px;
      border-bottom: 1px solid ${token('color.border')};
      padding-left: 1px;
      font-weight: bold;
      font-size: 13px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .job-site-address {
      display: flex;
      flex-direction: column;
      font-size: 11px;
      padding: 4px 2px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      a > span:not(.erh-address-link):not(.erh-davis-bacon-address) {
        display: none;
      }

      .erh-davis-bacon-address {
        display: inline-block;
        font-weight: 800;
        letter-spacing: -0.08em;
        margin-right: 1px;
        color: ${({ $accentColor }) =>
          // @ts-expect-error known issue with token function
          !$accentColor ? token(`color.text.sublest`) : token(`color.text.accent.${$accentColor}`)};
      }

      .erh-address-link {
        flex-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      a,
      span:not(.erh-davis-bacon-address) {
        color: ${token('color.text')};
        display: inline-block;
      }
    }
  }
`

const DailyReconNavContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  position: relative;
  flex: 1 1 auto;
  margin: 0 -8px;

  .vehicle-nav-wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.2rem;
  }
  .vehicle-nav-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 16px;
    border-top: 1px solid ${token('color.border')};
    border-bottom: 2px solid ${token('color.border')};
  }
  .vehicle-nav-assignments {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.2rem;
    padding: 0 16px;
  }
`
