import { Temporal } from '@js-temporal/polyfill'
import { QueryStatus, useQuery } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'

import useGraphQLClient from '@/utils/useAuthRequest'

import { toast } from 'sonner'
import { installerWeeklyLookupsByIdAtom } from '../reconPayrollAtoms'
import {
  GET_ALL_INSTALLER_PAYROLL,
  GET_ALL_INSTALLER_PAYROLL_QUERY_KEY,
  InstallerDatesLookupKey,
  InstallerWeeklyLookups,
  InstallerWeeklyLookupsById,
  InstallerWeeklyPayrollWithoutDates,
  InstallerWithWeeklyPayroll,
} from '../reconPayrollTypes'

const useReconPayrollQuery = (weekOfDate: Temporal.PlainDate, staffQueryStatus: QueryStatus) => {
  const graphqlClient = useGraphQLClient()
  const setPayrollByInstallerId = useSetAtom(installerWeeklyLookupsByIdAtom)

  const weekNumber = weekOfDate.weekOfYear
  const weekYear = weekOfDate.yearOfWeek
  const { isLoading, error } = useQuery({
    enabled: staffQueryStatus === 'success',
    queryKey: [GET_ALL_INSTALLER_PAYROLL_QUERY_KEY, { weekNumber, weekYear }],
    queryFn: async () => {
      const response = await graphqlClient.request(GET_ALL_INSTALLER_PAYROLL, { weekNumber, weekYear })
      if (response.allInstallerPayroll?.length > 0) {
        const installerWeeklyLookupsById: InstallerWeeklyLookupsById = {}
        response.allInstallerPayroll.forEach(row => {
          installerWeeklyLookupsById[row.installerId] = parseInstallerWithWeeklyPayroll(row)
        })
        setPayrollByInstallerId(installerWeeklyLookupsById)
      } else {
        toast.warning('No data found for this week')
      }
      console.warn('response', response)
      return response
    },
  })

  return { isLoading, error }
}

export default useReconPayrollQuery

export function parseInstallerWithWeeklyPayroll(row: InstallerWithWeeklyPayroll): InstallerWeeklyLookups {
  const { installerWeeklyPayroll: installerWeeklyPayrollWithDates, ...rest } = row
  const lookups: InstallerWeeklyLookups = {
    ...rest,
    '1': null,
    '2': null,
    '3': null,
    '4': null,
    '5': null,
    '6': null,
    '7': null,
  }
  let installerWeeklyPayroll: InstallerWeeklyPayrollWithoutDates | undefined
  if (installerWeeklyPayrollWithDates) {
    if (installerWeeklyPayrollWithDates.installerDates) {
      const { installerDates, ...rest } = installerWeeklyPayrollWithDates
      if (rest) installerWeeklyPayroll = rest
      if (installerDates) {
        installerDates.forEach(installerDate => {
          const dayOfWeek = Temporal.PlainDate.from(installerDate.date).dayOfWeek
          const key: InstallerDatesLookupKey = dayOfWeek.toString() as InstallerDatesLookupKey
          lookups[key] = installerDate
        })
      }
    } else installerWeeklyPayroll = installerWeeklyPayrollWithDates
  }
  lookups.installerWeeklyPayroll = installerWeeklyPayroll

  return lookups
}
