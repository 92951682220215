import { useSetAtom } from 'jotai'
import styled from 'styled-components'

import InlineEdit from '@atlaskit/inline-edit'
import Textfield from '@atlaskit/textfield'

import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'

import NumberCell from './NumberCell'

import { workItemEditsQueueAtom, workItemsOptimisticAttributesAtom } from '../editorAtoms'
import { WorkItemEditableNumberAttribute, WorkItemValueToSubmit } from '../editorTypes'

import useWorkItemAttribute from '../hooks/useWorkItemAttribute'

interface EditNumberCellProps {
  attribute: WorkItemEditableNumberAttribute
  workItemId: string | null
  prefix?: string
  suffix?: string
  decimalPlaces?: number
}

const EditNumberCell = ({ attribute, workItemId, prefix = '', suffix = '', decimalPlaces = 2 }: EditNumberCellProps) => {
  const setOptimisticAttributes = useSetAtom(workItemsOptimisticAttributesAtom)
  const setEstimateEditsQueue = useSetAtom(workItemEditsQueueAtom)

  const value = useWorkItemAttribute(attribute, workItemId ?? 'null')

  const initialValue = value ?? 'initial'

  const onConfirm = (newValue: string) => {
    const numericValue = parseFloat(newValue)
    if (isNaN(numericValue)) {
      console.warn('isNaN(numericValue): ', numericValue)
      return
    }

    const formattedNewValue = numericValue.toFixed(decimalPlaces)
    const formattedExistingValue = value !== null ? parseFloat(`${value}`).toFixed(decimalPlaces) : null

    if (formattedNewValue === formattedExistingValue) {
      console.warn('formattedNewValue === formattedExistingValue: ', formattedNewValue, formattedExistingValue)
      return
    }

    setOptimisticAttributes(prev => {
      const id = workItemId ?? 'null'
      const existingWorkItem = prev?.[id] ?? {}
      console.log('setOptimisticAttributes: ', { attribute, formattedNewValue, existingWorkItem })
      return { ...prev, [id]: { ...existingWorkItem, [attribute]: formattedNewValue } }
    })
    if (workItemId !== 'NEW')
      setEstimateEditsQueue(prev => {
        if (!workItemId) return prev
        const valueToSubmit = formattedNewValue
        const newEdit: WorkItemValueToSubmit = { workItemId, attribute, value: valueToSubmit }
        return [...prev, newEdit]
      })
  }

  return (
    <StyledInlineEditNumberCell>
      <InlineEdit
        defaultValue={initialValue}
        editButtonLabel={`${initialValue}`}
        editView={fieldProps => <Textfield {...fieldProps} autoFocus />}
        readView={() => <NumberCell value={value} prefix={prefix} suffix={suffix} decimalPlaces={decimalPlaces} />}
        onConfirm={onConfirm}
        hideActionButtons
      />
    </StyledInlineEditNumberCell>
  )
}

export default EditNumberCell

const StyledInlineEditNumberCell = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  form[role='presentation'] {
    border-radius: 0px;
    height: 100%;
    width: 100%;

    > div {
      height: 100%;
      width: 100%;
      margin-top: 0px;
      > div {
        width: 100%;
        height: 100%;
        > div[role='presentation'] {
          width: 100%;
          height: 100%;
          border-radius: 0px;
          > div {
            // this is NumberCell
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center !important;
            justify-content: flex-end;
            flex: 1;
          }
        }
      }
    }
  }
  input {
    flex: 1;
    border: none;
    text-align: right;
    font-family: ${MONOSPACE_FONT_FAMILY};
    width: 100%;
    height: 100%;
    padding: 0px;
  }
`
