// ui/src/components/MiniCalendarNav/MonthYearPicker.tsx

import { AnimatePresence, motion } from 'framer-motion'
import { useAtom } from 'jotai'
import React, { useState } from 'react'
import { useSearchParams } from 'react-router'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import { isMonthYearPickerOpenAtom } from './atoms'
import useDisplayMonthYear from './useDisplayMonthYear'

const BACKDROP_MOTION = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 },
}

const POPOVER_MOTION = {
  hidden: { opacity: 0, y: 10 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 5 },
}

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const MonthYearPicker: React.FC = () => {
  const { month, year } = useDisplayMonthYear(false)
  const [, setSearchParams] = useSearchParams()

  const [tempMonth, setTempMonth] = useState<number>(month)
  const [tempYear, setTempYear] = useState<number>(year)

  const [isOpen, setIsOpen] = useAtom(isMonthYearPickerOpenAtom)

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <Backdrop variants={BACKDROP_MOTION} initial='hidden' animate='visible' exit='exit' onClick={onClose} />
          <PopoverContainer variants={POPOVER_MOTION} initial='hidden' animate='visible' exit='exit'>
            <CloseButton onClick={onClose}>×</CloseButton>
            <h4>Jump to month/year</h4>
            <SelectRow>
              <select value={tempMonth} onChange={e => setTempMonth(Number(e.target.value))}>
                {months.map((m, idx) => (
                  <option key={m} value={idx + 1}>
                    {m}
                  </option>
                ))}
              </select>
              <input type='number' value={tempYear} onChange={e => setTempYear(Number(e.target.value))} style={{ width: '80px' }} />
            </SelectRow>
            <ConfirmButton onClick={handleConfirm}>Go</ConfirmButton>
          </PopoverContainer>
        </>
      )}
    </AnimatePresence>
  )
  function handleConfirm() {
    const cId = `${tempYear}-${tempMonth}`
    setSearchParams(prev => {
      prev.set('cId', cId)
      return prev
    })
    setIsOpen(false)
  }

  function onClose() {
    setIsOpen(false)
  }
}
export default MonthYearPicker

// Styled components
const Backdrop = styled(motion.div)`
  position: fixed;
  inset: 0;
  background: ${token('color.blanket', 'rgba(0,0,0,0.3)')};
  z-index: 999;
`

const PopoverContainer = styled(motion.div)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${token('elevation.surface', '#fff')};
  padding: 16px;
  border-radius: 8px;
  z-index: 1000;
  width: 260px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);

  h4 {
    margin: 0 0 12px 0;
  }
`

const SelectRow = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 12px;

  select,
  input {
    flex: 1;
    border-radius: 4px;
    border: 1px solid ${token('color.border', '#ccc')};
    padding: 4px;
  }
`

const ConfirmButton = styled.button`
  width: 100%;
  background: ${token('color.background.brand.bold', '#0052CC')};
  color: ${token('color.text.inverse', '#fff')};
  border: none;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  &:hover {
    background: ${token('color.background.brand.bold.hovered', '#0065FF')};
  }
`

const CloseButton = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 20px;
  line-height: 20px;
  cursor: pointer;
  color: ${token('color.text.subtlest', '#888')};
  &:hover {
    color: ${token('color.text', '#222')};
  }
`
