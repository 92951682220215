import { flexRender, Header } from '@tanstack/react-table'
import { Link, useNavigate } from 'react-router'

import Tooltip from '@atlaskit/tooltip'

import useReconSearchParams from '../../useReconSearchParams'
import { ReconTableRowDef } from '../reconPayrollTypes'

const TableHeaderCell = ({ header }: { header: Header<ReconTableRowDef, unknown> }) => {
  const navigate = useNavigate()
  const { paramValues } = useReconSearchParams()
  const metaStyle = header.column.columnDef.meta?.style ?? {}
  const headerDef = header.column.columnDef
  const isAnyGroupHeader = header.colSpan > 1
  const isRowHeader = headerDef.header === 'Section' || headerDef.header === 'Field'
  const colSpan = header.colSpan
  const isPinned = header.column.getIsPinned()
  const width = header.colSpan > 2 ? header.colSpan * 120 : undefined
  const style = { ...metaStyle, width }
  const className = `${isAnyGroupHeader ? '' : 'installer-column'} ${isPinned ? 'pinned-' + isPinned : ''}`
  const shouldTooltip = !isRowHeader && !isAnyGroupHeader
  const content = flexRender(headerDef.header, header.getContext())
  const installerId = headerDef.id
  let newPath: string | null = null
  if (installerId) {
    const newParams = new URLSearchParams(paramValues)
    newParams.delete('installerId')
    newParams.set('installerId', installerId)
    newPath = `/recon/payroll/installer-paystubs?${newParams.toString()}`
  }
  return (
    <th key={header.id} colSpan={colSpan} style={style} className={className}>
      {!shouldTooltip ? (
        content
      ) : (
        <Tooltip content={content} position='top-start'>
          <Link to={newPath ?? '/'} onClick={handleClick}>
            <div className='header-tooltip-content-wrapper'>{content}</div>
          </Link>
        </Tooltip>
      )}
    </th>
  )

  function handleClick(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault()
    if (newPath) {
      navigate(newPath, { replace: true, preventScrollReset: true })
    }
  }
}

export default TableHeaderCell
