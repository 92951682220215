import { useQuery } from '@tanstack/react-query'
import { useMemo, useState } from 'react'

import Textfield from '@atlaskit/textfield'

import { GetCustomersQuery } from '@/gql/graphql'

import useGraphQLClient from '@/utils/useAuthRequest'

import AppPage from '@/components/AppPage'
import SpectrumTable, { ColumnDefsType } from '@/components/SpectrumTable'

import GET_CUSTOMERS_QUERY, { GET_CUSTOMERS_QUERY_KEY } from '@/utils/api/getCustomers'
import CreateCustomerModal from '../CreateCustomerModal/CreateCustomerModal'

const actions = <CreateCustomerModal />

interface CustomerPageBottomBarProps {
  searchText: string
  setSearchText: (searchText: string) => void
}

const CustomerPageBottomBar = ({ searchText, setSearchText }: CustomerPageBottomBarProps) => {
  return (
    <div style={{ minWidth: 200, maxWidth: 300 }}>
      <Textfield
        isCompact
        value={searchText}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
        placeholder='Search'
      />
    </div>
  )
}

const CustomersList = () => {
  const graphQLClient = useGraphQLClient()
  const { data, status } = useQuery({
    queryKey: [GET_CUSTOMERS_QUERY_KEY],
    queryFn: () => graphQLClient.request(GET_CUSTOMERS_QUERY),
  })

  const [searchText, setSearchText] = useState('')
  const customers = useMemo(
    () => (data?.customers ?? []).filter(x => x.name.toLowerCase().includes(searchText.toLowerCase())),
    [data, searchText]
  )

  return (
    <AppPage
      header='Customers'
      actions={actions}
      disableScroll={true}
      bottomBar={<CustomerPageBottomBar searchText={searchText} setSearchText={setSearchText} />}
    >
      <title>Customers - Thermal Shop Admin</title>
      <SpectrumTable status={status} columns={spectrumColumns} rows={customers} linkSelector={linkSelector} absolute />
    </AppPage>
  )
}

export default CustomersList

type GetCustomersSingleCustomerType = GetCustomersQuery['customers'][0]

const linkSelector = (row: GetCustomersSingleCustomerType) => `/customers/${row.id}`

const spectrumColumns: ColumnDefsType<GetCustomersSingleCustomerType>[] = [
  { title: 'Customer Name', key: 'name' },
  { title: 'Created By', key: 'createdByFullName', valueGetter: ({ createdBy }) => createdBy?.fullName ?? null },
  {
    title: 'Created At',
    key: 'createdAt',
    align: 'end',
    cell: ({ createdAt }) => (!createdAt ? '' : new Date(createdAt).toLocaleDateString()),
  },
  // { title: 'Date Modified', id: 'date' },
]
