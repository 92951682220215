// ui/src/components/MiniCalendarNav/MiniCalendarNav.tsx

import React, { useRef } from 'react'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import { DAY_CELL_HEIGHT, DAY_WIDTH, useDateChangeTrigger, useInitialIndicators, useInitialProps, WEEK_NUM_WIDTH } from './atoms'
import { MiniCalendarNavProps, SelectionMode } from './calendarTypes'
import HeaderRow from './HeaderRow'
import MonthYearPicker from './MonthYearPicker'
import NavRow from './NavRow'
import useCalendarListeners from './useCalendarListeners'
import WeeksView from './WeeksView'

const MiniCalendarNav: React.FC<MiniCalendarNavProps> = ({
  onSelect,
  initialDisplayMonth,
  initialDisplayYear,
  initialSelectedDate,
  weekIndicatorsByWeekId,
  dateIndicatorsByDateId,
  areIndicatorsLoading,
  selectionMode = 'date' as SelectionMode,
}) => {
  useInitialProps({ initialDisplayMonth, initialDisplayYear, initialSelectedDate })
  useInitialIndicators({ weekIndicatorsByWeekId, dateIndicatorsByDateId, areIndicatorsLoading })
  useDateChangeTrigger(onSelect)

  const containerRef = useRef<HTMLDivElement>(null)
  useCalendarListeners(containerRef)

  return (
    <CalendarContainer ref={containerRef}>
      {/* Navigation Row */}
      <table>
        <thead>
          <NavRow />
          <HeaderRow />
        </thead>

        <tbody>
          <WeeksView selectionMode={selectionMode} />
        </tbody>
      </table>

      {/* Month/Year Popover */}
      <MonthYearPicker />
    </CalendarContainer>
  )
}
export default MiniCalendarNav

const CalendarContainer = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  * {
    box-sizing: border-box;
  }

  table {
    width: ${WEEK_NUM_WIDTH + 7 * DAY_WIDTH}px;
    min-width: ${WEEK_NUM_WIDTH + 7 * DAY_WIDTH}px;
    max-width: ${WEEK_NUM_WIDTH + 7 * DAY_WIDTH}px;
    border-collapse: collapse;
    border-spacing: 0;
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid ${token('color.border')};

    td,
    th {
      padding: 0;
      vertical-align: top;

      &:first-child {
        padding-left: 8px;
        width: ${WEEK_NUM_WIDTH}px;
        min-width: ${WEEK_NUM_WIDTH}px;
        max-width: ${WEEK_NUM_WIDTH}px;
        text-align: left;
      }
    }

    > thead {
      border: 0;
      > tr {
        text-align: center;
        font-weight: 600;
        font-size: 12px;
        color: ${token('color.text.subtlest')};
        margin-bottom: 4px;

        > th {
          text-align: center;
          vertical-align: middle;
          padding-top: 2px;
          padding-bottom: 2px;
          &:first-child {
            opacity: 0.4;
          }
        }
      }
    }

    > tbody {
      border: 0;
      > tr {
        > td {
          min-height: ${DAY_CELL_HEIGHT}px;
          height: ${DAY_CELL_HEIGHT}px;
          max-height: ${DAY_CELL_HEIGHT}px;

          &:not(:first-child) {
            width: ${DAY_WIDTH}px;
            min-width: ${DAY_WIDTH}px;
            max-width: ${DAY_WIDTH}px;
          }

          &:first-child {
            border-radius: 3px 0 0 3px;
          }
          &:last-child {
            border-radius: 0 3px 3px 0;
          }
        }
      }
    }
  }
`
