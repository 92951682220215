import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router'
import styled from 'styled-components'

import Link from '@atlaskit/link'
import { token } from '@atlaskit/tokens'

import useIsModifierPressed from '@/utils/useIsModifierPressed'
import { CardColorOptions, TRIP_COLORS } from '@/utils/utilities'

import { GetVehicleDateQuery, SubmitVehicleDateStatsMutationVariables } from '@/gql/graphql'

import HoursMinutesInput from '@/components/HoursMinutesInput'
import HrMinCell from '@/components/HrMinCell'
import NumberCell from '@/components/NumberCell'
import TableCellNumberTextField from '@/components/TableCellNumberTextField'

import { VEHICLE_RECON_ROW_WIDTHS as ROW_W } from './reconDateConstants'

type VehicleDate = GetVehicleDateQuery['vehicleDate']
type VehicleDispatch = NonNullable<VehicleDate['vehicleDispatchAssignments'][number]>
interface VehicleDispatchRowProps {
  vehicleDispatch: VehicleDispatch
  currentIndex: number
  mutate: (variables: SubmitVehicleDateStatsMutationVariables) => void
  isDisabled?: boolean
}

const VehicleDispatchRow = ({ currentIndex, vehicleDispatch, mutate, isDisabled }: VehicleDispatchRowProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const vehicleDispatchId = vehicleDispatch?.id
  const isRowSelected = useMemo(() => searchParams.get('vehicleDispatchId') === vehicleDispatchId, [searchParams, vehicleDispatchId])
  const workOrder = vehicleDispatch?.dispatch?.workOrder ?? null
  const tripName = workOrder?.tripName ?? 'No Trip Name'
  const accentColor: CardColorOptions = TRIP_COLORS?.[tripName] ?? 'orange'
  const address = workOrder?.projectSiteAddress ?? 'No Job Site Address'
  const isDavisBacon = workOrder?.isDavisBacon ?? false

  const [milesToArrive, setMilesToArrive] = useState(vehicleDispatch?.milesToArrive?.toString())
  const [minutesToArrive, setMinutesToArrive] = useState(vehicleDispatch?.minutesToArrive?.toString())
  const [minutesProximity, setProximityMinutes] = useState(vehicleDispatch?.minutesProximity?.toString())
  const [minutesOnSite, setMinutesOnSite] = useState(vehicleDispatch?.minutesOnSite?.toString())

  useEffect(() => {
    setMilesToArrive(vehicleDispatch?.milesToArrive?.toString())
    setMinutesToArrive(vehicleDispatch?.minutesToArrive?.toString())
    setProximityMinutes(vehicleDispatch?.minutesProximity?.toString())
    setMinutesOnSite(vehicleDispatch?.minutesOnSite?.toString())
  }, [vehicleDispatch])

  const calculatedMinutesDriveProximityAdjusted = vehicleDispatch?.calculatedMinutesDriveProximityAdjusted
  const calculatedMinutesDriveBuffer = vehicleDispatch?.calculatedMinutesDriveBuffer
  const milesProximityAdjusted = vehicleDispatch?.milesProximityAdjusted

  const isOptionKeyPressed = useIsModifierPressed('alt')
  const customerName = workOrder?.customerName ?? 'No Customer Name'
  const workOrderName = '#' + (workOrder?.id ?? '#id') + '-' + (workOrder?.name ?? 'No Work Order Name')
  const topRowText = isOptionKeyPressed ? workOrderName : customerName

  return (
    <JobRow>
      <JobCell $accentColor={accentColor} $isRowSelected={isRowSelected}>
        <div className='vehicle-dispatch-card-index'>{currentIndex + 1}</div>

        <div className='job-card-info' onClick={handleJobCardClick}>
          <div className='customer-name'>{topRowText}</div>

          <div className='job-site-address'>
            <Link
              appearance='subtle'
              href={`/jobs/${workOrder?.jobId}/estimates/${workOrder?.estimateId}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                {isDavisBacon ? <span className='erh-davis-bacon-address'>DB&nbsp;</span> : null}
                <span className='erh-address-link'>{address}</span>
              </div>
            </Link>
          </div>
        </div>
      </JobCell>

      <td style={{ width: ROW_W[1] }}>
        <HoursMinutesInput
          usedWithReadOnly
          minutes={minutesProximity ? Number(minutesProximity) : null}
          onChangeCommit={handleProximityMinutesBlur}
          isDisabled={isDisabled}
        />
      </td>
      <td style={{ width: ROW_W[2], background: token('color.background.disabled') }}>
        <NumberCell value={vehicleDispatch?.calculatedProximityPercent} suffix='%' readOnly />
      </td>
      <td style={{ width: ROW_W[3] }}>
        <HoursMinutesInput
          usedWithReadOnly
          minutes={minutesToArrive ? Number(minutesToArrive) : null}
          onChangeCommit={handleMinutesToArriveBlur}
          isDisabled={isDisabled}
        />
      </td>
      <td style={{ width: ROW_W[4] }}>
        <TableCellNumberTextField
          value={milesToArrive?.toString() ?? '-'}
          suffix='mi'
          onChange={handleMilesToArriveChange}
          onBlur={handleMilesToArriveBlur}
          onKeyDown={handleEnterKeyBlur}
          isDisabled={isDisabled}
        />
      </td>
      <td style={{ width: ROW_W[5] }}>
        <HoursMinutesInput
          usedWithReadOnly
          minutes={minutesOnSite ? Number(minutesOnSite) : null}
          onChangeCommit={handleMinutesOnSiteBlur}
          isDisabled={isDisabled}
        />
      </td>
      <td style={{ width: ROW_W[6], background: token('color.background.disabled') }}>
        <NumberCell value={milesProximityAdjusted} suffix='mi' readOnly />
      </td>
      <td style={{ width: ROW_W[7], background: token('color.background.disabled') }}>
        <HrMinCell value={calculatedMinutesDriveProximityAdjusted} />
      </td>
      <td
        style={{
          width: ROW_W[8],
          background: token('color.background.disabled'),
        }}
      >
        <HrMinCell value={calculatedMinutesDriveBuffer} />
      </td>
    </JobRow>
  )

  function handleJobCardClick() {
    if (vehicleDispatchId)
      setSearchParams(prev => {
        prev.set('vehicleDispatchId', vehicleDispatchId)
        return prev
      })
  }

  function handleMilesToArriveChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value
    setMilesToArrive(value)
  }

  function handleMilesToArriveBlur() {
    let compareValue = milesToArrive
    if (milesToArrive) {
      compareValue = parseFloat(milesToArrive).toFixed(2)
      setMilesToArrive(compareValue)
    }
    const bothBlank = compareValue === undefined && vehicleDispatch?.milesToArrive === null
    if (!bothBlank && compareValue !== String(vehicleDispatch?.milesToArrive)) {
      mutate({
        vehicleDateId: vehicleDispatch?.vehicleDateId,
        vehicleDispatchId: vehicleDispatch?.id,
        attribute: 'milesToArrive',
        value: compareValue,
      })
    }
  }

  function handleMinutesToArriveBlur(newMinutes: number | null) {
    const value = !newMinutes ? undefined : newMinutes.toString()
    setMinutesToArrive(value)
    const bothBlank = value === undefined && vehicleDispatch?.minutesToArrive === null
    if (!bothBlank && String(value) !== String(vehicleDispatch?.minutesToArrive)) {
      console.log('mutating minutesToArrive: ', { value: String(value), db: String(vehicleDispatch?.minutesToArrive) })
      mutate({
        vehicleDateId: vehicleDispatch?.vehicleDateId,
        vehicleDispatchId: vehicleDispatch?.id,
        attribute: 'minutesToArrive',
        value,
      })
    }
  }

  function handleProximityMinutesBlur(newMinutes: number | null) {
    const value = !newMinutes ? undefined : newMinutes.toString()
    setProximityMinutes(value)
    const bothBlank = value === undefined && vehicleDispatch?.minutesProximity === null
    if (!bothBlank && String(value) !== String(vehicleDispatch?.minutesProximity)) {
      mutate({
        vehicleDateId: vehicleDispatch?.vehicleDateId,
        vehicleDispatchId: vehicleDispatch?.id,
        attribute: 'minutesProximity',
        value,
      })
    }
  }

  function handleMinutesOnSiteBlur(newMinutes: number | null) {
    const value = !newMinutes ? undefined : newMinutes.toString()
    setMinutesOnSite(value)

    const bothBlank = value === undefined && vehicleDispatch?.minutesOnSite === null
    if (!bothBlank && value !== String(vehicleDispatch?.minutesOnSite)) {
      mutate({
        vehicleDateId: vehicleDispatch?.vehicleDateId,
        vehicleDispatchId: vehicleDispatch?.id,
        attribute: 'minutesOnSite',
        value,
      })
    }
  }

  function handleEnterKeyBlur(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') e.currentTarget.blur()
  }
}

export default VehicleDispatchRow

const JobRow = styled.tr`
  background-color: ${token('elevation.surface')};

  td + td {
    padding: 0;
    vertical-align: middle;
    text-align: right;
    position: relative;
  }
`

const JobCell = styled.td<{ $accentColor: CardColorOptions; $isRowSelected: boolean }>`
  height: 100%;
  min-height: 100%;

  padding: 0;
  width: ${ROW_W[0]}px;
  min-width: ${ROW_W[0]}px;

  border-color: ${({ $accentColor }) =>
    token(!$accentColor ? `color.border.accent.gray` : `color.border.accent.${$accentColor}`)} !important;
  /* border-bottom-width: 5px !important; */
  border-left: 3px solid ${({ $accentColor }) => token(!$accentColor ? `color.border` : `color.border.accent.${$accentColor}`)};
  background: ${({ $accentColor, $isRowSelected }) =>
    token(!$accentColor ? `elevation.surface.raised` : `color.background.accent.${$accentColor}.subtle${$isRowSelected ? '' : 'r'}`)};
  transition: background 0.1s ease-in-out;
  &:hover {
    ${({ $accentColor, $isRowSelected }) =>
      $isRowSelected
        ? ''
        : `background: ${token(!$accentColor ? `elevation.surface.raised` : `color.background.accent.${$accentColor}.subtler.hovered`)};
        cursor: pointer;
        `}
  }

  &:active {
    ${({ $accentColor, $isRowSelected }) =>
      $isRowSelected
        ? ''
        : `background: ${token(!$accentColor ? `elevation.surface.raised` : `color.background.accent.${$accentColor}.subtler.pressed`)};
        `}
  }

  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex: 0 0 none;

  .vehicle-dispatch-card-index {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 24px;
    font-size: 18px;
    font-weight: 800;

    flex: 0 0 auto;
    color: ${({ $accentColor }) =>
      // @ts-expect-error known issue with token function
      !$accentColor ? token(`color.text.sublest`) : token(`color.text.accent.${$accentColor}`)};
  }

  .job-card-info {
    flex: 1 1 auto;
    padding: 4px 8px 2px 0px;
    overflow: hidden;

    .customer-name {
      padding-bottom: 4px;
      border-bottom: 1px solid ${token('color.border')};
      padding-left: 1px;
      font-weight: bold;
      font-size: 13px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .job-site-address {
      display: flex;
      flex-direction: column;
      font-size: 11px;
      padding: 4px 2px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      a > span:not(.erh-address-link):not(.erh-davis-bacon-address) {
        display: none;
      }

      .erh-davis-bacon-address {
        display: inline-block;
        font-weight: 800;
        letter-spacing: -0.08em;
        margin-right: 1px;
        color: ${({ $accentColor }) =>
          // @ts-expect-error known issue with token function
          !$accentColor ? token(`color.text.sublest`) : token(`color.text.accent.${$accentColor}`)};
      }

      .erh-address-link {
        flex-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      a,
      span:not(.erh-davis-bacon-address) {
        color: ${token('color.text')};
        display: inline-block;
      }
    }
  }
`
