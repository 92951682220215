import { AllInstallerPayrollQuery } from '@/gql/graphql'

import { graphql } from '@/gql'

export const COL_1_WIDTH = 120
export const COL_2_WIDTH = 160
export const LOOKUP_COLUMN_WIDTH = 120
export const NON_LOOKUP_COLUMN_WIDTH = 120

export type InstallerWithWeeklyPayroll = NonNullable<AllInstallerPayrollQuery['allInstallerPayroll']>[number]
export type PayrollFieldValue = number | string | boolean | null | undefined

// type Aggregation = TimecardAggregationsFragmentFragment
type InstallerWeeklyPayrollType = NonNullable<InstallerWithWeeklyPayroll['installerWeeklyPayroll']>
export type InstallerDateType = InstallerWeeklyPayrollType['installerDates'][number]
export type InstallerWeeklyPayrollWithoutDates = Omit<InstallerWeeklyPayrollType, 'installerDates'>

export type InstallerDatesLookupKey = '1' | '2' | '3' | '4' | '5' | '6' | '7'
type InstallerDatesLookup = {
  [key in InstallerDatesLookupKey]: InstallerDateType | null
}
export type TimecardInstaller = InstallerWithWeeklyPayroll['installer']

export type InstallerWeeklyLookups = Omit<InstallerWithWeeklyPayroll, 'installerWeeklyPayroll'> & {
  installerWeeklyPayroll?: InstallerWeeklyPayrollWithoutDates
} & InstallerDatesLookup

export type InstallerWeeklyLookupsById = Record<string, InstallerWeeklyLookups>

export type PayrollValueSelectorFn = (row: InstallerWeeklyLookups) => PayrollFieldValue
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TimecardRenderFn = (row: InstallerWeeklyLookups) => React.ReactElement<any, string | React.JSXElementConstructor<any>>

// 1. Grab the “Aggregation” fields from the query
type WeeklyInstallerPayrollField = keyof Omit<NonNullable<InstallerWeeklyPayrollType>, '__typename'>

const TIMECARD_FIELDS_LIST = [
  'id',
  'installerId',
  'modifiedById',
  'weekOf',
  'modifiedAt',
  'dispatchesSyncedAt',
  'isHourlyInstaller',
  'defaultHourlyRate',
  'installerDispatchesSnapshot',
  'isCompleted',
  'isApproved',
  'isHidden',
  'noteForInstaller',
  'noteForAdmin',
  'countDaysWorked',
  'countDispatchesHourly',
  'countDispatchesPieceRate',
  'countDispatchesDavisBacon',
  'countDispatchesAll',
  'countDispatchesCompleted',
  'countDispatchesApproved',
  'davisBaconDispatchesMinutesDrive',
  'davisBaconDispatchesMinutesWorked',
  'davisBaconDispatchesMinutesTotal',
  'hourlyDispatchesMinutesDrive',
  'hourlyDispatchesMinutesWorked',
  'hourlyDispatchesMinutesTotal',
  'pieceRateDispatchesMinutesDrive',
  'pieceRateDispatchesMinutesWorked',
  'pieceRateDispatchesMinutesTotal',
  'totalDispatchesMinutesDrive',
  'totalDispatchesMinutesWorked',
  'totalDispatchesMinutesTotal',
  'totalDayMinutesNonDispatch',
  'totalDayMinutesNonDispatchPayable',
  'totalDayMinutesActual',
  'totalDayMinutesPayable',
  'totalDayMinutesLunch',
  'davisBaconDispatchesPayoutDrive',
  'davisBaconDispatchesPayoutDavisBacon',
  'davisBaconDispatchesPayoutPieceRateGap',
  'davisBaconDispatchesPayoutTotal',
  'hourlyDispatchesPayoutHourly',
  'hourlyDispatchesPayoutAddons',
  'hourlyDispatchesPayoutDrive',
  'hourlyDispatchesPayoutTotal',
  'pieceRateDispatchesPayoutAddons',
  'pieceRateDispatchesPayoutDrive',
  'pieceRateDispatchesPayoutPieceRate',
  'pieceRateDispatchesPayoutTotal',
  'totalDispatchesPayoutHourly',
  'totalDispatchesPayoutDavisBacon',
  'totalDispatchesPayoutDavisBaconPieceRateGap',
  'totalDispatchesPayoutAddons',
  'totalDispatchesPayoutDrive',
  'totalDispatchesPayoutPieceRate',
  'totalDispatchesPayoutTotal',
  'totalDayPayoutNonDispatch',
  'minutesAdjustmentManual',
  'minutesTotal',
  'minutesOvertime',
  'hourlyRateNonDispatch',
  'payoutSubtotalRaw',
  'hourlyRateRaw',
  'hourlyRateMinimum',
  'payoutMinimumWageCorrection',
  'payoutSubtotalBeforeOvertime',
  'hourlyRateBase',
  'payoutOvertimeUplift',
  'payoutTotalBeforeAdjustment',
  'payoutAdjustment',
  'payoutActual',
  'modifiedById',
] as const satisfies readonly WeeklyInstallerPayrollField[]

export type UsedWeeklyInstallerPayrollField = (typeof TIMECARD_FIELDS_LIST)[number]

type UsedFieldKey = UsedWeeklyInstallerPayrollField

export const FIELD_KEYS_ENUM = [...TIMECARD_FIELDS_LIST].reduce(
  (acc, field) => {
    acc[field] = field
    return acc
  },
  {} as Record<UsedFieldKey, UsedFieldKey>
)

export type FieldKeysEnum = typeof FIELD_KEYS_ENUM
export type ReconTableRowId = UsedFieldKey | 'totalDayMinutesNonDispatchReadOnly' | 'totalDayPayoutNonDispatchTotals' | 'isDriver'

export interface ReconTableRowDef {
  id: ReconTableRowId
  topHeader?: string
  topHeaderMinimized?: string
  fieldTitle: string
  fieldKey?: UsedFieldKey
  fieldType?: 'hourlyRate' | 'dollarAmount' | 'int' | 'hrMin'
  installerPayrollTooltip?: string
  lookupTooltip?: string
  highlightRow?: boolean
  installerPayrollAccessorFn?: PayrollValueSelectorFn
  installerPayrollCellFn?: (installerId: string, value: PayrollFieldValue, row?: InstallerWeeklyLookups) => React.ReactNode
}

export const INSTALLER_COLUMN_WIDTH = 140
export const FIRST_COLUMN_WIDTH = 170
export const SECOND_COLUMN_WIDTH = 200

export const GET_ALL_INSTALLER_PAYROLL_QUERY_KEY = 'getAllInstallerPayroll'
export const GET_ALL_INSTALLER_PAYROLL = graphql(/* GraphQL */ `
  query AllInstallerPayroll($weekNumber: Int!, $weekYear: Int!) {
    allInstallerPayroll(weekNumber: $weekNumber, weekYear: $weekYear) {
      ...InstallersWithWeeklyPayrollFragment
    }
  }

  fragment InstallersWithWeeklyPayrollFragment on InstallersWithWeeklyPayrollType {
    installerId
    weekOf
    weekYear
    weekNumber
    installer {
      id
      sid
      fullName
      lastName
      firstName
      isDriver
      isHourly
    }
    installerWeeklyPayroll {
      id
      installerId
      modifiedById
      weekOf
      modifiedAt
      dispatchesSyncedAt
      isHourlyInstaller
      defaultHourlyRate
      installerDispatchesSnapshot
      isCompleted
      isApproved
      isHidden
      noteForInstaller
      noteForAdmin
      countDaysWorked
      countDispatchesHourly
      countDispatchesPieceRate
      countDispatchesDavisBacon
      countDispatchesAll
      countDispatchesCompleted
      countDispatchesApproved
      davisBaconDispatchesMinutesDrive
      davisBaconDispatchesMinutesWorked
      davisBaconDispatchesMinutesTotal
      hourlyDispatchesMinutesDrive
      hourlyDispatchesMinutesWorked
      hourlyDispatchesMinutesTotal
      pieceRateDispatchesMinutesDrive
      pieceRateDispatchesMinutesWorked
      pieceRateDispatchesMinutesTotal
      totalDispatchesMinutesDrive
      totalDispatchesMinutesWorked
      totalDispatchesMinutesTotal
      totalDayMinutesNonDispatch
      totalDayMinutesNonDispatchPayable
      totalDayMinutesActual
      totalDayMinutesPayable
      totalDayMinutesLunch
      davisBaconDispatchesPayoutDrive
      davisBaconDispatchesPayoutDavisBacon
      davisBaconDispatchesPayoutPieceRateGap
      davisBaconDispatchesPayoutTotal
      hourlyDispatchesPayoutHourly
      hourlyDispatchesPayoutAddons
      hourlyDispatchesPayoutDrive
      hourlyDispatchesPayoutTotal
      pieceRateDispatchesPayoutAddons
      pieceRateDispatchesPayoutDrive
      pieceRateDispatchesPayoutPieceRate
      pieceRateDispatchesPayoutTotal
      totalDispatchesPayoutHourly
      totalDispatchesPayoutDavisBacon
      totalDispatchesPayoutDavisBaconPieceRateGap
      totalDispatchesPayoutAddons
      totalDispatchesPayoutDrive
      totalDispatchesPayoutPieceRate
      totalDispatchesPayoutTotal
      totalDayPayoutNonDispatch
      minutesAdjustmentManual
      minutesTotal
      minutesOvertime
      hourlyRateNonDispatch
      payoutSubtotalRaw
      hourlyRateRaw
      hourlyRateMinimum
      payoutMinimumWageCorrection
      payoutSubtotalBeforeOvertime
      hourlyRateBase
      payoutOvertimeUplift
      payoutTotalBeforeAdjustment
      payoutAdjustment
      payoutActual
      modifiedById
      modifiedBy {
        id
        sid
        fullName
        lastName
        firstName
      }
      installerDates {
        id
        installerId
        date
        modifiedAt
        dispatchesMinutesDrive
        dispatchesMinutesWorked
        dispatchesMinutesTotal
        dispatchesPayoutDrive
        dispatchesPayoutAddons
        dispatchesPayoutWork
        dispatchesPayoutTotal
        minutesActual
        minutesLunch
        minutesPayable
        minutesNonDispatch
        minutesNonDispatchPayable
        hourlyRateNonDispatch
        payoutNonDispatch
        payoutTotal
        hourlyRateForDay
        modifiedById
        modifiedBy {
          id
          sid
          fullName
          lastName
          firstName
        }
      }
    }
  }
`)
