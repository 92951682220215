// ui/src/components/MiniCalendarNav/HeaderRow.tsx

import React from 'react'

/** Re-usable helper for building day-of-week labels in correct order. */
function buildHeaderDays(firstDayOfWeek: number): string[] {
  // Sunday=0 => "Sun,Mon,Tue,Wed,Thu,Fri,Sat"
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  return days.slice(firstDayOfWeek).concat(days.slice(0, firstDayOfWeek))
}

interface HeaderRowProps {
  firstDayOfWeek?: number
}

const HeaderRow: React.FC<HeaderRowProps> = ({ firstDayOfWeek = 1 }) => {
  const headers = buildHeaderDays(firstDayOfWeek)

  return (
    <tr>
      <th>W</th>
      {headers.map(d => (
        <th key={d}>{d}</th>
      ))}
    </tr>
  )
}

export default HeaderRow
