import { Temporal } from '@js-temporal/polyfill'
import * as Sentry from '@sentry/react'
import { useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router'

import { accessTokenAtom, refreshTokenExpirationAtom } from '@/utils/appAtoms'

import AppRouter from '@/AuthRouter'
import Login from '@/pages/auth/Login'

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

const RootRouter = () => {
  const navigate = useNavigate()
  const pathname = useLocation()?.pathname

  const isLoggedIn = useIsLoggedIn()

  useEffect(() => {
    if (isLoggedIn && pathname.includes('login')) navigate('/calendar')
  }, [isLoggedIn, pathname, navigate])

  return (
    <SentryRoutes>
      <Route path='/login' element={<Login />} />
      <Route path='*' element={isLoggedIn ? <AppRouter /> : <Login />} />
    </SentryRoutes>
  )
}

export default RootRouter

const useIsLoggedIn = () => {
  const refreshTokenExpiration = useAtomValue(refreshTokenExpirationAtom)
  const token = useAtomValue(accessTokenAtom)
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    // Parse the timestamp as an `Instant` (which represents an absolute time in UTC)
    if (!refreshTokenExpiration) {
      setIsLoggedIn(false)
      return
    }
    const expiryInstant = Temporal.Instant.from(refreshTokenExpiration)

    // Convert the `Instant` to a ZonedDateTime in the 'America/Chicago' time zone
    const expiryInChicago = expiryInstant.toZonedDateTimeISO('America/Chicago')

    // Get the current time in the 'America/Chicago' time zone
    const nowInChicago = Temporal.Now.zonedDateTimeISO('America/Chicago')

    // Compare the two times
    const compareResult = Temporal.ZonedDateTime.compare(expiryInChicago, nowInChicago)

    const shouldBeLoggedIn = Boolean(token !== null && token.length > 3 && refreshTokenExpiration && compareResult >= 0)

    setIsLoggedIn(shouldBeLoggedIn)
  }, [token, refreshTokenExpiration])

  return isLoggedIn
}
