import { Temporal } from '@js-temporal/polyfill'
import { useQuery } from '@tanstack/react-query'
import type { TableProps } from 'antd'
import { ConfigProvider, Table, Typography } from 'antd'
import { useMemo } from 'react'
import { Link, useParams } from 'react-router'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'
import Tooltip from '@atlaskit/tooltip'

import { GetFinancialsByWorkOrderQuery } from '@/gql/graphql'
import useAntdTableTheme from '@/utils/useAntdTableTheme'
import useGraphQLClient from '@/utils/useAuthRequest'

import NumberCell from '@/components/NumberCell'

import GET_FINANCIALS_BY_WORK_ORDER, { GET_FINANCIALS_BY_WORK_ORDER_QUERY_KEY } from '@/utils/api/getFinancialsByWorkOrder'
import ReconAppPage from '../ReconAppPage'
import FinancialsByWorkOrderActions from './FinancialsByWorkOrderActions'

type FinancialsByWorkOrderType = GetFinancialsByWorkOrderQuery['financialsByWorkOrder'][number]

const calculateColumnTotals = (data: FinancialsByWorkOrderType[] | undefined) => {
  if (!data?.length) return null

  return {
    dispatchCountOutsideRange: data.reduce((sum, row) => sum + Number(row.dispatchCountOutsideRange || 0), 0),
    materialCost: data.reduce((sum, row) => sum + Number(row.materialCost || 0), 0),
    projectedDrivePay: data.reduce((sum, row) => sum + Number(row.projectedDrivePay || 0), 0),
    projectedPieceRate: data.reduce((sum, row) => sum + Number(row.projectedPieceRate || 0), 0),
    projectedAddons: data.reduce((sum, row) => sum + Number(row.projectedAddons || 0), 0),
    laborCost: data.reduce((sum, row) => sum + Number(row.laborCost || 0), 0),
    totalCost: data.reduce((sum, row) => sum + Number(row.totalCost || 0), 0),
    totalPrice: data.reduce((sum, row) => sum + Number(row.totalPrice || 0), 0),
    marginAmount: data.reduce((sum, row) => sum + Number(row.marginAmount || 0), 0),
  }
}

const FinancialsByWorkOrder = () => {
  console.log('FinancialsByWorkOrder')
  const params = useParams()
  const graphqlClient = useGraphQLClient()
  const theme = useAntdTableTheme()

  const { fallbackStartDate, fallbackEndDate } = useMemo(() => {
    const now = Temporal.Now.zonedDateTimeISO().toPlainDate()
    const startDate = now.with({ day: 1 }).toString()
    const endDate = now.with({ day: now.daysInMonth }).toString()
    return { fallbackStartDate: startDate, fallbackEndDate: endDate }
  }, [])

  const variables = useMemo(() => {
    const startDate = params.startDate ? params.startDate : fallbackStartDate
    const endDate = params.endDate ? params.endDate : fallbackEndDate
    return { startDate, endDate }
  }, [params, fallbackStartDate, fallbackEndDate])

  const { isLoading, error, data } = useQuery({
    queryKey: [GET_FINANCIALS_BY_WORK_ORDER_QUERY_KEY, variables],
    queryFn: () => graphqlClient.request(GET_FINANCIALS_BY_WORK_ORDER, variables),
    enabled: !!variables.startDate && !!variables.endDate,
  })

  return (
    <ReconAppPage
      header='Financials By Work Order'
      actions={
        <FinancialsByWorkOrderActions
          fallbackStartDate={fallbackStartDate}
          fallbackEndDate={fallbackEndDate}
          startDate={variables.startDate}
          endDate={variables.endDate}
        />
      }
    >
      <AbsoluteScrollContainer>
        {isLoading && <div>Loading...</div>}
        {error && <div>Error: {error.message}</div>}
        {data ? (
          <div style={{ marginBottom: 10, color: token('color.text.subtlest') }}>
            Found {data?.financialsByWorkOrder?.length} Work Orders dispatched within this date range.
          </div>
        ) : (
          <div>No data</div>
        )}
        <div className='table-scroll-container'>
          <ConfigProvider theme={theme}>
            <Table
              rowKey='workOrderId'
              columns={columns}
              dataSource={data?.financialsByWorkOrder}
              size='small'
              loading={isLoading}
              pagination={false}
              scroll={{
                x: 'max-content',
                y: 'calc(100vh - 330px)',
              }}
              summary={currentData => {
                const totals = calculateColumnTotals(currentData as FinancialsByWorkOrderType[])
                if (!totals) return null

                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <SummaryCell index={0} colSpan={3}>
                        <Typography.Text strong>Totals</Typography.Text>
                      </SummaryCell>

                      <SummaryCell index={3}>
                        <Typography.Text strong></Typography.Text>
                      </SummaryCell>
                      <SummaryCell index={4}>
                        <Typography.Text strong></Typography.Text>
                      </SummaryCell>
                      <SummaryCell index={5}>
                        <Typography.Text strong></Typography.Text>
                      </SummaryCell>
                      <SummaryCell index={6}>
                        <Typography.Text strong></Typography.Text>
                      </SummaryCell>
                      <SummaryCell index={7}>
                        <Typography.Text strong></Typography.Text>
                      </SummaryCell>
                      <SummaryCell index={8}>
                        <Typography.Text strong></Typography.Text>
                      </SummaryCell>
                      <SummaryCell index={9}>
                        <Typography.Text strong></Typography.Text>
                      </SummaryCell>
                      {/* <SummaryCell index={10} align='right'>
                        {totals.dispatchCountOutsideRange}
                      </SummaryCell> */}
                      <SummaryCell index={11} align='right' className='antd-number-cell'>
                        <NumberCell value={totals.materialCost} prefix='$' />
                      </SummaryCell>
                      <SummaryCell index={12} align='right' className='antd-number-cell'>
                        <NumberCell value={totals.projectedDrivePay} prefix='$' />
                      </SummaryCell>
                      <SummaryCell index={13} align='right' className='antd-number-cell'>
                        <NumberCell value={totals.projectedPieceRate} prefix='$' />
                      </SummaryCell>
                      <SummaryCell index={14} align='right' className='antd-number-cell'>
                        <NumberCell value={totals.projectedAddons} prefix='$' />
                      </SummaryCell>
                      <SummaryCell index={15} align='right' className='antd-number-cell'>
                        <NumberCell value={totals.laborCost} prefix='$' />
                      </SummaryCell>
                      <SummaryCell index={16} align='right' className='antd-number-cell'>
                        <NumberCell value={totals.totalCost} prefix='$' />
                      </SummaryCell>
                      <SummaryCell index={17} align='right' className='antd-number-cell'>
                        <NumberCell value={totals.totalPrice} prefix='$' />
                      </SummaryCell>
                      <SummaryCell index={18} align='right' className='antd-number-cell'>
                        <NumberCell value={totals.marginAmount} prefix='$' />
                      </SummaryCell>
                      <SummaryCell index={19} align='right' className='antd-number-cell'>
                        <NumberCell value={((Number(totals.marginAmount) / Number(totals.totalPrice)) * 100).toFixed(2)} suffix='%' />
                      </SummaryCell>
                    </Table.Summary.Row>
                  </Table.Summary>
                )
              }}
            />
          </ConfigProvider>
        </div>
      </AbsoluteScrollContainer>
    </ReconAppPage>
  )
}

export default FinancialsByWorkOrder

const AbsoluteScrollContainer = styled.div`
  position: absolute;
  inset: 0 32px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .table-scroll-container {
    align-self: stretch;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    td {
      padding: 4px 8px !important;
      max-width: 200px; /* Optional: Limit the width if needed */

      white-space: nowrap; /* Inherit wrapping rules */
      overflow: hidden; /* Hide overflowing text */
      text-overflow: ellipsis; /* Show ellipsis */

      &.antd-number-cell {
        padding: 0px !important;

        > div {
          > span.num-cell-value-suffix {
            flex: 1;
          }
        }
      }
    }
    td > div {
      white-space: nowrap; /* Prevent wrapping */
      overflow: hidden; /* Hide overflow content */
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex: 1 1 200px;
    }

    td > div > a {
      display: inline-block; /* Allows the anchor to honor the ellipsis */
      white-space: nowrap; /* Inherit wrapping rules */
      overflow: hidden; /* Hide overflowing text */
      text-overflow: ellipsis; /* Show ellipsis */
      width: 100%;
      max-width: 100%; /* Ensure it stays within the parent's bounds */
    }
  }
`

const SummaryCell = styled(Table.Summary.Cell)`
  background-color: ${token('color.background.accent.gray.subtlest')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

interface EstimateLinkProps {
  jobId: string
  estimateId: string
  estimateTitle: string
}
const EstimateLink = ({ jobId, estimateId, estimateTitle }: EstimateLinkProps) => (
  <Tooltip content={'Estimate: ' + estimateTitle}>
    <Link to={{ pathname: `/jobs/${jobId}/estimates/${estimateId}` }} target='_blank'>
      {estimateTitle}
    </Link>
  </Tooltip>
)

interface CustomerLinkProps {
  customerName: string
  customerId: string
}
const CustomerLink = ({ customerName, customerId }: CustomerLinkProps) => (
  <Tooltip content={'Customer: ' + customerName}>
    <Link to={{ pathname: `/customers/${customerId}` }} target='_blank'>
      {customerName}
    </Link>
  </Tooltip>
)

interface JobLinkProps {
  jobTitle: string
  jobId: string
}
const JobLink = ({ jobTitle, jobId }: JobLinkProps) => (
  <Tooltip content={'Job: ' + jobTitle}>
    <Link to={{ pathname: `/jobs/${jobId}` }} target='_blank'>
      {jobTitle}
    </Link>
  </Tooltip>
)
const columns: TableProps<FinancialsByWorkOrderType>['columns'] = [
  {
    key: 'customerName', // column index 0
    title: 'Customer',
    dataIndex: 'customerName',
    sorter: (a, b) => a.customerName.localeCompare(b.customerName),
    fixed: 'left',
    width: 180,
    render: (_, { customerId, customerName }) => <CustomerLink customerId={customerId} customerName={customerName} />,
  },
  {
    key: 'estimateTitle', // column index 1
    title: 'Estimate Title',
    dataIndex: 'estimateTitle',
    sorter: (a, b) => a.estimateTitle.localeCompare(b.estimateTitle),
    fixed: 'left',
    width: 200,
    render: (_, { jobId, estimateId, estimateTitle }) => (
      <EstimateLink jobId={jobId} estimateId={estimateId} estimateTitle={estimateTitle} />
    ),
  },
  {
    key: 'workOrderId', // column index 2
    title: 'Work Order',
    dataIndex: 'workOrderId',
    sorter: (a, b) => a.workOrderId.localeCompare(b.workOrderId),
    width: 70,
    render: (_, { workOrderId, workOrderName }) => `#${workOrderId} - ${workOrderName}`,
    fixed: 'left',
  },
  {
    key: 'jobTitle', // column index 3
    title: 'Job Title',
    dataIndex: 'jobTitle',
    sorter: (a, b) => a.jobTitle.localeCompare(b.jobTitle),
    width: 200,
    render: (_, { jobId, jobTitle }) => <JobLink jobId={jobId} jobTitle={jobTitle} />,
  },
  {
    key: 'isDavisBacon', // column index 4
    title: 'Davis Bacon',
    dataIndex: 'isDavisBacon',
    render: text => (text === null ? '-' : text ? 'Yes' : 'No'),
    sorter: (a, b) => Number(a.isDavisBacon) - Number(b.isDavisBacon),
    width: 70,
  },
  {
    key: 'salespersonName', // column index 5
    title: 'Salesperson',
    dataIndex: 'salespersonName',
    sorter: (a, b) => a.salespersonName.localeCompare(b.salespersonName),
  },
  {
    key: 'projectSiteName', // column index 6
    title: 'Project Site',
    dataIndex: 'projectSiteName',
    sorter: (a, b) => a.projectSiteName.localeCompare(b.projectSiteName),
  },

  {
    key: 'isWorkCompleted', // column index 7
    title: 'Done',
    dataIndex: 'isWorkCompleted',
    sorter: (a, b) => Number(a.isWorkCompleted) - Number(b.isWorkCompleted),
    render: text => (text === null ? '-' : text ? 'Yes' : 'No'),
    width: 70,
  },
  {
    key: 'firstDispatchDate', // column index 8
    title: 'First Dispatch Date',
    dataIndex: 'firstDispatchDate',
    sorter: (a, b) => new Date(a.firstDispatchDate ?? '').getTime() - new Date(b.firstDispatchDate ?? '').getTime(),
  },
  {
    key: 'lastDispatchDate', // column index 9
    title: 'Last Dispatch Date',
    dataIndex: 'lastDispatchDate',
    sorter: (a, b) => new Date(a.lastDispatchDate ?? '').getTime() - new Date(b.lastDispatchDate ?? '').getTime(),
  },
  // {
  //   key: 'dispatchCountOutsideRange', // column index 10
  //   title: 'Dispatches Outside Range',
  //   dataIndex: 'dispatchCountOutsideRange',
  //   sorter: (a, b) => a.dispatchCountOutsideRange - b.dispatchCountOutsideRange,
  //   width: 90,
  //   align: 'right',
  //   className: 'antd-number-cell',
  //   render: text => <NumberCell value={text} decimalPlaces={0} />,
  // },
  {
    key: 'materialCost', // column index 11
    title: 'Material Cost',
    dataIndex: 'materialCost',
    align: 'right',
    className: 'antd-number-cell',
    render: text => <NumberCell value={text} prefix='$' />,
    sorter: (a, b) => Number(a.materialCost ?? 0) - Number(b.materialCost ?? 0),
    width: 130,
  },
  {
    key: 'projectedDrivePay', // column index 12
    title: 'Drive Pay',
    dataIndex: 'projectedDrivePay',
    align: 'right',
    className: 'antd-number-cell',
    render: text => <NumberCell value={text} prefix='$' />,
    sorter: (a, b) => Number(a.projectedDrivePay ?? 0) - Number(b.projectedDrivePay ?? 0),
    width: 120,
  },
  {
    key: 'projectedPieceRate', // column index 13
    title: 'Piece Rate',
    dataIndex: 'projectedPieceRate',
    align: 'right',
    className: 'antd-number-cell',
    render: text => <NumberCell value={text} prefix='$' readOnly />,
    sorter: (a, b) => Number(a.projectedPieceRate ?? 0) - Number(b.projectedPieceRate ?? 0),
    width: 120,
  },
  {
    key: 'projectedAddons', // column index 14
    title: 'Addons',
    dataIndex: 'projectedAddons',
    align: 'right',
    className: 'antd-number-cell',
    render: text => <NumberCell value={text} prefix='$' readOnly />,
    sorter: (a, b) => Number(a.projectedAddons ?? 0) - Number(b.projectedAddons ?? 0),
    width: 120,
  },
  {
    key: 'laborCost', // column index 15
    title: 'Labor Cost',
    dataIndex: 'laborCost',
    align: 'right',
    className: 'antd-number-cell',
    render: text => <NumberCell value={text} prefix='$' readOnly />,
    sorter: (a, b) => Number(a.laborCost ?? 0) - Number(b.laborCost ?? 0),
    width: 120,
  },
  {
    key: 'totalCost', // column index 16
    title: 'Total Cost',
    dataIndex: 'totalCost',
    align: 'right',
    className: 'antd-number-cell',
    render: text => <NumberCell value={text} prefix='$' readOnly />,
    sorter: (a, b) => Number(a.totalCost ?? 0) - Number(b.totalCost ?? 0),
    width: 120,
  },
  {
    key: 'totalPrice', // column index 17
    title: 'Total Price',
    dataIndex: 'totalPrice',
    align: 'right',
    className: 'antd-number-cell',
    render: text => <NumberCell value={text} prefix='$' readOnly />,
    sorter: (a, b) => Number(a.totalPrice ?? 0) - Number(b.totalPrice ?? 0),
    width: 120,
  },
  {
    key: 'marginAmount', // column index 18
    title: 'Margin Amount',
    dataIndex: 'marginAmount',
    align: 'right',
    className: 'antd-number-cell',
    render: text => <NumberCell value={text} prefix='$' readOnly />,
    sorter: (a, b) => Number(a.marginAmount ?? 0) - Number(b.marginAmount ?? 0),
    width: 120,
  },
  {
    key: 'marginPercent', // column index 19
    title: 'Margin Percent',
    dataIndex: 'marginPercent',
    align: 'right',
    className: 'antd-number-cell',
    render: text => <NumberCell value={text} suffix='%' readOnly />,
    sorter: (a, b) => Number(a.marginPercent ?? 0) - Number(b.marginPercent ?? 0),
    width: 100,
  },
]
