import { AnimatePresence, motion } from 'framer-motion'
import { JSXElementConstructor, ReactElement, ReactNode, useMemo } from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs'
import InlineEdit from '@atlaskit/inline-edit'
import PageHeader from '@atlaskit/page-header'
import { Content, Main } from '@atlaskit/page-layout'
import Spinner from '@atlaskit/spinner'
import { token, useThemeObserver } from '@atlaskit/tokens'

import AppRightSidebar from './AppRightSidebar'

export type BreadcrumbItem = {
  navigateTo: string
  label: string
}
type SideBarAttributes = {
  key: string
  value: string
}

type AppPageProps = {
  children: ReactNode
  //
  showLeftSidebar?: boolean
  leftSidebarContent?: ReactNode
  //
  rightSidebarContent?: ReactNode
  rightSidebarTitle?: string
  rightSidebarAttributes?: SideBarAttributes[]
  rightSidebarWidth?: number
  //
  header: ReactNode | string
  breadcrumbs?: BreadcrumbItem[]
  disableScroll?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actions?: ReactElement<any, string | JSXElementConstructor<any>> | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bottomBar?: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  //
  rightPanelComponent?: ReactNode
  isRightPanelOpen?: boolean
  rightPanelWidth?: number
  rightSidebarActions?: ReactNode
  isLoading?: boolean
  onTitleEdit?: (title: string) => void
}

const homePageBreadcrumbs: BreadcrumbItem[] = [{ navigateTo: 'home', label: 'Home' }]

const DEFAULT_RIGHT_PANEL_WIDTH = 400

const CustomTitleComponent = ({ title, onTitleEdit }: { title: string; onTitleEdit: (title: string) => void }) => {
  return (
    <InlineEdit
      readView={() => <ReadView>{title}</ReadView>}
      editView={(props, ref) => <EditView {...props} ref={ref} />}
      defaultValue={title}
      onConfirm={onTitleEdit}
    />
  )
}

const AppPage = ({
  children,
  showLeftSidebar = false,
  leftSidebarContent,
  rightSidebarContent,
  rightSidebarTitle = 'Attributes',
  rightSidebarWidth = 300,
  isRightPanelOpen = false,
  rightPanelComponent,
  rightPanelWidth = DEFAULT_RIGHT_PANEL_WIDTH,
  header = '<Header>',
  breadcrumbs,
  actions,
  bottomBar,
  disableScroll = false,
  rightSidebarActions,
  isLoading = false,
  onTitleEdit,
}: AppPageProps) => {
  const navigate = useNavigate()
  const theme = useThemeObserver()

  const isDark = theme.colorMode === 'dark'

  const breadcrumbsToUse = useMemo(
    () => (
      <Breadcrumbs>
        {(breadcrumbs ?? homePageBreadcrumbs).map(item => (
          <BreadcrumbsItem
            text={item.label}
            key={item.navigateTo}
            onClick={e => {
              e.preventDefault()
              navigate(item.navigateTo)
            }}
          />
        ))}
      </Breadcrumbs>
    ),
    [breadcrumbs, navigate]
  )

  return (
    <Content>
      {showLeftSidebar ? leftSidebarContent : null}

      <Main>
        <MainWrapper>
          <MainContent $isRightPanelOpen={isRightPanelOpen} $rightPanelWidth={rightPanelWidth}>
            <HeaderPadding>
              <PageHeader breadcrumbs={breadcrumbsToUse} actions={actions} bottomBar={bottomBar}>
                <TitleWrapper $isEditable={!!onTitleEdit}>
                  {onTitleEdit ? <CustomTitleComponent onTitleEdit={onTitleEdit} title={header as string} /> : header}
                  {isLoading && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Spinner />
                    </div>
                  )}
                </TitleWrapper>
              </PageHeader>
            </HeaderPadding>

            <InnerContentDiv $disableScroll={disableScroll} $isDark={isDark}>
              {children}
            </InnerContentDiv>
          </MainContent>
        </MainWrapper>
      </Main>

      {!rightSidebarContent ? null : (
        <AppRightSidebar actions={rightSidebarActions} width={rightSidebarWidth} title={rightSidebarTitle}>
          {rightSidebarContent}
        </AppRightSidebar>
      )}

      <AnimatePresence>
        {rightPanelComponent && (
          <RightPanelWrapper
            initial={{
              opacity: 1,
              translateX: rightPanelWidth,
            }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 1, translateX: rightPanelWidth }}
            transition={{
              duration: 0.2,
              ease: [0.0, 0.8, 0.1, 1], // Custom bezier curve for mounting
              exit: {
                duration: 0.2,
                ease: [0.8, 0.05, 0.01, 0.9], // Custom bezier curve for unmounting
              },
            }}
          >
            {rightPanelComponent}
          </RightPanelWrapper>
        )}
      </AnimatePresence>
    </Content>
  )
}

const ReadView = styled.div`
  display: flex;
  max-width: 100%;
  padding: ${token('space.100', '8px')} ${token('space.075', '6px')};
  font: ${token('font.heading.large')};
  overflow: hidden;
`

const EditView = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding: ${token('space.075', '6px')} ${token('space.075', '6px')};
  border: 2px solid ${token('color.border')};
  border-radius: 3px;
  cursor: inherit;
  font: ${token('font.heading.large')};
  outline: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  :focus {
    border: 2px solid ${token('color.border.focused')};
  }
`
const TitleWrapper = styled.div<{ $isEditable: boolean }>`
  margin-left: ${({ $isEditable }) => ($isEditable ? '-8px' : '0')};
  margin-top: ${({ $isEditable }) => ($isEditable ? '-16px' : '0')};
  position: relative;
  display: flex;
  align-items: center;
  gap: 12px;
`

const RightPanelWrapper = styled(motion.div)`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${token('elevation.surface')};
`

const MainWrapper = styled.div`
  position: relative;
  align-self: stretch;
  min-width: 0;
  min-height: 0;
  flex: 1;
  height: calc(100vh - 56px);
  display: flex;
  flex-direction: row;
`

type MainContentProps = {
  $isRightPanelOpen: boolean
  $rightPanelWidth: number
}
const MainContent = styled.div<MainContentProps>`
  z-index: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  margin-right: ${({ $isRightPanelOpen, $rightPanelWidth }) => ($isRightPanelOpen ? $rightPanelWidth + 'px' : '0px')};
  transition: margin-right 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  position: relative;
  box-sizing: border-box;
`
const HeaderPadding = styled.div`
  padding: 0px 32px;
  z-index: 2;
`

type InnerContentDivProps = {
  $disableScroll?: boolean
  $isDark?: boolean
}
const InnerContentDiv = styled.div<InnerContentDivProps>`
  flex: 1;
  z-index: 1;
  position: relative;
  padding: 0px ${({ $disableScroll }) => ($disableScroll === false ? '32px 32px' : '0 0')} 32px;
  display: flex;
  min-width: 1px;

  box-sizing: border-box;

  overflow: ${({ $disableScroll }) => ($disableScroll === false ? 'auto' : 'hidden')};

  ${({ $disableScroll, $isDark }) =>
    $disableScroll === false && !$isDark
      ? `
  background: linear-gradient(white 30%, hsla(0, 0%, 100%, 0)), linear-gradient(hsla(0, 0%, 100%, 0) 10px, white 70%) bottom,
    radial-gradient(at top, rgba(0, 0, 0, 0.2), transparent 70%), radial-gradient(at bottom, rgba(0, 0, 0, 0.2), transparent 70%) bottom;
  background-repeat: no-repeat;
  background-size: 100% 20px, 100% 20px, 100% 10px, 100% 10px;
  background-attachment: local, local, scroll, scroll;`
      : ''}
`

export default AppPage
