import { CELL_PAD } from './constants'

import { Link } from 'react-router'

import { token } from '@atlaskit/tokens'

import { GetDispatchPayoutDetailsQuery } from '@/gql/graphql'

import NumberCell from '@/components/NumberCell'
import { useMemo } from 'react'

interface WorkOrderDispatchRowProps {
  dispatch: GetDispatchPayoutDetailsQuery['workOrder']['dispatches'][0]
  dateString: string
  paramValues: URLSearchParams
  isDavisBacon: boolean
  isLast: boolean
}

const linkBase = `/recon/daily/`

const WorkOrderDispatchRow = ({ dispatch, dateString, paramValues, isDavisBacon, isLast }: WorkOrderDispatchRowProps) => {
  const isSelected = dispatch.date === dateString

  const style = useMemo(() => {
    const backgroundColor = isSelected ? token('color.background.selected') : 'transparent'
    const borderBottomWidth = isLast ? 3 : 1
    const borderBottom = `${borderBottomWidth}px solid ${token('color.border')}`
    return { backgroundColor, fontSize: 12, borderBottom }
  }, [isLast, isSelected])

  const vehicleAssignment = dispatch?.vehicleAssignments?.[0]
  const vehicleDateId = vehicleAssignment?.vehicleDateId
  const vehicleDispatchId = vehicleAssignment?.id

  const linkProps = useMemo(() => {
    const newParams = new URLSearchParams(paramValues)
    newParams.delete('vehicleDateId')
    newParams.delete('vehicleDispatchId')
    newParams.delete('dateId')
    newParams.set('vehicleDateId', vehicleDateId ?? '')
    newParams.set('vehicleDispatchId', vehicleDispatchId ?? '')
    newParams.set('dateId', dispatch.date)

    const link = `${linkBase}?${newParams.toString()}`
    return {
      to: link,
      state: {
        vehicleDateId,
        vehicleDispatchId,
      },
    }
  }, [dispatch.date, paramValues, vehicleDispatchId, vehicleDateId])

  const labelStyle = useMemo(() => {
    return { paddingLeft: 8, color: isSelected ? token('color.text.selected') : token('color.text.subtlest') }
  }, [isSelected])

  return (
    <tr key={dispatch.date} style={style}>
      <td style={labelStyle} className='col-row-labels'>
        <Link {...linkProps}>{dispatch.date}</Link>
      </td>

      {isDavisBacon ? <td className='dispatch-col-hourly' /> : <td className='dispatch-col-hourly' />}

      {isDavisBacon ? <td className='dispatch-col-pr-gap' /> : null}

      <td className='dispatch-col-drive'>
        <NumberCell padding={CELL_PAD} readOnly value={null} prefix='$' />
      </td>
      <td className='dispatch-col-piece-rate'>
        <NumberCell padding={CELL_PAD} readOnly value={dispatch.enteredPieceRate} prefix='$' />
      </td>
      <td className='dispatch-col-addons'>
        <NumberCell padding={CELL_PAD} readOnly value={dispatch.enteredAddons} prefix='$' />
      </td>
      <td className='dispatch-col-total'>
        <NumberCell padding={CELL_PAD} readOnly value={dispatch.enteredPayTotal} prefix='$' />
      </td>
    </tr>
  )
}

export default WorkOrderDispatchRow
