import { useState } from 'react'

import { IconButton } from '@atlaskit/button/new'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'
import MoreIcon from '@atlaskit/icon/glyph/more'
import { token } from '@atlaskit/tokens'

import DeleteWorkOrderModal, { JobQueryWorkOrderType } from './DeleteWorkOrderModal'

const WorkOrderTableDropdown = ({ workOrder }: { workOrder: JobQueryWorkOrderType }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  return (
    <>
      <DropdownMenu
        placement='bottom-end'
        trigger={({ triggerRef, ...props }) => <IconButton {...props} icon={MoreIcon} label='more' ref={triggerRef} />}
      >
        <DropdownItemGroup>
          <DropdownItem onClick={() => setShowDeleteModal(true)}>
            <span style={{ color: token('color.text.danger') }}>Delete Work Order</span>
          </DropdownItem>
        </DropdownItemGroup>
      </DropdownMenu>

      <DeleteWorkOrderModal workOrder={workOrder} onClose={() => setShowDeleteModal(false)} isOpen={showDeleteModal} />
    </>
  )
}

export default WorkOrderTableDropdown
