import { graphql } from '@/gql'
export const GET_FINANCIALS_BY_WORK_ORDER_QUERY_KEY = 'getFinancialsByWorkOrder'
const GET_FINANCIALS_BY_WORK_ORDER = graphql(/* GraphQL */ `
  query GetFinancialsByWorkOrder($startDate: Date!, $endDate: Date!) {
    financialsByWorkOrder(startDate: $startDate, endDate: $endDate) {
      estimateTitle
      estimateId
      isDavisBacon
      salespersonName
      salespersonId
      jobTitle
      jobId
      customerName
      customerId
      projectSiteName
      projectSiteId
      tripName
      workOrderId
      workOrderName
      isWorkCompleted
      materialCost
      projectedDrivePay
      projectedPieceRate
      projectedAddons
      laborCost
      totalCost
      totalPrice
      marginPercent
      marginAmount
      lastDispatchDate
      firstDispatchDate
      dispatchCountOutsideRange
    }
  }
`)

export default GET_FINANCIALS_BY_WORK_ORDER
