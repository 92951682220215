import styled from 'styled-components'

import { token } from '@atlaskit/tokens'
import Tooltip from '@atlaskit/tooltip'

interface FieldNameCellWithTooltipProps {
  installerPayrollTooltip?: string
  lookupTooltip?: string
  fieldTitle: string
}
const FieldNameCellWithTooltip = ({ installerPayrollTooltip, lookupTooltip, fieldTitle }: FieldNameCellWithTooltipProps) => (
  <FieldNameWrapper>
    <Tooltip
      content={
        <FieldNameCellContent installerPayrollTooltip={installerPayrollTooltip} lookupTooltip={lookupTooltip} fieldTitle={fieldTitle} />
      }
      position='right-start'
    >
      {tooltipProps => (
        <span style={{ fontWeight: '500', color: token('color.text.subtle'), padding: '0 8px' }} {...tooltipProps}>
          {fieldTitle}
        </span>
      )}
    </Tooltip>
  </FieldNameWrapper>
)

export default FieldNameCellWithTooltip

const FieldNameCellContent = ({ installerPayrollTooltip, lookupTooltip, fieldTitle }: FieldNameCellWithTooltipProps) => {
  if (!installerPayrollTooltip) return fieldTitle
  return (
    <div>
      <div>{installerPayrollTooltip}</div>
      {!lookupTooltip ? null : (
        <div style={{ marginTop: 8 }}>
          <span style={{ fontWeight: '600' }}>Lookup Column: </span>
          {lookupTooltip}
        </div>
      )}
    </div>
  )
}

const FieldNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
