import ToggleShowLookups from './ToggleShowLookups'

const ReconPayrollActions = () => {
  return (
    <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
      {/* <SyncButton weekOf={weekOf.toString()} /> */}
      <ToggleShowLookups />
    </div>
  )
}

export default ReconPayrollActions
