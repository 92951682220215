import { useMutation, useQueryClient } from '@tanstack/react-query'

import useGraphQLClient from '@/utils/useAuthRequest'

import { GetVehicleDateQuery, SubmitVehicleDateStatsMutationVariables } from '@/gql/graphql'

import { graphql } from '@/gql'

import { GET_DISPATCH_PAYOUT_DETAILS_QUERY_KEY } from './components/DispatchSection'

const useSubmitVehicleDateStats = () => {
  const graphQLClient = useGraphQLClient()
  const queryClient = useQueryClient()

  const { mutate, isPending, data } = useMutation({
    mutationFn: async (variables: SubmitVehicleDateStatsMutationVariables) => {
      const result = await graphQLClient.request(SUBMIT_VEHICLE_DATE_STATS, variables)
      if (!result.submitVehicleDateStats.success) throw new Error(result.submitVehicleDateStats.message)
      return result
    },
    onMutate: async variables => {
      // Cancel any outgoing refetches, then Snapshot the previous value
      await queryClient.cancelQueries({ queryKey: ['GetVehicleDate', variables.vehicleDateId] })
      const previousData = queryClient.getQueryData<GetVehicleDateQuery>(['GetVehicleDate', variables.vehicleDateId])
      // Optimistically update to the new value
      queryClient.setQueryData<GetVehicleDateQuery>(['GetVehicleDate', variables.vehicleDateId], old =>
        optimisticUpdateVehicleDate({ variables, old })
      )
      return { previousData }
    },
    onSuccess: (result, variables) => {
      // Update the cache with the returned data
      queryClient.setQueryData<GetVehicleDateQuery>(['GetVehicleDate', variables.vehicleDateId], old => {
        if (!old?.vehicleDate) return old

        return {
          ...old,
          vehicleDate: {
            ...old.vehicleDate,
            ...result.submitVehicleDateStats.vehicleDate,
            // Preserve the existing dispatch assignments if they're not in the result
            vehicleDispatchAssignments:
              result.submitVehicleDateStats.vehicleDate?.vehicleDispatchAssignments ?? old.vehicleDate.vehicleDispatchAssignments,
          },
        }
      })
      queryClient.invalidateQueries({ queryKey: [GET_DISPATCH_PAYOUT_DETAILS_QUERY_KEY] })
    },
    onError: (_err, variables, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      if (context?.previousData) queryClient.setQueryData(['GetVehicleDate', variables.vehicleDateId], context.previousData)
    },
  })

  return { mutate, isPending, data }
}

export default useSubmitVehicleDateStats

interface OptimisticUpdateVehicleDateProps {
  variables: SubmitVehicleDateStatsMutationVariables
  old?: GetVehicleDateQuery
}
function optimisticUpdateVehicleDate({ variables, old }: OptimisticUpdateVehicleDateProps) {
  if (!old?.vehicleDate) return old

  // Update specific dispatch assignment
  if (variables.vehicleDispatchId)
    return {
      ...old,
      vehicleDate: {
        ...old.vehicleDate,
        vehicleDispatchAssignments: old.vehicleDate.vehicleDispatchAssignments.map(assignment =>
          assignment.id === variables.vehicleDispatchId ? { ...assignment, [variables.attribute]: variables.value } : assignment
        ),
      },
    }

  // Update vehicle date level attribute
  return {
    ...old,
    vehicleDate: {
      ...old.vehicleDate,
      [variables.attribute]: variables.value,
    },
  }
}

const SUBMIT_VEHICLE_DATE_STATS = graphql(/* GraphQL */ `
  mutation SubmitVehicleDateStats($vehicleDateId: ID!, $vehicleDispatchId: ID, $attribute: String!, $value: String) {
    submitVehicleDateStats(vehicleDateId: $vehicleDateId, vehicleDispatchId: $vehicleDispatchId, attribute: $attribute, value: $value) {
      success
      message
      vehicleDate {
        ...VehicleDateReconFragment
      }
    }
  }
`)

export const VEHICLE_DATE_RECON_FRAGMENT = graphql(/* GraphQL */ `
  fragment VehicleDateReconFragment on VehicleDateType {
    id
    sid
    sortedVehicleDispatchIds
    totalMilesDriven
    totalMinutesDriven
    totalMinutesProximity
    minutesToArriveHome
    milesToArriveHome
    totalDrivePayout
    totalMinutesVehicleOnSite

    vehicleDispatchAssignments {
      id
      sid
      vehicleDateId

      minutesProximity
      minutesToArrive
      minutesOnSite

      milesToArrive
      milesProximityAdjusted

      calculatedProximityPercent
      calculatedMinutesDriveProximityAdjusted
      calculatedMinutesDriveBuffer

      assignedInstallerIds

      dispatchId
      dispatch {
        id
        sid
        date
        totalMinutesWorked
        countInstallerAssignmentsCompleted

        workOrderId
        workOrder {
          id
          name
          tripName
          isConfirmed
          isWorkCompleted
          estimateId
          estimateTitle
          isDavisBacon
          jobId
          jobTitle
          customerName
          projectSiteAddress
        }
      }
    }
  }
`)
