import { Route, Routes } from 'react-router'

import { PageLayout } from '@atlaskit/page-layout'

import AppTopNavigation from './components/AppTopNavigation'
import useWatchModifierKeys from './utils/useWatchModifierKeys'

import CalendarPage from './pages/calendar/CalendarPage'
import CustomerPage from './pages/customers/Customer/CustomerPage'
import CustomersList from './pages/customers/CustomersList/CustomersList'
import DispatchPage from './pages/dispatch/DispatchPage/DispatchPage'
import LoadSheetPrint from './pages/dispatch/LoadSheetPrint/LoadSheetPrint'
import EstimateEditorPage from './pages/jobs/EstimateEditor/EstimateEditorPage'
import JobPage from './pages/jobs/JobPage/JobPage'
import JobsList from './pages/jobs/JobsList/JobsList'
import WorkOrderView from './pages/jobs/WorkOrderView/WorkOrderView'
import MaterialsList from './pages/materials/MaterialsList'
import ReconRouter from './pages/recon/ReconRouter'

const AuthRouter = () => {
  useWatchModifierKeys()
  return (
    <div style={{ height: '100vh', maxWidth: '100vw', overflow: 'hidden' }}>
      <PageLayout>
        <AppTopNavigation />

        <Routes>
          <Route path='/calendar/:firstWeekDate?/:lastWeekDate?' element={<CalendarPage />} />

          <Route path='materials'>
            <Route index element={<MaterialsList />} />

            <Route path=':materialId' element={<MaterialsList />} />
          </Route>

          <Route path='jobs'>
            <Route index element={<JobsList />} />

            <Route path='/jobs/:jobId/estimates/:estimateId' element={<EstimateEditorPage />} />
            <Route path='/jobs/:jobId/*' element={<JobPage />} />
          </Route>

          <Route path='customers'>
            <Route index element={<CustomersList />} />

            <Route path=':customerId'>
              <Route index element={<CustomerPage />} />
            </Route>
          </Route>

          <Route path='/work-order/:workOrderId/:dateString?' element={<WorkOrderView />} />
          <Route path='/load-sheet/:dateString/:vehicleId' element={<LoadSheetPrint />} />
          <Route path='/dispatch/:viewMode?/:dateString?' element={<DispatchPage />} />

          <Route path='recon/*' element={<ReconRouter />} />
        </Routes>
      </PageLayout>
    </div>
  )
}

export default AuthRouter
