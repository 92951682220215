import { Temporal } from '@js-temporal/polyfill'
import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router'

import { getMondayDateFromWeekId } from '@/utils/utilities'

import { InstallerDatesLookupKey, PayrollValueSelectorFn } from '../reconPayrollTypes'

export interface ColumnDef {
  id: string
  header: string
  field?: string
  accessor: PayrollValueSelectorFn
  useNumberCell?: boolean
  dayIndex?: InstallerDatesLookupKey
}

interface ColumnGroup {
  groupHeader: string
  columns: ColumnDef[]
}

type FormatDayHeaderFn = (dayOffset: number) => string

const formatDayHeaderFn = ({ firstDayOfWeek, dayOffset }: { firstDayOfWeek: Temporal.PlainDate; dayOffset: number }) => {
  const date = firstDayOfWeek.add({ days: dayOffset })
  return `${date.toLocaleString('en-US', { weekday: 'long' })}, ${date.toLocaleString('en-US', {
    month: 'short',
  })} ${date.day}`
}

const useTimesheetColumnDefs = () => {
  const [searchParams] = useSearchParams()

  const weekId = useMemo(() => {
    const today = Temporal.Now.plainDateISO()
    const todayWeekId = `${today.yearOfWeek}-${today.weekOfYear}`
    return searchParams.get('weekId') ?? todayWeekId
  }, [searchParams])

  const firstDayOfWeek = useMemo(() => getMondayDateFromWeekId(weekId), [weekId])
  const formatDayHeader: FormatDayHeaderFn = useCallback(dayOffset => formatDayHeaderFn({ firstDayOfWeek, dayOffset }), [firstDayOfWeek])
  const columnGroups: ColumnGroup[] = useMemo(() => createColumnGroups({ formatDayHeader }), [formatDayHeader])

  return columnGroups
}

export default useTimesheetColumnDefs

interface CreateColumnGroupsProps {
  formatDayHeader: FormatDayHeaderFn
}

const createColumnGroups = ({ formatDayHeader }: CreateColumnGroupsProps): ColumnGroup[] => [
  {
    groupHeader: '',
    columns: [{ id: 'installer', header: 'Installer', accessor: row => row.installer.fullName }],
  },
  ...Array.from({ length: 7 }, (_, i) => {
    const dayKey = `${i + 1}` as InstallerDatesLookupKey
    const dateColumns: ColumnDef[] = [
      {
        id: `${dayKey}Drive`,
        header: 'Drive',
        accessor: row => {
          const minutes = (row[dayKey]?.dispatchesMinutesDrive ?? 0) as number
          return minutes
        },
        useNumberCell: true,
      },
      {
        id: `${dayKey}Work`,
        header: 'Work',
        accessor: row => {
          const minutes = (row[dayKey]?.dispatchesMinutesWorked ?? 0) as number
          return minutes
        },
        useNumberCell: true,
      },
      {
        id: `${dayKey}Dispatch`,
        header: 'Dispatch',
        accessor: row => {
          const minutes = (row[dayKey]?.dispatchesMinutesTotal ?? 0) as number
          return minutes
        },
        useNumberCell: true,
      },
      {
        id: `${dayKey}NonDispatch`,
        header: 'Non-Disp',
        field: 'minutesNonDispatch',
        accessor: row => {
          const minutes = (row[dayKey]?.minutesNonDispatch ?? 0) as number
          return minutes
        },
        dayIndex: `${i + 1}` as InstallerDatesLookupKey,
      },
      {
        id: `${dayKey}Actual`,
        header: 'Actual',
        field: 'minutesActual',
        accessor: row => {
          const minutes = (row[dayKey]?.minutesActual ?? 0) as number
          return minutes
        },
        dayIndex: `${i + 1}` as InstallerDatesLookupKey,
      },
      {
        id: `${dayKey}Lunch`,
        header: 'Lunch',
        accessor: row => {
          const minutes = (row[dayKey]?.minutesLunch ?? 0) as number
          return minutes
        },
        useNumberCell: true,
      },
      {
        id: `${dayKey}Payable`,
        header: 'Payable',
        accessor: row => {
          const minutes = (row[dayKey]?.minutesPayable ?? 0) as number
          return minutes
        },
        useNumberCell: true,
      },
    ]

    return {
      groupHeader: formatDayHeader(i),
      columns: dateColumns,
    }
  }),
  {
    groupHeader: 'Week Subtotals',
    columns: [
      {
        id: 'weekMinutesSubtotal',
        header: 'Subtotal',
        accessor: row => row.installerWeeklyPayroll?.totalDayMinutesPayable ?? 0,
        useNumberCell: true,
      },
      {
        id: 'weekMinutesAdjustmentLunch',
        header: 'Lunch Adj.',
        accessor: row => row.installerWeeklyPayroll?.totalDayMinutesLunch ?? 0,
        useNumberCell: true,
      },
    ],
  },
  {
    groupHeader: 'Week Totals',
    columns: [
      {
        id: 'minutesAdjustmentManual',
        header: 'Manual',
        accessor: row => row.installerWeeklyPayroll?.minutesAdjustmentManual ?? 0,
        useNumberCell: true,
      },
      {
        id: 'weekMinutesTotal',
        header: 'Total',
        accessor: row => row.installerWeeklyPayroll?.minutesTotal ?? 0,
        useNumberCell: true,
      },
      {
        id: 'weekMinutesOvertime',
        header: 'Overtime',
        accessor: row => row.installerWeeklyPayroll?.minutesOvertime ?? 0,
        useNumberCell: true,
      },
    ],
  },
]
