// ui/src/components/MiniCalendarNav/DayCell.tsx
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'
import Tooltip from '@atlaskit/tooltip'

import {
  DAY_CELL_PADDING_BOTTOM,
  DAY_CELL_PADDING_TOP,
  DAY_NUMBER_DIV_HEIGHT,
  INDICATOR_DOTS_DIV_HEIGHT,
  useDateProps,
  useDateSelector,
} from './atoms'
import { CalendarDate, CalendarWeek, SelectionMode } from './calendarTypes'
import IndicatorDot, { IndicatorDotTooltipRow } from './IndicatorDot'

interface DayCellProps {
  date: CalendarDate
  week: CalendarWeek
  selectionMode: SelectionMode
  isWeekSelected: boolean
}

const DayCell: React.FC<DayCellProps> = ({ date, week, selectionMode, isWeekSelected }) => {
  const selectDate = useDateSelector({ selectionMode })
  const { indicators, isSelected, isInDisplayMonth, isToday } = useDateProps(date)
  const { dayNumber } = date
  const indicatorDots = useMemo(() => {
    if (!indicators || indicators.length === 0) return null
    return (
      <IndicatorDots>
        {indicators.map((ind, idx) => (
          <IndicatorDot key={idx} color={ind.color} />
        ))}
      </IndicatorDots>
    )
  }, [indicators])

  const tooltipContent = useMemo(() => {
    if (!indicators || indicators.length === 0) return null
    return (
      <div>
        {indicators.map((ind, idx) => (
          <TooltipRow key={idx}>
            <IndicatorDotTooltipRow value={ind.tooltipNumber} label={ind.tooltipLabel} color={ind.color} />
          </TooltipRow>
        ))}
      </div>
    )
  }, [indicators])

  return (
    <Tooltip content={tooltipContent} delay={500} hideTooltipOnClick>
      <DayCellWrapper
        $isInDisplayMonth={isInDisplayMonth}
        $isToday={isToday}
        $isSelected={isSelected && !isWeekSelected}
        $isWeekSelected={isWeekSelected}
        onClick={handleClick}
        $selectionMode={selectionMode}
      >
        <div className='mini-cal-day-cell-day-number'>{dayNumber}</div>
        <div className='mini-cal-day-cell-indicator-dots'>{indicatorDots}</div>
      </DayCellWrapper>
    </Tooltip>
  )

  function handleClick() {
    selectDate({ date, week })
  }
}

export default DayCell

interface DayCellWrapperProps {
  $isInDisplayMonth: boolean
  $isToday: boolean
  $isSelected: boolean
  $selectionMode: SelectionMode
  $isWeekSelected: boolean
}

const DayCellWrapper = styled.div<DayCellWrapperProps>`
  position: relative;
  padding: ${DAY_CELL_PADDING_TOP}px 0 ${DAY_CELL_PADDING_BOTTOM}px 0;

  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  border-radius: 4px;
  border: 1px solid transparent;

  cursor: pointer;
  user-select: none;

  color: ${({ $isInDisplayMonth }) => ($isInDisplayMonth ? token('color.text') : token('color.text.disabled'))};

  background: ${({ $isSelected, $isWeekSelected, $isToday }) => {
    if ($isWeekSelected) return $isToday ? token('color.background.discovery.hovered') : 'transparent'
    if ($isSelected) return token('color.background.selected')
    if ($isToday) return token('color.background.discovery')
    return 'transparent'
  }};

  &:hover {
    background: ${({ $isSelected }) => {
      if ($isSelected) return token('color.background.selected.hovered')
      return token('color.background.neutral.subtle.hovered')
    }};
  }

  ${({ $isToday, $isWeekSelected }) =>
    $isToday &&
    `
    font-weight: 700;
    color: ${token('color.text.discovery')};
  `}

  ${({ $isSelected }) =>
    $isSelected &&
    `
    font-weight: 700;
    color: ${token('color.text.selected')};
    border-color: ${token('color.border.selected')};
  `}

  transition: background 0.2s ease, border-color 0.2s ease, color 0.2s ease;

  .mini-cal-day-cell-day-number {
    transform: ${({ $isSelected }) => ($isSelected ? 'scale(1.1)' : 'scale(1)')};
    transform-origin: center;
    transition: transform 0.12s cubic-bezier(0.4, 0, 0.2, 1);
    flex: 0 0 auto;
    height: ${DAY_NUMBER_DIV_HEIGHT}px;
  }
  .mini-cal-day-cell-indicator-dots {
    height: ${INDICATOR_DOTS_DIV_HEIGHT}px;
    min-height: ${INDICATOR_DOTS_DIV_HEIGHT}px;
    max-height: ${INDICATOR_DOTS_DIV_HEIGHT}px;
  }
`

const IndicatorDots = styled.div`
  height: ${INDICATOR_DOTS_DIV_HEIGHT}px;
  display: flex;
  gap: 3px;
  justify-content: center;
  align-items: center;
`

const TooltipRow = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`
