import Button from '@atlaskit/button/new'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'
import useGraphQLClient from '@/utils/useAuthRequest'

import { GetDispatchPayoutDetailsQuery, SubmitDispatchPayoutRecalcMutationVariables } from '@/gql/graphql'

import { graphql } from '@/gql'

import HrMinCell from '@/components/HrMinCell'
import NumberCell from '@/components/NumberCell'

import InstallersTableRow from './InstallersTableRow'

type WorkOrder = GetDispatchPayoutDetailsQuery['workOrder']
type Dispatch = WorkOrder['dispatches'][number]

interface InstallerTableProps {
  dispatch: Dispatch
  isDavisBacon: boolean
}
const CELL_PAD = '4px 8px'

const InstallerTable = ({ dispatch, isDavisBacon }: InstallerTableProps) => {
  const installerAssignments = dispatch.installerAssignments.sort((a, b) => {
    // First sort by vehicle name
    const vehicleCompare = (a.vehicleName ?? '').localeCompare(b.vehicleName ?? '')
    if (vehicleCompare !== 0) return vehicleCompare

    // Then sort by installer name
    return a.installer.fullName.localeCompare(b.installer.fullName)
  })

  const graphQLClient = useGraphQLClient()
  const queryClient = useQueryClient()

  const { mutate, isPending } = useMutation({
    mutationFn: async (variables: SubmitDispatchPayoutRecalcMutationVariables) => {
      const response = await graphQLClient.request(SUBMIT_DISPATCH_PAYOUT_RECALC, variables)
      if (!response.submitDispatchPayoutRecalc?.success)
        throw new Error(response.submitDispatchPayoutRecalc?.message ?? 'Failed to recalculate payout')
      return response.submitDispatchPayoutRecalc
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['GetDispatchPayoutDetails'] })
    },
  })

  const showAddonsToggle = installerAssignments.some(
    assignment => (assignment.isHourlyPayout || assignment.isDavisBacon) && assignment.calculatedPayoutAddons
  )

  const areAllPercentLocked = installerAssignments.every(assignment => assignment.isPercentLocked)
  const totalPrGap = installerAssignments.reduce((acc, assignment) => acc + Number(assignment.payoutPieceRateDavisBaconGap ?? 0), 0)
  const showPrGap = totalPrGap > 0
  console.log('showPrGap', totalPrGap)
  const areAllDistributedEvenly = useMemo(
    () =>
      installerAssignments
        .filter(assignment => !assignment.isPercentLocked)
        .every((assignment, _, arr) => {
          if (arr.length <= 1) return true
          const firstPercent = Number(arr[0].percentPieceRate)
          const currentPercent = Number(assignment.percentPieceRate)
          const diff = Math.abs(firstPercent - currentPercent)
          const isWithinTolerance = diff <= 0.015
          if (!isWithinTolerance) console.log({ firstPercent, currentPercent, diff, assignment })
          return isWithinTolerance
        }),
    [installerAssignments]
  )
  const notHundred = dispatch.percentPieceRateAllocated !== '100.00'
  const recalcEnabled = (!areAllDistributedEvenly || notHundred) && !areAllPercentLocked
  const addCols = (showPrGap ? 1 : 0) + (showAddonsToggle ? 1 : 0)
  return (
    <TableWrapper>
      <StyledTable $showTog={showAddonsToggle} $notHundred={notHundred} $addCols={addCols}>
        <thead>
          <tr>
            <th colSpan={3} style={{ textAlign: 'left', paddingLeft: 8, color: token('color.text.subtlest'), fontSize: 15 }}>
              Installer Assignments
            </th>

            <th colSpan={3}>{isDavisBacon ? 'Davis Bacon' : 'Hourly'}</th>

            <th colSpan={3}>Drive Pay</th>

            <th colSpan={4 + addCols}>Piece Rate</th>

            <th colSpan={3}></th>
          </tr>
          <tr>
            <th>Vehicle</th>
            <th>Installer</th>
            <th>Mode</th>
            {/* HOURLY */}
            <th>On Site</th>
            <th>{isDavisBacon ? 'DB Pay Rate' : 'Pay Rate'}</th>
            <th>{isDavisBacon ? 'DB Payout' : 'Payout'}</th>
            {/* DRIVE PAY */}
            <th>Payable</th>
            <th>Pay Rate</th>
            <th>Payout</th>
            {/* PIECE RATE */}
            <th>% of Work</th>
            <th>Lock %</th>
            <th>PR Payout</th>
            {showPrGap ? <th>PR Gap</th> : null}
            {showAddonsToggle ? <th>Addons</th> : null}
            <th>Addons Payout</th>
            {/* TOTAL */}
            <th>Total Payout</th>
            <th>Complete</th>
            <th>Approved</th>
          </tr>
        </thead>

        <tbody>
          {installerAssignments.map((assignment, index) => {
            const isLastOfVehicle =
              index === installerAssignments.length - 1 || assignment.vehicleName !== installerAssignments[index + 1].vehicleName
            const isFirstOfVehicle = index === 0 || assignment.vehicleName !== installerAssignments[index - 1].vehicleName

            return (
              <InstallersTableRow
                key={assignment.id}
                installerDispatch={assignment}
                isLastOfVehicle={isLastOfVehicle}
                isFirstOfVehicle={isFirstOfVehicle}
                vehicleInstallerCount={installerAssignments.filter(a => a.vehicleName === assignment.vehicleName).length}
                showAddonsToggle={showAddonsToggle}
                showPrGap={showPrGap}
              />
            )
          })}
        </tbody>

        <tfoot>
          <tr>
            <td></td>

            <td>Total Entered</td>

            <td></td>

            <td>
              <HrMinCell value={dispatch.totalMinutesWorked} />
            </td>

            <td style={{ background: token('color.background.disabled') }}></td>

            <td>
              <NumberCell padding={CELL_PAD} readOnly value={dispatch.totalPaidHourly} prefix='$' monoPrefixSuffix />
            </td>

            <td style={{ background: token('color.background.disabled') }}></td>
            <td style={{ background: token('color.background.disabled') }}></td>

            <td>
              <NumberCell padding={CELL_PAD} readOnly value={dispatch.totalPaidDrivePay} prefix='$' monoPrefixSuffix />
            </td>

            <td style={{ position: 'relative' }}>
              {areAllPercentLocked ? null : (
                <div className='recalc-button-overlay'>
                  <Button
                    appearance='subtle'
                    onClick={() => mutate({ dispatchId: dispatch.id })}
                    isDisabled={isPending || areAllPercentLocked}
                    isLoading={isPending}
                    shouldFitContainer
                  >
                    Recalc
                  </Button>
                </div>
              )}

              <NumberCell
                padding={CELL_PAD}
                readOnly
                value={dispatch.percentPieceRateAllocated}
                suffix='%'
                appearance={recalcEnabled ? 'warning' : 'default'}
              />
            </td>

            <td style={{ background: token('color.background.disabled') }}></td>

            <td>
              <NumberCell padding={CELL_PAD} readOnly value={dispatch.totalPaidPieceRate} prefix='$' monoPrefixSuffix />
            </td>

            {showPrGap ? (
              <td>
                <NumberCell padding={CELL_PAD} readOnly value={totalPrGap} prefix='$' monoPrefixSuffix />
              </td>
            ) : null}

            {!showAddonsToggle ? null : <td />}

            <td>
              <NumberCell padding={CELL_PAD} readOnly value={dispatch.totalPaidAddons} prefix='$' monoPrefixSuffix />
            </td>

            <td>
              <NumberCell padding={CELL_PAD} readOnly value={dispatch.totalPaidLaborTotal} prefix='$' monoPrefixSuffix />
            </td>

            <td></td>
            <td></td>
          </tr>
        </tfoot>
      </StyledTable>
    </TableWrapper>
  )
}

const COL_1_VEHICLE = 108
const COL_2_INSTALLER = 140
const COL_3_MODE = 58

const COL_4_ON_SITE = 95
const COL_5_HOURLY_PAY_RATE = 110
const COL_6_HOURLY_PAYOUT = 80
const COL_7_DRIVE_PAYABLE = 95
const COL_8_DRIVE_PAY_RATE = 110
const COL_9_DRIVE_PAYOUT = 80
const COL_10_PERCENT_OF_WORK = 95
const COL_11_LOCK_PERCENT = 80
const COL_12_PR_PAYOUT = 80
const COL_12_5_NO_ADDONS = 80
const COL_12_8_GAP = 80
const COL_13_ADDONS_PAYOUT = 80

const COL_14_TOTAL_PAYOUT = 100
const COL_15_COMPLETE = 80
const COL_16_APPROVED = 80

export default InstallerTable
const TableWrapper = styled.div`
  align-self: stretch;
  max-width: 100%;
  overflow-x: auto;
  position: relative;
  flex: 0 0 auto;
  /* Reserve space for scrollbars so that sticky footer stays flush */
  scrollbar-gutter: stable;

  /* Optional: Style scrollbars on WebKit browsers for a slimmer look */
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    box-sizing: border-box;
    border: 1px solid ${token('color.border')};
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;

    background-color: ${token('elevation.surface.sunken')};
    transition: background-color 0.3s ease;
    &:hover {
      background-color: ${token('color.border')};
    }
  }
  &::-webkit-scrollbar-thumb {
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid ${token('color.border')};

    transition: background-color 0.3s ease;
    background-color: ${token('color.border')};
    &:hover {
      background-color: ${token('color.icon.disabled')};
    }
  }
`
const StyledTable = styled.table<{ $showTog: boolean; $notHundred: boolean; $addCols: number }>`
  width: fit-content;

  /* ALL CELLS */
  td,
  th {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
  }

  td {
    height: 32px;
  }

  /* TOP HEADER ROW */
  thead > tr:first-child {
    background: ${token('color.background.neutral')};
    color: ${token('color.text.disabled')};
    border-top: 1px solid ${token('elevation.surface')};
    * {
      box-sizing: border-box;
    }
    > th {
      text-align: center;
      z-index: 3;

      &:not(:first-child):not(:last-child) {
        border-top-width: 0px;
        z-index: 2;
        box-shadow: inset 0 1px 0 ${token('color.border')};
        border-bottom: 1px solid ${token('color.border')};
      }
      &:not(:first-child):not(:last-child):not(:nth-child(2)) {
        border-left: 3px solid ${token('color.border')};
      }
      &:first-child {
        background: ${token('elevation.surface')};
        position: sticky;
        left: 0px;
        z-index: 4;
        border-right-width: 0px;
        border-left-width: 0px;
        border-top-width: 0px;
        &::after {
          content: '';
          position: absolute;
          right: 0px;
          top: 0;
          bottom: 0;
          border-right: 1.5px solid ${token('color.border')};
          pointer-events: none;
        }
      }
      &:last-child {
        background: ${token('elevation.surface')};
        position: sticky;
        right: 0px;
        border-left-width: 0px;
        border-right-width: 0px;
        border-top-width: 0px;
        z-index: 4;
        &::before {
          content: '';
          position: absolute;
          left: 0px;
          top: 0;
          bottom: 0;
          border-left: 1.5px solid ${token('color.border')};
          pointer-events: none;
        }
      }
    }
  }

  /* BOTTOM HEADER ROW */
  thead > tr:last-child > th {
    background: ${token('color.background.neutral')};
    text-align: right;
    padding-right: 8px;
    padding-left: 8px;
    border-top: 2px solid ${token('color.border')};

    &:nth-child(3) {
      text-align: center;
    }
    &:nth-child(-n + 3),
    &:nth-child(n + ${({ $addCols }) => 14 + $addCols}) {
      background: ${token('elevation.surface.hovered')};
    }
  }

  tr:nth-child(2) > th:nth-child(even) > td:nth-child(n + ${({ $addCols }) => 14 + $addCols}) {
    background-color: ${token('elevation.surface.sunken')};
  }
  tr:nth-child(even) > td:nth-child(-n + 3),
  tr:nth-child(even) > td:nth-child(n + ${({ $addCols }) => 14 + $addCols}) {
    background-color: ${token('elevation.surface.sunken')};
  }
  tr:nth-child(odd) > td:nth-child(-n + 3),
  tr:nth-child(odd) > td:nth-child(n + ${({ $addCols }) => 14 + $addCols}) {
    background-color: ${token('color.background.input')};
  }

  tbody > tr:nth-child(even) {
    background-color: ${token('elevation.surface.sunken')};
  }

  /* ALL CELLS NOT IN TOP HEADER ROW */
  td,
  thead > tr:last-child > th {
    z-index: 1;

    &:not(:first-child) {
      border-left: 1px solid ${token('color.border')};
    }

    &:last-child {
      box-shadow: inset -1px 0px 0 ${token('color.border')};
    }
    &:first-child {
      box-shadow: inset 1px 0px 0 ${token('color.border')};
    }

    &:nth-child(-n + 3) {
      position: sticky;
      z-index: 4;

      padding-left: 8px;
      padding-right: 8px;
      font-family: ${token('font.family.body')};
      border-color: ${token('elevation.surface.raised.pressed')};
      border-right-width: 0px;
      border-left-width: 0px;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        border-right: 1px solid ${token('color.border')};
        pointer-events: none;
      }
    }
    &:nth-child(n + ${({ $addCols }) => 14 + $addCols}) {
      position: sticky;
      z-index: 4;

      border-left-width: 0px;
      border-right-width: 0px;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        border-left: 1px solid ${token('color.border')};
        pointer-events: none;
      }
    }
    &:nth-child(1) {
      /* Vehicle */
      text-align: left;

      width: ${COL_1_VEHICLE}px;
      min-width: ${COL_1_VEHICLE}px;
      max-width: ${COL_1_VEHICLE}px;

      left: 0px;

      &.vehicle-name-cell {
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
      }
    }
    &:nth-child(2) {
      /* Installer */
      text-align: left;

      width: ${COL_2_INSTALLER}px;
      min-width: ${COL_2_INSTALLER}px;
      max-width: ${COL_2_INSTALLER}px;

      left: ${COL_1_VEHICLE}px;
    }
    &:nth-child(3) {
      /* Mode */
      width: ${COL_3_MODE}px;
      min-width: ${COL_3_MODE}px;
      max-width: ${COL_3_MODE}px;

      left: ${COL_1_VEHICLE + COL_2_INSTALLER}px;
    }
    &:nth-child(4) {
      /* On Site */
      width: ${COL_4_ON_SITE}px;
      min-width: ${COL_4_ON_SITE}px;
    }
    &:nth-child(5) {
      /* Hourly Pay Rate */
      width: ${COL_5_HOURLY_PAY_RATE}px;
      min-width: ${COL_5_HOURLY_PAY_RATE}px;
    }
    &:nth-child(6) {
      /* Payout */
      min-width: ${COL_6_HOURLY_PAYOUT}px;
      width: ${COL_6_HOURLY_PAYOUT}px;
    }
    &:nth-child(7) {
      /* Drive Payable */
      width: ${COL_7_DRIVE_PAYABLE}px;
      min-width: ${COL_7_DRIVE_PAYABLE}px;
      border-left-width: 3px;
    }
    &:nth-child(8) {
      /* Drive Pay Rate */
      width: ${COL_8_DRIVE_PAY_RATE}px;
      min-width: ${COL_8_DRIVE_PAY_RATE}px;
    }
    &:nth-child(9) {
      /* Drive Payout */
      min-width: ${COL_9_DRIVE_PAYOUT}px;
      width: ${COL_9_DRIVE_PAYOUT}px;
    }
    &:nth-child(10) {
      /* Percent of Work */
      width: ${COL_10_PERCENT_OF_WORK}px;
      min-width: ${COL_10_PERCENT_OF_WORK}px;
      border-left-width: 3px;
    }
    &:nth-child(11) {
      /* Lock Percent */
      width: ${COL_11_LOCK_PERCENT}px;
      min-width: ${COL_11_LOCK_PERCENT}px;
    }
    ${({ $showTog }) =>
      $showTog
        ? `
       &:nth-child(12) {
      /* Addons Toggle */
      min-width: ${COL_12_5_NO_ADDONS}px;
      width: ${COL_12_5_NO_ADDONS}px;
    }`
        : ''}
    ${({ $showTog, $addCols }) =>
      $showTog && $addCols === 2
        ? `
       &:nth-child(13) {
      /* PR Gap */
      min-width: ${COL_12_8_GAP}px;
      width: ${COL_12_8_GAP}px;
    }`
        : ''}
    &:nth-child(${({ $addCols }) => 12 + $addCols}) {
      /* Addons Payout */
      min-width: ${COL_12_PR_PAYOUT}px;
      width: ${COL_12_PR_PAYOUT}px;
    }
    &:nth-child(${({ $addCols }) => 13 + $addCols}) {
      /* Piece Rate Payout */
      width: ${COL_13_ADDONS_PAYOUT}px;
      min-width: ${COL_13_ADDONS_PAYOUT}px;
    }
    &:nth-child(${({ $addCols }) => 14 + $addCols}) {
      /* Total Payout */
      width: ${COL_14_TOTAL_PAYOUT}px;
      min-width: ${COL_14_TOTAL_PAYOUT}px;
      right: ${COL_16_APPROVED + COL_15_COMPLETE + 2.5}px;
      font-weight: 600;
    }
    &:nth-child(${({ $addCols }) => 15 + $addCols}) {
      /* Complete */
      width: ${COL_15_COMPLETE}px;
      min-width: ${COL_15_COMPLETE}px;
      right: ${COL_16_APPROVED + 1.5}px;
      font-weight: 600;
    }
    &:nth-child(${({ $addCols }) => 16 + $addCols}) {
      /* Approved */
      width: ${COL_16_APPROVED}px;
      min-width: ${COL_16_APPROVED}px;
      font-weight: 600;
      right: 0px;
    }
  }

  tbody > tr > td {
    border-bottom: 1px solid ${token('color.border')};

    > label {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  /* NUMERIC CELLS */
  tr > td:not(:nth-child(-n + 3)):not(:nth-child(n + ${({ $addCols }) => 15 + $addCols})) {
    font-family: ${MONOSPACE_FONT_FAMILY};
    padding: 0;
    text-align: right;
  }

  /* FOOTER ROW */
  tfoot > tr > td {
    font-weight: 600;

    &:nth-child(2) {
      /* Installer */
      color: ${token('color.text.subtle')};
    }
  }

  /* Making Inputs fill cells */
  td:not(:nth-child(-n + 3)) > div > div {
    max-width: 120px;
    display: flex;
    justify-content: flex-end;
    border-radius: 0;
    /* border-color: ${token('color.border')}; */

    &:not(:focus-within) {
      background: transparent;
    }
    > span {
      color: ${token('color.text.disabled')};
    }

    input {
      /* max-width: 120px; */
      font-family: ${MONOSPACE_FONT_FAMILY};
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }

  .recalc-button-overlay {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    background: ${({ $notHundred }) => ($notHundred ? token('color.background.warning') : token('color.background.success'))};
    opacity: 0;
    &:hover {
      opacity: 1;
    }
  }

  .row-header {
    font-family: ${token('font.family.body')};
    font-weight: 500;
    padding: 0 8px;
  }
  .checkbox-wrapper {
    padding: 0 8px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    > label {
      transform-origin: center;
      transform: scale(1.33);
    }
  }
`
const SUBMIT_DISPATCH_PAYOUT_RECALC = graphql(/* GraphQL */ `
  mutation SubmitDispatchPayoutRecalc($dispatchId: ID!) {
    submitDispatchPayoutRecalc(dispatchId: $dispatchId) {
      success
      message
    }
  }
`)
