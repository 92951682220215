import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import PickupTruckSvg from '@/components/PickupTruckSvg'
import TruckIcon from '@/components/TruckIcon'

import type { Vehicle } from '@/utils/queryHooks/useVehiclesQuery'
import { type CardColorOptions } from '@/utils/utilities'

interface VehicleContainerHeaderProps {
  vehicle: Vehicle
  color: CardColorOptions
}

const VehicleContainerHeader = ({ vehicle, color }: VehicleContainerHeaderProps) => {
  return (
    <VehicleContainerHeaderCard $color={color}>
      <div className='vehicle-header-section-left'>
        <div className='vehicle-icon'>
          {vehicle.name.includes('150') ? (
            <PickupTruckSvg color={token('color.icon.subtle')} size={32} />
          ) : (
            <TruckIcon color={token('color.icon.subtle')} size={32} />
          )}
        </div>

        <div className='vehicle-card-info'>
          <div className='vehicle-card-title'>{vehicle.name}</div>
        </div>
      </div>

      <div className='vehicle-header-section-right'></div>
    </VehicleContainerHeaderCard>
  )
}

export default VehicleContainerHeader

interface VehicleContainerHeaderCardProps {
  $color: CardColorOptions
}

const VehicleContainerHeaderCard = styled.div<VehicleContainerHeaderCardProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 4px;
  padding-right: 12px;

  background: ${({ $color }) => (!$color ? token('elevation.surface') : token(`color.background.accent.${$color}.subtlest`))};
  border-bottom: 1px solid ${({ $color }) => (!$color ? token('color.border') : token(`color.border.accent.${$color}`))};
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  .vehicle-header-section-left {
    display: flex;
    align-items: center;
  }
  .vehicle-header-section-right {
    display: flex;
    align-items: center;
    gap: 12px;
    opacity: 0.5;
    transition: opacity 500ms;
    &:hover {
      opacity: 1;
    }
  }

  .vehicle-icon {
    padding: 4px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    svg {
      stroke: ${({ $color }) => (!$color ? token('color.icon.subtle') : token(`color.text.accent.${$color}`))};
      opacity: 0.8;
    }
  }

  .vehicle-card-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 4px 12px 4px 0;
    z-index: 1;

    .vehicle-card-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      color: ${({ $color }) => (!$color ? token('elevation.surface') : token(`color.text.accent.${$color}`))};
      opacity: 0.8;
    }
  }
`
