import { useMemo } from 'react'

import useStaffListQuery, { Staff } from '@/utils/queryHooks/useStaffListQuery'

const useInstallersList = () => {
  const { staffList, status, error } = useStaffListQuery()
  const installers = useMemo(() => filterSortInstallers(staffList), [staffList])
  const isLoading = useMemo(() => status === 'pending', [status])
  return { installers, status, error, isLoading }
}

export default useInstallersList

function filterSortInstallers(installers: Staff[]) {
  return installers
    .filter(staff => staff.isInstaller)
    .sort((a, b) => {
      if (a.isHourly !== b.isHourly) return a.isHourly ? -1 : 1
      if (a.lastName !== b.lastName) return a.lastName.localeCompare(b.lastName)
      return a.firstName.localeCompare(b.firstName)
    })
}
