import { graphql } from '@/gql'

export const GET_CUSTOMERS_QUERY_KEY = 'GetCustomers'
const GET_CUSTOMERS_QUERY = graphql(/* GraphQL */ `
  query GetCustomers {
    customers {
      id
      sid
      name
      isBusiness
      businessPhone
      createdAt

      createdBy {
        id
        sid
        fullName
      }

      locations {
        place {
          id
          city
          state
        }
      }
    }
  }
`)

export default GET_CUSTOMERS_QUERY
