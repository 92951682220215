import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Header } from '@tanstack/react-table'
import { useParams } from 'react-router'

import Button from '@atlaskit/button/new'

import { graphql } from '@/gql'
import { CreateWorkItemMutationVariables } from '@/gql/graphql'
import useGraphQLClient from '@/utils/useAuthRequest'

import { getCommonPinningStyles } from '../editorConstants'
import { QueriedEstimateWorkItem } from '../editorTypes'

import { GET_ESTIMATE_QUERY_KEY } from '../hooks/useEditorData'

interface AddNewRowProps {
  headers: Header<QueriedEstimateWorkItem, unknown>[]
}

const AddNewRow = ({ headers }: AddNewRowProps) => {
  const params = useParams()
  const estimateId = params.estimateId

  const graphQLClient = useGraphQLClient()
  const queryClient = useQueryClient()

  const { mutate: createWorkItem, isPending } = useMutation({
    mutationFn: async (variables: CreateWorkItemMutationVariables) => {
      console.log('useNewWorkItemWatcher: Creating work item: ', variables)
      const result = await graphQLClient.request(CREATE_WORK_ITEM, variables)
      if (result.createWorkItem.success) return result
      throw new Error(result.createWorkItem.message)
    },
    onSuccess: result => {
      console.log('Successfully created work item: ', result)
      queryClient.invalidateQueries({ queryKey: [GET_ESTIMATE_QUERY_KEY] })
    },
    onError: error => console.error('Failed to create work item: ', error),
  })

  function handleAddNewItem() {
    if (estimateId) createWorkItem({ estimateId })
  }

  const workAreaColumnIndex = headers.findIndex(header => header.column.id === 'workAreaId')
  return (
    <tr id={`row-extra`} className='tr'>
      {headers.map((cell, index) =>
        [workAreaColumnIndex + 1, workAreaColumnIndex + 2].includes(index) ? null : (
          <td
            key={cell.id}
            className={`extra-row-cell td`}
            style={{ ...getCommonPinningStyles(cell.column), borderColor: 'transparent', width: cell.column.getSize() }}
          >
            {index !== workAreaColumnIndex ? null : (
              <Button appearance='subtle' shouldFitContainer onClick={handleAddNewItem} isLoading={isPending}>
                Add Work Item
              </Button>
            )}
          </td>
        )
      )}
    </tr>
  )
}

export default AddNewRow

const CREATE_WORK_ITEM = graphql(/* GraphQL */ `
  mutation CreateWorkItem(
    $tripId: Int
    $workAreaId: Int
    $estimateId: UUID!
    $materialId: UUID
    $quantity: Decimal
    $isVisibleOnQuote: Boolean
    $isVisibleOnWorkOrder: Boolean
    $materialCostScheduleId: UUID
  ) {
    createWorkItem(
      tripId: $tripId
      workAreaId: $workAreaId
      estimateId: $estimateId
      materialId: $materialId
      quantity: $quantity
      isVisibleOnQuote: $isVisibleOnQuote
      isVisibleOnWorkOrder: $isVisibleOnWorkOrder
      materialCostScheduleId: $materialCostScheduleId
    ) {
      success
      message
    }
  }
`)
