import styled from 'styled-components'

import Button from '@atlaskit/button/new'
import { token } from '@atlaskit/tokens'
import Tooltip from '@atlaskit/tooltip'

interface PayoutModeCellProps {
  isDavisBacon?: boolean | null
  isHourlyPayout?: boolean | null
  onClick: () => void
  isCompleted?: boolean
}

const PayoutModeCell = ({ isDavisBacon, isHourlyPayout, isCompleted, onClick }: PayoutModeCellProps) => {
  let className = 'payout-mode-button-'
  if (isDavisBacon) className += 'davis-bacon'
  else if (isHourlyPayout) className += 'hourly'
  else className += 'piece-rate'
  return (
    <ButtonWrapper className={className}>
      <Tooltip content={isCompleted ? 'Recon Completed' : isHourlyPayout ? 'Switch to Piece Rate payout' : 'Switch to Hourly payout'}>
        <Button appearance='subtle' onClick={onClick} shouldFitContainer isDisabled={isCompleted}>
          {isDavisBacon ? 'DB' : isHourlyPayout ? 'Hr' : 'P'}
        </Button>
      </Tooltip>
    </ButtonWrapper>
  )
}

export default PayoutModeCell

const ButtonWrapper = styled.div`
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: stretch;
  position: absolute;
  inset: 0;
  div > button {
    position: absolute;
    inset: 0;
    border-radius: 0;
    span {
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  &.payout-mode-button-davis-bacon {
    background: ${token('color.background.information')};
    color: ${token('color.text.information')};
  }
  &.payout-mode-button-hourly {
    background: ${token('color.background.discovery')};
    &:hover {
      background: ${token('color.background.discovery.hovered')};
    }
    &:active {
      background: ${token('color.background.discovery.pressed')};
    }
    span {
      color: ${token('color.text.discovery')} !important;
    }
  }
  &.payout-mode-button-piece-rate {
    background: ${token('color.background.warning')};
    &:hover {
      background: ${token('color.background.warning.hovered')};
    }
    &:active {
      background: ${token('color.background.warning.pressed')};
    }
    span {
      color: ${token('color.text.warning')} !important;
    }
  }
`
