import { useQuery } from '@tanstack/react-query'
import { useAtom, useSetAtom } from 'jotai'

import {
  accessTokenAtom,
  accessTokenExpirationAtom,
  isRefreshingTokensAtom,
  refreshTokenAtom,
  refreshTokenExpirationAtom,
} from '@/utils/appAtoms'
import useGraphQLClient, { REFRESH_TOKEN_MUTATION } from '@/utils/useAuthRequest'
import useCurrentUser from '@/utils/useCurrentUser'

const useRefreshToken = () => {
  const setIsRefreshing = useSetAtom(isRefreshingTokensAtom)

  const setAccessToken = useSetAtom(accessTokenAtom)
  const setAccessTokenExpiration = useSetAtom(accessTokenExpirationAtom)
  const [refreshToken, setRefreshToken] = useAtom(refreshTokenAtom)
  const setRefreshTokenExpiration = useSetAtom(refreshTokenExpirationAtom)

  const graphQLClient = useGraphQLClient()

  useCurrentUser()
  const { isPending } = useQuery({
    queryKey: ['refreshToken', { refreshToken }],
    queryFn: async () => {
      setIsRefreshing(true)
      console.log('useRefreshToken: Refreshing token')
      const response = await graphQLClient.request(REFRESH_TOKEN_MUTATION, { refreshToken })

      if (
        response.refreshToken.success &&
        response.refreshToken?.refreshToken?.token &&
        response.refreshToken?.refreshToken?.expiresAt &&
        response.refreshToken?.token?.payload?.exp &&
        response.refreshToken?.token?.token
      ) {
        console.log('useRefreshToken: Received refresh success: ', response.refreshToken)
        setRefreshToken(response.refreshToken.refreshToken.token)
        setRefreshTokenExpiration(new Date(response.refreshToken.refreshToken.expiresAt).toISOString())
        setAccessTokenExpiration(new Date(response.refreshToken.token.payload.exp).toISOString())
        setAccessToken(response.refreshToken.token.token)
        setIsRefreshing(false)
        return response
      } else {
        console.error('Did not get expected refresh response.', response)
        setIsRefreshing(false)
        throw new Error('Failed to refresh token')
      }
    },
    staleTime: 1000 * 60 * 220, // 220 minutes, 20 less than expiration
    refetchInterval: 1000 * 60 * 60, // 1 hour
    refetchIntervalInBackground: true,
  })
  return { refreshToken, isPending }
}
export default useRefreshToken
