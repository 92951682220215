import * as Sentry from '@sentry/react'
import { QueryCache, QueryClient } from '@tanstack/react-query'

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: error => {
        // Log mutation errors to Sentry
        if (error instanceof Error) {
          Sentry.captureException(error, {
            level: 'error',
            tags: {
              type: 'mutation',
            },
          })
        }
      },
    },
  },
  queryCache: new QueryCache({
    onError: error => {
      // Log query errors globally
      if (error instanceof Error) {
        Sentry.captureException(error, {
          level: 'error',
          tags: {
            type: 'query',
          },
        })
      }
      console.error('QueryCache error: ', error)
    },
  }),
})
