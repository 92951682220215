import { useMutation, useQueryClient } from '@tanstack/react-query'
import React from 'react'

import Button, { IconButton } from '@atlaskit/button/new'
import CrossIcon from '@atlaskit/icon/glyph/cross'
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog'
import { Flex, Grid, xcss } from '@atlaskit/primitives'
import { token } from '@atlaskit/tokens'

import { graphql } from '@/gql'
import { DeleteWorkOrderMutationVariables, GetJobQuery } from '@/gql/graphql'
import useGraphQLClient from '@/utils/useAuthRequest'

import { GET_JOB_QUERY_KEY } from './JobPage'

const gridStyles = xcss({
  width: '100%',
})

const closeContainerStyles = xcss({
  gridArea: 'close',
})

const titleContainerStyles = xcss({
  gridArea: 'title',
})
export type JobQueryWorkOrderType = GetJobQuery['job']['estimates'][number]['workOrders'][number]
interface DeleteWorkOrderModalProps {
  workOrder: JobQueryWorkOrderType
  onClose: () => void
  isOpen: boolean
}

const DeleteWorkOrderModal: React.FC<DeleteWorkOrderModalProps> = ({ workOrder, isOpen, onClose }) => {
  const graphQLClient = useGraphQLClient()
  const queryClient = useQueryClient()

  const { mutate: deleteWorkOrder, isPending } = useMutation({
    mutationFn: async (variables: DeleteWorkOrderMutationVariables) => {
      const result = await graphQLClient.request(DELETE_WORK_ORDER_MUTATION, variables)
      if (!result.deleteWorkOrder.success) {
        console.error(result.deleteWorkOrder.message)
        throw new Error(result.deleteWorkOrder.message)
      }
      return result
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [GET_JOB_QUERY_KEY] })
    },
  })
  const hasChildren = Boolean(workOrder.dispatches)
  return (
    <ModalTransition>
      {isOpen ? (
        <Modal onClose={onClose}>
          <ModalHeader>
            <Grid gap='space.200' templateAreas={['title close']} xcss={gridStyles}>
              <Flex xcss={closeContainerStyles} justifyContent='end'>
                <IconButton appearance='subtle' icon={CrossIcon} label='Close Modal' onClick={onClose} />
              </Flex>
              <Flex xcss={titleContainerStyles} justifyContent='start'>
                <ModalTitle appearance='danger'>You’re about to delete the work order {workOrder.name}</ModalTitle>
              </Flex>
            </Grid>
          </ModalHeader>

          <ModalBody>
            <p>If you continue, you will delete it permanently{!hasChildren ? '.' : ', along with the following:'}</p>
            {hasChildren && (
              <ul>
                {workOrder.dispatches === null ? null : (
                  <li>
                    {workOrder.dispatches.length} Dispatches:
                    <ul>
                      {workOrder.dispatches.map(dispatch => (
                        <li>
                          {dispatch.date}&nbsp;&nbsp;
                          {workOrder.dispatches === null ? (
                            <span style={{ color: token('color.text.disabled') }}>Unscheduled, </span>
                          ) : (
                            <span style={{ color: token('color.text.accent.yellow'), fontWeight: 'bold' }}>Dispatched, </span>
                          )}
                          {workOrder.isConfirmed ? (
                            <span style={{ color: token('color.text.danger'), fontWeight: 'bold' }}>Confirmed</span>
                          ) : (
                            <span style={{ color: token('color.text.disabled') }}>Unconfirmed</span>
                          )}
                        </li>
                      ))}
                    </ul>
                  </li>
                )}
              </ul>
            )}
          </ModalBody>

          <ModalFooter>
            <Button appearance='subtle' onClick={onClose}>
              Cancel
            </Button>
            <Button appearance='danger' isDisabled={isPending} isLoading={isPending} onClick={handleDeleteWorkOrder}>
              Delete
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}
    </ModalTransition>
  )
  function handleDeleteWorkOrder() {
    console.log('delete work order')
    deleteWorkOrder({ workOrderId: workOrder.id })
  }
}

export default DeleteWorkOrderModal

const DELETE_WORK_ORDER_MUTATION = graphql(/* GraphQL */ `
  mutation DeleteWorkOrder($workOrderId: ID!) {
    deleteWorkOrder(workOrderId: $workOrderId) {
      success
      message
    }
  }
`)
