import { useMemo, useState } from 'react'
import styled from 'styled-components'

import Link from '@atlaskit/link'
import { token } from '@atlaskit/tokens'

import { CardColorOptions, COLOR_OPTIONS } from '@/utils/utilities'

import { GetPayrollDayVehiclesQuery, GetVehicleDateQuery } from '@/gql/graphql'

import HoursMinutesInput from '@/components/HoursMinutesInput'
import HrMinCell from '@/components/HrMinCell'
import NumberCell from '@/components/NumberCell'
import TableCellNumberTextField from '@/components/TableCellNumberTextField'

import useSubmitVehicleDateStats from '../useSubmitVehicleDateStats'

import { VEHICLE_RECON_ROW_WIDTHS as ROW_W } from './reconDateConstants'
import VehicleContainerHeader from './VehicleContainerHeader'
import VehicleDispatchRow from './VehicleDispatchRow'

type Vehicle = GetPayrollDayVehiclesQuery['vehiclesByDate'][number]
type VehicleDate = GetVehicleDateQuery['vehicleDate']
type VehicleDispatch = NonNullable<VehicleDate['vehicleDispatchAssignments'][number]>

interface VehicleContainerProps {
  vehicle: Vehicle
  vehicleDate: VehicleDate
}

const VehicleContainer = ({ vehicle, vehicleDate }: VehicleContainerProps) => {
  const [milesToArrive, setMilesToArrive] = useState<string>(vehicleDate?.milesToArriveHome?.toString() ?? '')

  const vehicleDateId = vehicle.vehicleDate?.id
  const vehicleDispatchIds = vehicle.vehicleDate?.sortedVehicleDispatchIds ?? []

  // @ts-expect-error - vehicle.color is not always a CardColorOptions
  const color = (COLOR_OPTIONS.includes(vehicle?.color ?? '') ? vehicle.color : 'gray') as CardColorOptions

  const vehicleDispatchAssignmentsById = (vehicleDate?.vehicleDispatchAssignments ?? []).reduce<Record<string, VehicleDispatch>>(
    (acc, vda) => {
      if (!vda || !vda.id) return acc
      acc[vda.id] = vda
      return acc
    },
    {}
  )
  const isSomeCompleted = vehicleDate?.vehicleDispatchAssignments.some(vda => vda.dispatch?.countInstallerAssignmentsCompleted > 0)
  const { mutate, isPending, data } = useSubmitVehicleDateStats()

  const dbVehicleDate = vehicleDate
  const mutationVehicleDate = data?.submitVehicleDateStats?.vehicleDate
  const vDate = useMemo(() => mutationVehicleDate ?? dbVehicleDate, [mutationVehicleDate, dbVehicleDate])

  return (
    <VehicleContainerCard
      key={vehicle.id}
      $color={color}
      $vehicleDispatchCount={vehicleDispatchIds.length}
      $isLoading={isPending}
      id='vehicle-container-card'
    >
      <VehicleContainerHeader vehicle={vehicle} color={color} />

      <div className='vehicle-body-section'>
        <table>
          <thead>
            <tr>
              <th style={{ width: ROW_W[0] }}></th>
              <th style={{ width: ROW_W[1] + ROW_W[2] }} colSpan={2}>
                Proximity To Shop
              </th>
              <th style={{ width: ROW_W[3] + ROW_W[4] }} colSpan={2}>
                Travel To Site
              </th>
              <th style={{ width: ROW_W[5] }}>On Site</th>
              <th style={{ width: ROW_W[6] + ROW_W[7] + ROW_W[8] }} colSpan={3}>
                Payable Calculations
              </th>
            </tr>

            <tr>
              <th style={{ width: ROW_W[0] }}></th>
              {/* PROXIMITY */}
              <th style={{ width: ROW_W[1] }}>Minutes</th>
              <th style={{ width: ROW_W[2] }}>Percent</th>
              {/* TRAVEL */}
              <th style={{ width: ROW_W[3] }}>Minutes</th>
              <th style={{ width: ROW_W[4] }}>Miles</th>
              {/* ON SITE */}
              <th style={{ width: ROW_W[5] }}>Hours</th>
              {/* BILLABLE */}
              <th style={{ width: ROW_W[6] }}>Miles</th>
              <th style={{ width: ROW_W[7] }}>Minutes</th>
              <th style={{ width: ROW_W[8] }}>Buffer</th>
            </tr>
          </thead>

          <tbody>
            {vehicleDispatchIds.map((vehicleDispatchId, index) => (
              <VehicleDispatchRow
                key={vehicleDispatchId}
                currentIndex={index}
                vehicleDispatch={vehicleDispatchAssignmentsById[vehicleDispatchId]}
                isDisabled={isSomeCompleted}
                mutate={mutate}
              />
            ))}

            <tr>
              <ReturnJobCell>
                <div className='vehicle-dispatch-card-index'>R</div>

                <div className='job-card-info'>
                  <div className='customer-name'>Return Trip</div>

                  <div className='job-site-address'>
                    <Link appearance='subtle' href={'#'} target='_blank' rel='noopener noreferrer'>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                        <span className='erh-address-link'>From Last Job</span>
                      </div>
                    </Link>
                  </div>
                </div>
              </ReturnJobCell>

              <td style={{ width: ROW_W[1], backgroundColor: token('color.background.disabled') }} />
              <td style={{ width: ROW_W[2], backgroundColor: token('color.background.disabled') }} />
              <td style={{ width: ROW_W[3], padding: 0 }}>
                <HoursMinutesInput
                  usedWithReadOnly
                  minutes={vehicleDate?.minutesToArriveHome ?? null}
                  onChangeCommit={handleMinutesToArriveSubmit}
                  isDisabled={isSomeCompleted}
                />
              </td>
              <td style={{ width: ROW_W[4], padding: 0 }}>
                <TableCellNumberTextField
                  value={milesToArrive ?? '-'}
                  suffix='mi'
                  onChange={handleMilesToArriveChange}
                  onBlur={handleMilesToArriveSubmit}
                  onKeyDown={handleEnterKeyBlur}
                  isDisabled={isSomeCompleted}
                />
              </td>
              <td style={{ width: ROW_W[5], backgroundColor: token('color.background.disabled') }} />
              <td style={{ width: ROW_W[6], backgroundColor: token('color.background.disabled') }} />
              <td style={{ width: ROW_W[7], backgroundColor: token('color.background.disabled') }} />
              <td style={{ width: ROW_W[8], backgroundColor: token('color.background.disabled') }} />
            </tr>

            <tr>
              <TotalCell>
                <span style={{ fontSize: 15, fontWeight: 'bold', paddingLeft: 24 }}>Vehicle Totals</span>
              </TotalCell>
              <td style={{ width: ROW_W[1] }}>
                <HrMinCell value={vDate?.totalMinutesProximity} />
              </td>
              <td style={{ width: ROW_W[2] }}></td>
              <td style={{ width: ROW_W[3] }}>
                <HrMinCell value={vDate?.totalMinutesDriven} />
              </td>
              <td style={{ width: ROW_W[4] }}>
                <NumberCell value={vDate?.totalMilesDriven} suffix='mi' readOnly />
              </td>
              <td style={{ width: ROW_W[5] }}>
                <HrMinCell value={vDate?.totalMinutesVehicleOnSite} />
              </td>
              <td style={{ width: ROW_W[6] }} />
              <td style={{ width: ROW_W[7] }} />
              <td style={{ width: ROW_W[8] }} />
            </tr>
          </tbody>
        </table>
      </div>
    </VehicleContainerCard>
  )
  function handleMilesToArriveChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value
    setMilesToArrive(value)
  }

  function handleMilesToArriveSubmit() {
    console.log('handleMilesToArriveSubmit', milesToArrive)
    const dbValue = vehicleDate?.milesToArriveHome ?? null
    if (vehicleDateId && milesToArrive === '') {
      if (dbValue === null) return
      // we are clearing the database value, which is valid
      mutate({ vehicleDateId, attribute: 'milesToArriveHome', value: milesToArrive })
      return
    }

    const roundedValue = parseFloat(milesToArrive).toFixed(2)
    if (roundedValue !== milesToArrive) setMilesToArrive(roundedValue)
    if (vehicleDateId && roundedValue !== dbValue) {
      console.log('mutating milesToArriveHome', { roundedValue, dbValue })
      mutate({ vehicleDateId, attribute: 'milesToArriveHome', value: roundedValue })
    } else {
      console.log('not mutating milesToArriveHome', { roundedValue, dbValue })
    }
  }

  function handleMinutesToArriveSubmit(newValue: number | null) {
    console.log('handleMinutesToArriveSubmit', newValue)
    const newValueStr = newValue?.toString() ?? ''
    const dbValue = vehicleDate?.minutesToArriveHome?.toString() ?? ''
    if (vehicleDateId && newValueStr === '') {
      if (dbValue === '') return
      // we are clearing the database value, which is valid
      mutate({ vehicleDateId, attribute: 'minutesToArriveHome', value: newValueStr })
      return
    }

    if (vehicleDateId && newValueStr !== dbValue) {
      console.log('mutating minutesToArriveHome', { newValueStr, dbValue })
      mutate({ vehicleDateId, attribute: 'minutesToArriveHome', value: newValueStr })
    } else {
      console.log('not mutating minutesToArriveHome', { newValueStr, dbValue })
    }
  }

  function handleEnterKeyBlur(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') e.currentTarget.blur()
  }
}

export default VehicleContainer

interface VehicleContainerCardProps {
  $color: CardColorOptions
  $vehicleDispatchCount: number
  $isLoading: boolean
}
const VEHICLE_DISPATCH_CARD_GAP = 8

const VehicleContainerCard = styled.div<VehicleContainerCardProps>`
  border: 1px solid ${({ $color }) => (!$color ? token('color.border') : token(`color.border.accent.${$color}`))};
  border-radius: 4px;
  min-width: 500px;
  flex-shrink: 0;
  z-index: 0;
  align-self: flex-start;
  /* overflow: hidden; */

  .vehicle-body-section {
    position: relative;
    background: ${token('elevation.surface.sunken')};

    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;

    padding: ${VEHICLE_DISPATCH_CARD_GAP}px ${VEHICLE_DISPATCH_CARD_GAP + 4}px;
    overflow: hidden;
  }

  table {
    opacity: ${({ $isLoading }) => ($isLoading ? 0.5 : 1)};
    transition: opacity 0.2s ease-in-out;
    background: transparent;
    border-collapse: collapse;
    width: 100%;
    padding: 0;
    width: fit-content;
    /* border: 1px solid ${token('color.border')}; */
    td,
    th {
      box-sizing: border-box;
    }
    thead {
      color: ${token('color.text.subtlest')};
      tr {
        /* border-right: 1px solid ${token('color.border')}; */
        th + th {
          background: ${token('color.background.neutral')};
          border-top: 1px solid ${token('color.border')};
          border-left: 1px solid ${token('color.border')};
        }

        &:first-child {
          th {
            color: ${token('color.text.disabled')};
            text-align: center;
          }
        }

        &:last-child {
          th {
            text-align: right;
            padding-right: 8px;
          }
        }
      }
    }
    tr {
      padding: 0;
      border-right: 1px solid ${token('color.border')};
      /* display: flex;
      align-items: stretch; */
    }

    tbody {
      background: ${token('elevation.surface')};
      padding: 0;
      tr {
        &:last-child {
          td + td {
            padding: 0;
            font-size: 15px;
          }
          border-top: 2px solid ${token('color.border')};
          td {
            text-align: right;
          }
        }
        &:not(:last-child) {
          td {
            border-bottom: 1px solid ${token('color.border')};
          }
        }
        td + td + td {
          border-left: 1px solid ${token('color.border')};
        }
      }
    }
  }
`

const TotalCell = styled.td`
  height: 100%;
  min-height: 100%;

  padding: 0;
  width: ${ROW_W[0]}px;
  min-width: ${ROW_W[0]}px;

  border-width: 0px;
  border-color: ${token('color.border.accent.gray')};
  border-left: 3px solid ${token('color.border.accent.gray')};
  /* background: ${token(`color.background.accent.gray.subtlest`)}; */

  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex: 0 0 none;
  border-bottom-width: 0px;
  border-right: 1px solid ${token('color.border')};
  > span {
    padding: 6px 8px 6px 24px;
  }
`

const ReturnJobCell = styled.td<{ $accentColor?: CardColorOptions }>`
  height: 100%;
  min-height: 100%;

  padding: 0;
  width: ${ROW_W[0]}px;
  min-width: ${ROW_W[0]}px;

  border-color: ${token('color.border.accent.gray')};
  border-left: 3px solid ${token('color.border.accent.gray')};
  border-right: 1px solid ${token('color.border')};
  border-bottom-width: 0px !important;
  background: ${token(`color.background.accent.gray.subtlest`)};

  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex: 0 0 none;

  .vehicle-dispatch-card-index {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 24px;
    font-size: 18px;
    font-weight: 800;

    flex: 0 0 auto;
    color: ${({ $accentColor }) => (!$accentColor ? token(`color.text.disabled`) : token(`color.text.accent.${$accentColor}`))};
  }

  .job-card-info {
    flex: 1 1 auto;
    padding: 4px 8px 2px 0px;
    overflow: hidden;

    .customer-name {
      padding-bottom: 4px;
      border-bottom: 1px solid ${token('color.border')};
      padding-left: 1px;
      font-weight: bold;
      font-size: 13px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .job-site-address {
      display: flex;
      flex-direction: column;
      font-size: 11px;
      padding: 4px 2px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      a > span:not(.erh-address-link):not(.erh-davis-bacon-address) {
        display: none;
      }

      .erh-davis-bacon-address {
        display: inline-block;
        font-weight: 800;
        letter-spacing: -0.08em;
        margin-right: 1px;
        color: ${({ $accentColor }) =>
          // @ts-expect-error known issue with token function
          !$accentColor ? token(`color.text.sublest`) : token(`color.text.accent.${$accentColor}`)};
      }

      .erh-address-link {
        flex-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      a,
      span:not(.erh-davis-bacon-address) {
        color: ${token('color.text')};
        display: inline-block;
      }
    }
  }
`
