import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import InstallerTimecardTableNameCell from './components/InstallerTimecardTableNameCell'
import WeeklyTimecardCell from './components/WeeklyTimecardCell'
import useInstallersList from './hooks/useInstallersList'
import useTimesheetColumnDefs from './hooks/useTimesheetColumnDefs'

const WeeklyTimecardsTab = () => {
  const [isRendered, setIsRendered] = useState(false)

  const { installers, isLoading } = useInstallersList()
  const columnGroups = useTimesheetColumnDefs()

  // Build our column groups manually.
  // (Installer group: plain text; Day groups and week totals/subtotals use NumberCell.)

  useEffect(() => {
    setIsRendered(true)
  }, [])

  if (!installers.length && !isLoading) return <div>No timecard data available</div>

  return (
    <TableScroller>
      <Table>
        <thead>
          {/* First header row: group headers */}
          <tr>
            {columnGroups.map(group => (
              <TableHeader key={group.groupHeader} colSpan={group.columns.length}>
                {group.groupHeader}
              </TableHeader>
            ))}
          </tr>

          {/* Second header row: individual column headers */}
          <tr>{columnGroups.map(group => group.columns.map(col => <TableHeader key={col.id}>{col.header}</TableHeader>))}</tr>
        </thead>

        <tbody>
          {!isRendered
            ? null
            : installers.map((installer, rowIndex) => (
                <tr key={installer.id}>
                  {columnGroups.map(group =>
                    group.columns.map(col => {
                      const className = !col.field
                        ? ''
                        : rowIndex % 2 === 0
                          ? 'even-row-editable-field-cell'
                          : 'odd-row-editable-field-cell'
                      return (
                        <TableCell key={col.id} className={className}>
                          {col.id === 'installer' ? (
                            <InstallerTimecardTableNameCell key={col.id} installer={installer} />
                          ) : (
                            <WeeklyTimecardCell installer={installer} col={col} />
                          )}
                        </TableCell>
                      )
                    })
                  )}
                </tr>
              ))}
        </tbody>
      </Table>
    </TableScroller>
  )
}

export default WeeklyTimecardsTab
const HR_COLUMN_WIDTH = 90
const FIRST_COLUMN_WIDTH = 160
const TOTALS_COLUMN_WIDTH = 100

const DAY_COLS = 7
const TOT = 1 + DAY_COLS * 7 + 3

const TableScroller = styled.div`
  overflow: auto;
  position: absolute;
  inset: 0;
  /* Reserve space for scrollbars so that sticky footer stays flush */
  scrollbar-gutter: stable;

  /* Optional: Style scrollbars on WebKit browsers for a slimmer look */
  &::-webkit-scrollbar {
    width: 8px;
    background-color: ${token('elevation.surface.sunken')};
    height: 8px;
    border-left: 1px solid ${token('color.border')};
    box-sizing: border-box;
    transition: background-color 0.3s ease;
    border-top: 1px solid ${token('color.border')};
    &:hover {
      background-color: ${token('color.border')};
    }
  }
  &::-webkit-scrollbar-thumb {
    transition: background-color 0.3s ease;
    background-color: ${token('color.border')};
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid ${token('color.border')};
    &:hover {
      background-color: ${token('color.icon.disabled')};
    }
  }
`

const Table = styled.table`
  z-index: 1;
  border-collapse: separate;
  border-spacing: 0;
  background: transparent;
  border-bottom: 0px solid ${token('color.border')};

  width: max-content;
  min-width: 100%;

  * {
    box-sizing: border-box;
  }

  /* Common cell styles */
  td,
  th {
    height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;

    &:not(:nth-child(1)) {
      width: ${HR_COLUMN_WIDTH}px;
      min-width: ${HR_COLUMN_WIDTH}px;
      max-width: ${HR_COLUMN_WIDTH}px;
    }

    > div > a > div.header-tooltip-content-wrapper {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: ${token('color.text.subtlest')};
    }
    & + td {
      border-left: 1px solid ${token('color.border')};
    }
  }

  /* All table rows (Header/Body/Footer) */
  tr {
    border-left: 1px solid ${token('color.border')};
    border-right: 1px solid ${token('color.border')};

    &:not(thead > tr:first-child) {
      td,
      th {
        &:nth-child(1) {
          width: ${FIRST_COLUMN_WIDTH}px;
          min-width: ${FIRST_COLUMN_WIDTH}px;
          max-width: ${FIRST_COLUMN_WIDTH}px;
          text-align: left;
        }

        &:nth-child(2) {
          border-left-width: 0;
        }

        /* WEEK SUBTOTALS */
        &:nth-child(${TOT - 1}),
        &:nth-child(${TOT - 2}) {
          width: ${TOTALS_COLUMN_WIDTH}px;
          min-width: ${TOTALS_COLUMN_WIDTH}px;
          max-width: ${TOTALS_COLUMN_WIDTH}px;
        }

        /* WEEK TOTALS */
        &:nth-child(${TOT}),
        &:nth-child(${TOT + 1}),
        &:nth-child(${TOT + 2}) {
          width: ${TOTALS_COLUMN_WIDTH}px;
          min-width: ${TOTALS_COLUMN_WIDTH}px;
          max-width: ${TOTALS_COLUMN_WIDTH}px;
          position: sticky;
        }
        &:nth-child(${TOT}) {
          right: ${TOTALS_COLUMN_WIDTH * 2}px;
          box-shadow: -2px 0 3px -1px ${token('color.border')};
        }
        &:nth-child(${TOT + 1}) {
          right: ${TOTALS_COLUMN_WIDTH}px;
        }
        &:nth-child(${TOT + 2}) {
          right: 0px;
        }
        ${Array.from({ length: 7 }, (_, i) => {
          const dayIndex = i + 1
          const dayCol = dayIndex * DAY_COLS + 2
          console.log(dayCol)
          // if (i === 0) return ''
          return `
            &:nth-child(${dayCol}) {
              border-left-width: 3px;
            }

          `
        })}
      }
    }
  }

  /* Table head */
  thead {
    position: sticky;
    top: 0;
    z-index: 5;
    box-sizing: border-box;
    height: 64px;
    min-height: 64px;
    max-height: 64px;

    > tr {
      background: ${token('elevation.surface.raised')};
      z-index: 5;

      th {
        max-height: 32px;
        min-height: 32px;
        z-index: 5;
        padding: 4px 8px;
        text-align: left;
        position: relative;

        &:nth-child(${TOT}),
        &:nth-child(${TOT + 1}),
        &:nth-child(${TOT + 2}) {
          position: sticky;
          right: 0;

          background: ${token('color.background.accent.gray.subtlest')};
          top: auto;

          &:nth-child(${TOT + 1}) {
            right: ${HR_COLUMN_WIDTH}px;
          }
          &:nth-child(${TOT + 2}) {
            right: ${HR_COLUMN_WIDTH * 2}px;
          }
        }
      }

      /* Add left border between adjacent THs */
      th + th {
        border-left: 1px solid ${token('color.border')};
      }
      &:first-child > th:first-child,
      &:last-child > th:first-child {
        position: sticky;
        left: 0;

        z-index: 6;
        background: ${token('color.background.accent.gray.subtlest')};

        color: ${token('color.text.subtlest')};

        box-shadow: 2px 0 3px -1px ${token('color.border')};
      }

      /* First row in thead */
      &:first-child > th {
        color: ${token('color.text.subtlest')};
        top: 0;

        &:last-child {
          background: ${token('color.background.accent.gray.subtlest')};
          position: sticky;
          right: 0;
          z-index: 6;
          box-shadow: -2px 0 3px -1px ${token('color.border')};
          /* border-left-width: 1px !important; */
        }

        &:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(10)) {
          border-left-width: 3px;
        }

        &:nth-child(2) {
          border-left-width: 0;
        }
      }

      /* Last row in thead */
      &:last-child > th {
        z-index: 5;
        top: auto;
        text-align: right;

        &:after {
          z-index: 6;
          content: '';
          position: absolute;
          inset: 0;
          box-shadow: inset 0 -3px 2px -2.5px ${token('color.icon.disabled')};
        }
      }
    }
  }

  /* Table body */
  tbody {
    z-index: 1;

    > tr {
      z-index: 1;

      /* Sticky first row in body */

      /* Even rows get neutral background */
      &:nth-child(even) {
        background: ${token('color.background.neutral')};

        td:nth-child(1),
        td:nth-child(${TOT}),
        td:nth-child(${TOT + 1}),
        td:nth-child(${TOT + 2}) {
          background: ${token('elevation.surface.hovered')};
        }
      }

      /* Optional thick top border */
      &.has-top-header-row > td {
        border-top: 3px solid ${token('color.border')};
      }

      /* Body cells */
      > td {
        padding: 0;
        border-bottom: 1px solid ${token('color.border')};
        vertical-align: middle;
        text-align: right;

        /* Slightly special case for first cell */
        &:first-child {
          padding-left: 8px;
          padding-right: 8px;
        }

        /* Sticky pinned columns */
        &:nth-child(1),
        &:nth-child(${TOT}),
        &:nth-child(${TOT + 1}),
        &:nth-child(${TOT + 2}) {
          position: sticky;
          z-index: 2;
          background: ${token('elevation.surface.raised')};
        }

        &:nth-child(1) {
          left: 0;
          box-shadow: 2px 0 3px -1px ${token('color.border')};

          &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 2px;
          }
        }
      }
    }
  }
`

interface TableCellProps {
  $sticky?: 'left' | 'right'
  $isGroup?: boolean
}

const TableHeader = styled.th<TableCellProps>`
  background: ${token('color.background.neutral')};
  padding: 8px;
  text-align: left;
  font-weight: 600;
  border-bottom: 1px solid ${token('color.border')};
  position: sticky;
  top: ${props => (props.$isGroup ? 0 : '32px')};
  z-index: 2;

  ${props =>
    props.$sticky === 'left' &&
    `
    position: sticky;
    left: 0;
    z-index: 3;
  `}

  ${props =>
    props.$sticky === 'right' &&
    `
    position: sticky;
    right: 0;
    z-index: 3;
  `}
`

const TableCell = styled.td<TableCellProps>`
  padding: 8px;
  border-bottom: 1px solid ${token('color.border')};

  ${props =>
    props.$sticky === 'left' &&
    `
    position: sticky;
    left: 0;
    z-index: 1;
  `}

  ${props =>
    props.$sticky === 'right' &&
    `
    position: sticky;
    right: 0;
    z-index: 1;
  `}

  &.even-row-editable-field-cell {
    background: ${token('color.background.accent.blue.subtlest')};
  }

  &.odd-row-editable-field-cell {
    background: ${token('color.background.accent.blue.subtler')};
  }
`
