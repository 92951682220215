import styled from 'styled-components'

import { token } from '@atlaskit/tokens'
import Tooltip from '@atlaskit/tooltip'

import { toTitleCase } from '@/utils/utilities'

import { GetDispatchPayoutDetailsQuery } from '@/gql/graphql'

import NumberCell from '@/components/NumberCell'

import AddOnModal from '../../AddOnModal/AddOnModal'
import DispatchPieceRateTextField from '../DispatchPieceRateTextField'
import { WORK_ORDER_CELL_WIDTH } from '../DispatchTableWorkOrderCell'

import { CELL_PAD } from './constants'
import DispatchTableTopHalf from './DispatchTableTopHalf'

interface DispatchPayoutTopTableProps {
  workOrder?: GetDispatchPayoutDetailsQuery['workOrder']
  dateString: string
  isSomeCompleted: boolean
}

const DispatchPayoutTopTable = ({ workOrder, dateString, isSomeCompleted }: DispatchPayoutTopTableProps) => {
  const dispatches = (workOrder?.dispatches ?? []).sort((a, b) => a.date.localeCompare(b.date))
  const selectedDispatch = dispatches.find(dispatch => dispatch.date === dateString)
  const dispatchAddons = selectedDispatch?.enteredAddons ?? 0
  const isDavisBacon = workOrder?.isDavisBacon ?? false
  // const isDavisBacon = true

  const enteredPieceRate = selectedDispatch?.enteredPieceRate ? Number(selectedDispatch.enteredPieceRate) : null
  const thisDispatchIndex = dispatches.findIndex(dispatch => dispatch.date === dateString) + 1
  const addons = selectedDispatch?.payoutAddons ?? []
  const addonCount = addons.length
  const thisDispatchStyle = { padding: '4px 8px', fontWeight: 700, borderTop: `2px solid ${token('color.border')}` }
  return (
    <TopTableWrapper>
      <table>
        <thead>
          <tr>
            <th colSpan={2} />
            {isDavisBacon ? <th>Davis Bacon</th> : <th>Hourly</th>}
            {isDavisBacon ? <th>PR Gap</th> : null}
            <th>Drive Pay</th>
            <th>Piece Rate</th>
            <th>Addons</th>
            <th>Total</th>
          </tr>
        </thead>

        <tbody>
          <DispatchTableTopHalf workOrder={workOrder} dateString={dateString} isDavisBacon={isDavisBacon} />
          <tr style={{ minHeight: 16 }}>
            <td rowSpan={2 + addonCount} className='col0 blank-spacer' />
            <td colSpan={isDavisBacon ? 7 : 6} style={thisDispatchStyle}>
              This Dispatch{' '}
              <span style={{ fontWeight: 400 }}>
                ({thisDispatchIndex} of {dispatches.length})
              </span>
            </td>
          </tr>

          {!selectedDispatch?.id ? null : (
            <tr>
              <td className='col-row-labels'>Entered Piece Rate</td>

              {isDavisBacon ? (
                <td className='dispatch-col-hourly empty-cell' colSpan={3} />
              ) : (
                <td className='dispatch-col-hourly empty-cell' colSpan={2} />
              )}

              <td className='dispatch-col-piece-rate entered-piece-rate-field'>
                {isSomeCompleted ? (
                  <NumberCell padding={CELL_PAD} readOnly value={enteredPieceRate} prefix='$' />
                ) : (
                  <DispatchPieceRateTextField
                    databaseValue={enteredPieceRate}
                    dispatchId={selectedDispatch.id}
                    isDisabled={isSomeCompleted}
                  />
                )}
              </td>

              <td className='dispatch-col-addons'>
                <NumberCell padding={CELL_PAD} readOnly value={dispatchAddons} prefix='$' />
              </td>

              <td className='dispatch-col-total' style={{ backgroundColor: token('color.background.neutral') }}>
                <AddOnModal dispatchId={selectedDispatch.id} isDisabled={isSomeCompleted} />
              </td>
            </tr>
          )}

          {selectedDispatch?.payoutAddons.map(addon => (
            <tr key={addon.id}>
              <td className='col-row-labels'> + Addon: {toTitleCase(addon.reason ?? 'None')}</td>

              <td colSpan={isDavisBacon ? 4 : 3} className='addon-note dispatch-col-drive'>
                <Tooltip content={addon.note}>
                  <span>{addon.note}</span>
                </Tooltip>
              </td>

              <td className='dispatch-col-piece-rate'>
                <NumberCell padding={CELL_PAD} readOnly value={addon.payoutAmount} prefix='$' />
              </td>

              <td
                className='addon-control-cell dispatch-col-addons'
                style={{ backgroundColor: token('color.background.neutral'), textAlign: 'center' }}
              >
                <AddOnModal dispatchId={selectedDispatch?.id} selectedAddon={addon} isDisabled={isSomeCompleted} />
              </td>
            </tr>
          ))}
        </tbody>

        <tfoot>
          <tr>
            <td className='col0' />
            <td className='col-row-labels'>Actual Payout</td>

            {isDavisBacon ? (
              <>
                <td className='dispatch-col-davis-bacon'>
                  <NumberCell padding={CELL_PAD} readOnly value={selectedDispatch?.totalPaidDavisBacon} prefix='$' />
                </td>
                <td className='dispatch-col-pr-gap'>
                  <NumberCell padding={CELL_PAD} readOnly value={selectedDispatch?.totalPaidPieceRateDavisBaconGap} prefix='$' />
                </td>
              </>
            ) : (
              <td className='dispatch-col-hourly'>
                <NumberCell padding={CELL_PAD} readOnly value={selectedDispatch?.totalPaidHourly} prefix='$' />
              </td>
            )}

            <td className='dispatch-col-drive'>
              <NumberCell padding={CELL_PAD} readOnly value={selectedDispatch?.totalPaidDrivePay} prefix='$' />
            </td>

            <td className='dispatch-col-piece-rate'>
              <NumberCell padding={CELL_PAD} readOnly value={selectedDispatch?.totalPaidPieceRate} prefix='$' />
            </td>

            <td className='dispatch-col-addons'>
              <NumberCell padding={CELL_PAD} readOnly value={selectedDispatch?.totalPaidAddons} prefix='$' />
            </td>

            <td className='dispatch-col-total'>
              <NumberCell padding={CELL_PAD} readOnly value={selectedDispatch?.totalPaidLaborTotal} prefix='$' />
            </td>
          </tr>
        </tfoot>
      </table>
    </TopTableWrapper>
  )
}

const TopTableWrapper = styled.div`
  padding-top: 16px;
  > table {
    width: fit-content;
    transition: opacity 0.2s ease-in-out;
    background: transparent;
    border-collapse: collapse;

    td,
    th {
      box-sizing: border-box;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    thead {
      color: ${token('color.text.subtlest')};
      border-bottom: 0px solid transparent;
      tr {
        border-right: 1px solid ${token('color.border')};
        th + th {
          background: ${token('color.background.neutral')};
          border-top: 1px solid ${token('color.border')};
          border-left: 1px solid ${token('color.border')};
        }

        &:last-child {
          th {
            text-align: right;
            padding-right: 8px;
          }
        }
      }
    }

    tr {
      border-right: 1px solid ${token('color.border')};

      &:last-child > .dispatch-col-hourly {
        background: inherit;
      }
      td,
      th {
        &.col-row-labels {
          width: 140px;
          padding-right: 8px;
          padding-left: 8px;
          color: ${token('color.text.subtlest')};
          font-weight: 500;
        }
        &.dispatch-col-hourly {
          width: 110px;
          background: ${token('color.background.disabled')};
        }
        &.dispatch-col-davis-bacon {
          width: 110px;
          background: ${token('color.background.disabled')};
        }
        &.dispatch-col-pr-gap {
          width: 110px;
          background: ${token('color.background.disabled')};
        }
        &.dispatch-col-drive {
          width: 110px;
        }
        &.dispatch-col-piece-rate {
          width: 110px;

          &.entered-piece-rate-field {
            padding: 0;
            /* border: 1.5px solid ${token('color.border.selected')}; */
            background: ${token('color.background.selected')};
            color: ${token('color.text.information')};
          }
        }
        &.dispatch-col-addons {
          width: 100px;
        }
        &.dispatch-col-total {
          width: 120px;
        }
      }

      td {
        border-bottom: 1px solid ${token('color.border')};
        padding: 0;

        &.empty-cell {
          background: ${token('color.background.disabled')} !important;
        }

        &.addon-note {
          padding: 0 8px;
          color: ${token('color.text.subtlest')};
          max-width: 180px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        &.addon-control-cell {
          opacity: 0.4;
          transition: opacity 0.12s ease-in-out;
          &:hover {
            opacity: 1;
          }
        }
      }
      td:not(.col0) {
        border-left: 1px solid ${token('color.border')};
      }
    }

    tbody {
      border-bottom: 0px solid transparent;
      tr {
        height: 32px;
        min-height: 32px;
        > td {
          min-height: 32px;
        }
      }
      tr:first-child > td:not(.col0) {
        border-top: 2px solid ${token('color.border')};
      }
      tr:last-child > td {
        border-bottom: 2px solid ${token('color.border')};
      }
      .blank-spacer {
        border-bottom: 0px solid transparent;
      }
      .workOrderCell {
        padding: 0;
        border: 0;
        border-bottom: 0px solid transparent;
        vertical-align: top;
        position: relative;
        min-width: ${WORK_ORDER_CELL_WIDTH}px;
        box-sizing: border-box;
        min-height: 94px;
      }
    }
    tfoot {
      border-bottom: 0px solid transparent;
      tr {
        td {
          font-weight: 500;
          color: ${token('color.text')};
          &:first-child {
            color: ${token('color.text')};
            font-weight: 600;
            border-bottom: 0px solid transparent;
          }
        }
        td + td {
          padding: 0;
        }
      }
    }
  }
`

export default DispatchPayoutTopTable
