import { Temporal } from '@js-temporal/polyfill'
import { useQuery } from '@tanstack/react-query'

import useGraphQLClient from '@/utils/useAuthRequest'

import { graphql } from '@/gql'

import useReconSearchParams from '../useReconSearchParams'

const usePaystubQuery = () => {
  const { weekNumber, year, installerId } = useWeekYearParams()
  const graphqlClient = useGraphQLClient()

  return useQuery({
    queryKey: [GET_INSTALLER_PAYROLL_QUERY_KEY, year, weekNumber, installerId],
    queryFn: async () => {
      const response = await graphqlClient.request(GET_INSTALLER_PAYROLL, {
        weekYear: year,
        weekNumber,
        installerId: installerId ?? '',
      })
      if (!response.installerPayroll) {
        throw new Error('No week of data found')
      }
      return response
    },
  })
}

export default usePaystubQuery

const useWeekYearParams = () => {
  const { paramValues } = useReconSearchParams()
  const installerId = paramValues.get('installerId')
  const today = Temporal.Now.plainDateISO()
  const weekId = paramValues.get('weekId')
  const todayWeekNumber = today.weekOfYear
  const todayYear = today.year
  let todayWeekYear = todayYear
  const todayMonthNumber = today.month
  if (todayMonthNumber === 1 && todayWeekNumber > 50) todayWeekYear -= 1

  const year = Number(weekId?.split('-')?.[0] ?? todayWeekYear)
  const weekNumber = Number(weekId?.split('-')?.[1] ?? todayWeekNumber)

  return { weekNumber, year, installerId }
}

export const GET_INSTALLER_PAYROLL_QUERY_KEY = 'GET_INSTALLER_PAYROLL'
const GET_INSTALLER_PAYROLL = graphql(/* GraphQL */ `
  query GetInstallerPayroll($weekNumber: Int!, $weekYear: Int!, $installerId: ID!) {
    installerPayroll(weekNumber: $weekNumber, weekYear: $weekYear, installerId: $installerId) {
      ...InstallersWithWeeklyPayrollFragment
      installerDispatches {
        id
        sid
        installerId
        modifiedAt
        vehicleDispatchId
        vehicleDispatch {
          id
          vehicleDateId
          dispatchId
          dispatch {
            id
            date
            workOrderId
            workOrder {
              id
              name
              projectSiteAddress
              estimateId
              customerName
            }
          }
        }
        date
        isHourlyPayout
        isDavisBacon
        isCompleted
        isApproved
        isHourlyInstaller

        calculatedMinutesDriveBuffer
        calculatedMinutesOnSite

        hourlyRate
        hourlyRateDrive

        minutesDriveActual
        minutesDrivePayable
        minutesDrivePayableOverride

        minutesOnSite
        minutesWorked
        minutesTotal

        payoutAddons
        payoutDavisBacon
        payoutDrive
        payoutHourly
        payoutPieceRate
        payoutPieceRateDavisBaconGap
        payoutTotal
        percentPieceRate
      }
    }
  }
`)
