import { ColumnOrderState, ColumnSizingInfoState } from '@tanstack/react-table'
import { atom } from 'jotai'
import { atomFamily, atomWithStorage, selectAtom } from 'jotai/utils'

import { COLUMN_WIDTH_DRAG, COLUMN_WIDTH_INDICATOR, COLUMN_WIDTH_SELECT } from './editorConstants'
import {
  EditorColumnKey,
  QueriedEstimate,
  QueriedEstimateWorkItem,
  QueriedEstimateWorkOrders,
  WorkItemEditableAttributeKeys,
  WorkItemValueToSubmit,
} from './editorTypes'

export const isRecalculatingEstimateAtom = atom(false)

export const databaseEstimateAtom = atom<Omit<QueriedEstimate, 'prefetchedWorkItems'> | null>(null)

export const databaseWorkItemsAtom = atom<Record<string, QueriedEstimateWorkItem>>({})
export const databaseWorkItemsFamily = atomFamily((id: string) => selectAtom(databaseWorkItemsAtom, items => items[id]))

export const workItemsOptimisticAttributesAtom = atom<Record<string, Partial<QueriedEstimateWorkItem>>>({})
export const workItemsOptimisticAttributesFamily = atomFamily((id: string) =>
  selectAtom(workItemsOptimisticAttributesAtom, items => items[id])
)

export const databaseEstimateWorkOrdersAtom = atom<QueriedEstimateWorkOrders>([])

export const workItemAttributeFamily = atomFamily(({ id, key }: { id: string; key: keyof QueriedEstimateWorkItem }) =>
  atom(get => {
    const databaseWorkItem = get(databaseWorkItemsFamily(id))
    const optimisticAttributes = get(workItemsOptimisticAttributesFamily(id))
    return optimisticAttributes?.[key] ?? databaseWorkItem?.[key]
  })
)

type WorkItemEdit = Record<WorkItemEditableAttributeKeys, string | boolean | null>
export type WorkItemEdits = Record<string, WorkItemEdit>

export const workItemEditsQueueAtom = atom<WorkItemValueToSubmit[]>([])

export const editingCellAtom = atom<{
  rowId: string | null
  columnId: string | null
  edits: WorkItemEdits
}>({
  rowId: null,
  columnId: null,
  edits: {} as WorkItemEdits,
})

export const columnOrderAtom = atom<ColumnOrderState>([
  'dragHandle',
  'select',
  'indicators',
  'workOrderId',
  'workAreaId',
  'materialId',
  'materialName',
  'materialNote',
  'tripId',
  'quantity',
  'unitOfMeasure',
  'containersNeededFinal',
  'costPerUnit',
  'materialCostCalculated',
  'laborCostPerUnit',
  'laborCostOverride',
  'laborCostFinal',
  'totalCostCalculated',
  'marginPercent',
  'totalPriceOverride',
  'totalPriceFinal',
  'isVisibleOnQuote',
  'isVisibleOnWorkOrder',
])

export const editorSelectionStateAtom = atom<Record<string, boolean>>({})

const initialColumnWidths: Partial<Record<EditorColumnKey, number>> = {
  dragHandle: COLUMN_WIDTH_DRAG,
  select: COLUMN_WIDTH_SELECT,
  indicators: COLUMN_WIDTH_INDICATOR,
}
export const columnSizingAtom = atomWithStorage<Partial<Record<EditorColumnKey, number>>>('columnSizing', initialColumnWidths, undefined, {
  getOnInit: true,
})

export const columnSizingInfoAtom = atom<ColumnSizingInfoState>({
  columnSizingStart: [],
  deltaOffset: null,
  deltaPercentage: null,
  isResizingColumn: false,
  startOffset: null,
  startSize: null,
})
