import { RefObject, useEffect } from 'react'

import { useSetAtom } from 'jotai'
import { isMonthYearPickerOpenAtom } from './atoms'

const useCalendarListeners = (containerRef: RefObject<HTMLDivElement | null>) => {
  const setMonthYearPickerOpen = useSetAtom(isMonthYearPickerOpenAtom)
  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
        // In original code: reset range if partial. Also close month/year picker
        setMonthYearPickerOpen(false)
      }
    }
    window.addEventListener('mousedown', handleClickOutside)
    return () => window.removeEventListener('mousedown', handleClickOutside)
  }, [setMonthYearPickerOpen, containerRef])

  // Escape key to cancel range selection or close popover
  useEffect(() => {
    function onKeyDown(e: KeyboardEvent) {
      if (e.key === 'Escape') setMonthYearPickerOpen(false)
    }
    window.addEventListener('keydown', onKeyDown)
    return () => window.removeEventListener('keydown', onKeyDown)
  }, [setMonthYearPickerOpen])
}
export default useCalendarListeners
