// ui/src/components/MiniCalendarNav/useCalendarLogic.ts
import { Temporal } from '@js-temporal/polyfill'
import { useMemo } from 'react'
import { CalendarDate, CalendarWeekWithDates } from './calendarTypes'

interface UseCalendarLogicProps {
  displayedYear: number
  displayedMonth: number
}

const FIRST_DAY_OF_WEEK = 1 // Monday

const useCalendarLogic = ({ displayedYear, displayedMonth }: UseCalendarLogicProps) => {
  const calendarMatrix: CalendarDate[] = useMemo(() => {
    const startOfMonth = Temporal.PlainDate.from({
      year: displayedYear,
      month: displayedMonth,
      day: 1,
    })
    // Calculate offset to get back to the first day in the grid.
    const startDayOfWeek = startOfMonth.dayOfWeek // Monday=1, Sunday=7
    const isoWeekIndexForStart = startDayOfWeek % 7 // Sunday=0
    const offsetToGridStart = (isoWeekIndexForStart - FIRST_DAY_OF_WEEK + 7) % 7
    const gridStartDate = startOfMonth.subtract({ days: offsetToGridStart })

    const totalCells = 42 // 6 rows * 7 days
    const daysMatrix: CalendarDate[] = []
    for (let i = 0; i < totalCells; i++) {
      const date = gridStartDate.add({ days: i })
      const calendarDate: CalendarDate = {
        id: date.toString(),
        year: date.year,
        monthNumber: date.month,
        dayNumber: date.day,
        dayOfWeek: date.dayOfWeek,
        weekNumber: date.weekOfYear,
        weekYear: date.yearOfWeek,
        date: date,
      }
      daysMatrix.push(calendarDate)
    }
    return daysMatrix
  }, [displayedYear, displayedMonth])

  const weeks: CalendarWeekWithDates[] = useMemo(() => {
    const rows: CalendarWeekWithDates[] = []
    for (let i = 0; i < calendarMatrix.length; i += 7) {
      const rowDates: CalendarDate[] = calendarMatrix.slice(i, i + 7)
      const { weekNumber, weekYear } = rowDates[0]
      const rowWeek: CalendarWeekWithDates = {
        id: `${weekYear}-${weekNumber}`,
        weekNumber: weekNumber,
        weekYear: weekYear,
        dates: rowDates,
      }
      if (i === 0) console.log('## rowWeek', rowWeek)
      rows.push(rowWeek)
    }
    return rows
  }, [calendarMatrix])

  return weeks
}

export default useCalendarLogic
