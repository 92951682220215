import { useMutation } from '@tanstack/react-query'
import { useAtom, useSetAtom } from 'jotai'
import { useCallback, useEffect, useState } from 'react'

import useGraphQLClient from '@/utils/useAuthRequest'

import { graphql } from '@/gql'

import { databaseEstimateAtom, databaseWorkItemsAtom, workItemEditsQueueAtom, workItemsOptimisticAttributesAtom } from '../editorAtoms'
import type { QueriedEstimateWorkItem, WorkItemValueToSubmit } from '../editorTypes'

export const useMutationQueue = () => {
  const graphQLClient = useGraphQLClient()

  const setOptimisticAttributes = useSetAtom(workItemsOptimisticAttributesAtom)
  const setDatabaseWorkItems = useSetAtom(databaseWorkItemsAtom)
  const setDatabaseEstimate = useSetAtom(databaseEstimateAtom)
  const [editsQueue, setEditsQueue] = useAtom(workItemEditsQueueAtom)
  const [isProcessing, setIsProcessing] = useState(false)

  const { mutateAsync } = useMutation({
    mutationFn: async (edit: WorkItemValueToSubmit) => {
      console.log('Estimate Edit Mutation: ', edit)
      const response = await graphQLClient.request(SUBMIT_WORK_ITEM_VALUE, {
        workItemId: edit.workItemId,
        attribute: edit.attribute,
        value: edit.value,
      })
      if (!response.submitWorkItemValue.success) throw new Error(response.submitWorkItemValue.message)
      return response
    },

    onSuccess: (data, { workItemId, attribute }) => {
      const workItem = data.submitWorkItemValue.workItem
      const newId = workItemId ?? workItem?.id ?? null
      if (!workItem || !newId) throw new Error('Work item not found in successful response')
      console.log('onSuccess setting workItem: ', workItem)
      // Inject the updated workItem into the databaseWorkItemsAtom
      setDatabaseWorkItems(prev => ({ ...prev, [newId]: workItem }))

      const {
        materialCostTotal,
        laborCostTotal,
        totalCostTotal,
        marginPercent,
        totalPriceCalculated,
        totalPriceOverride,
        totalPriceFinal,
        modifiedAt,
      } = workItem.estimate

      setDatabaseEstimate(prev => {
        console.log('onSuccess setting estimate: ', {
          prev,
          materialCostTotal,
          laborCostTotal,
          totalCostTotal,
          marginPercent,
          totalPriceCalculated,
          totalPriceOverride,
          totalPriceFinal,
          modifiedAt,
        })
        return {
          ...prev,
          materialCostTotal,
          laborCostTotal,
          totalCostTotal,
          marginPercent,
          totalPriceCalculated,
          totalPriceOverride,
          totalPriceFinal,
          modifiedAt,
        }
      })

      // Clear the optimistic attribute
      setOptimisticAttributes(attrs => {
        const newAttrs = { ...attrs }
        if (newAttrs[newId]) {
          delete newAttrs[newId][attribute as keyof Partial<QueriedEstimateWorkItem>]
          if (Object.keys(newAttrs[newId]).length === 0) delete newAttrs[newId]
        }
        return newAttrs
      })
    },
    onError: (error, { workItemId, attribute }) => {
      console.error('Error submitting work item value:', error)
      setEditsQueue(prevQueue => prevQueue.filter(edit => edit.workItemId !== workItemId || edit.attribute !== attribute))
    },
  })

  const processQueue = useCallback(async () => {
    if (isProcessing || editsQueue.length === 0) return

    setIsProcessing(true)

    try {
      const edit = editsQueue[0]
      await mutateAsync(edit)

      setEditsQueue(prevQueue => prevQueue.slice(1))
    } catch (error) {
      console.error('Error processing edit:', error)
    } finally {
      setIsProcessing(false)
    }
  }, [editsQueue, isProcessing, mutateAsync, setEditsQueue])

  useEffect(() => {
    if (editsQueue.length > 0 && !isProcessing) processQueue()
  }, [editsQueue, isProcessing, processQueue])

  return { isSubmitting: isProcessing }
}

export default useMutationQueue

const SUBMIT_WORK_ITEM_VALUE = graphql(/* GraphQL */ `
  mutation SubmitWorkItemValue($workItemId: UUID!, $attribute: String!, $value: JSON!) {
    submitWorkItemValue(workItemId: $workItemId, attribute: $attribute, value: $value) {
      success
      message
      workItem {
        id
        sid
        quantity
        modifiedAt
        isVisibleOnQuote
        isVisibleOnWorkOrder
        materialNote
        isOverrideLocked
        materialCostCalculated
        laborCostCalculated
        laborCostOverride
        laborCostFinal
        totalCostCalculated
        containersNeededCalculated
        containersNeededOverride
        containersNeededFinal
        marginPercent
        totalPriceCalculated
        totalPriceOverride
        totalPriceFinal

        workOrderId
        workOrder {
          id
          name
        }
        tripId
        trip {
          id
          name
        }

        workAreaId
        workArea {
          id
          name
        }

        materialCostScheduleId
        materialCostSchedule {
          id
          sid
          effectiveDate
          costPerUnit
          costPerContainer
          purchaseContainerLabel
          purchaseContainerUnitQuantity
        }

        materialId
        material {
          id
          sid
          itemCode
          name
          unitOfMeasure
          displayName
          laborCostPerUnit
          activeCostSchedule {
            id
            costPerUnit
            purchaseContainerUnitQuantity
          }
        }

        estimateId
        estimate {
          id
          sid
          jobId
          title
          startDate
          status
          materialCostTotal
          laborCostTotal
          totalCostTotal
          marginPercent
          totalPriceCalculated
          totalPriceOverride
          totalPriceFinal
          modifiedAt
          sortOrder
        }
      }
    }
  }
`)
