import styled from 'styled-components'

const defaultOptions = ['Option One', 'Option Two']

interface RadioGroupProps {
  options?: string[]
  value?: string | null
  setValue?: (value: string) => void
  style?: React.CSSProperties
  isDisabled?: boolean
}
const RadioGroup = ({ options = defaultOptions, value = null, setValue, style = {}, isDisabled = false }: RadioGroupProps) => {
  return (
    <RadioGroupContainer style={{ ...style }} $isDisabled={isDisabled}>
      {options.map((option, index) => (
        <RadioGroupButton
          onClick={e => (isDisabled ? null : handleClick(e, option))}
          $isSelected={value === option}
          $isLast={index + 1 === options.length}
          $isFirst={index === 0}
          key={option}
          $isDisabled={isDisabled}
        >
          {option}
        </RadioGroupButton>
      ))}
    </RadioGroupContainer>
  )
  function handleClick(e: React.MouseEvent<HTMLButtonElement>, option: string) {
    e.preventDefault()
    console.log('handleClick', option)
    if (setValue) setValue(option)
  }
}

export default RadioGroup

interface RadioGroupButtonProps {
  $isSelected: boolean
  $isLast: boolean
  $isFirst: boolean
  $isDisabled: boolean
}
const RadioGroupButton = styled.button<RadioGroupButtonProps>`
  -webkit-box-align: baseline;
  align-items: baseline;
  border-width: 0px;

  box-sizing: border-box;
  display: inline-flex;
  font-size: inherit;
  font-style: normal;
  font-family: inherit;
  font-weight: 500;
  max-width: 100%;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition:
    background 0.1s ease-out 0s,
    box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  white-space: nowrap;

  cursor: pointer;
  height: 2.28571em;
  /* line-height: 2.28571em; */
  padding: 0px 12px;
  vertical-align: middle;
  align-items: center;
  width: auto;
  -webkit-box-pack: center;
  justify-content: center;
  color: ${({ $isSelected }) => ($isSelected ? 'rgb(244, 245, 247)' : '#7A869A')};
  background: ${({ $isSelected }) => ($isSelected ? 'rgb(37, 56, 88)' : 'rgba(9, 30, 66, 0.04)')} !important;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background: ${({ $isSelected }) => ($isSelected ? 'rgb(37, 56, 88)' : 'rgba(9, 30, 66, 0.08)')};
    text-decoration: inherit;
    transition-duration: 0s, 0.15s;
    color: ${({ $isSelected }) => ($isSelected ? 'rgb(255, 255, 255)' : '#7A869A')};
  }

  ${({ $isSelected }) =>
    !$isSelected &&
    `
  &:active {
    background: rgba(179, 212, 255, 0.6);
    transition-duration: 0s, 0s;
    color: rgb(0, 82, 204);
  }
  `}
  ${({ $isFirst }) =>
    $isFirst &&
    `
border-bottom-left-radius: 3px;
border-top-left-radius: 3px;
`}
  ${({ $isLast }) =>
    $isLast &&
    `
border-bottom-right-radius: 3px;
border-top-right-radius: 3px;
`}
`
interface RadioGroupContainerProps {
  $isDisabled: boolean
}
const RadioGroupContainer = styled.div<RadioGroupContainerProps>`
  display: flex;
  align-items: stretch;
  height: 32px;
  ${({ $isDisabled }) => $isDisabled && `opacity: 0.7;`}
`
