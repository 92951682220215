import React, { useRef, useState } from 'react'

import HoursMinutesInput from '@/components/HoursMinutesInput'
import TableCellNumberTextField from '@/components/TableCellNumberTextField'

import useParamsWeek from '../hooks/useParamsWeek'
import useSubmitInstallerPayroll from '../hooks/useSubmitInstallerPayroll'

type EditableFieldKeys = 'payoutAdjustment' | 'hourlyRateNonDispatch' | 'minutesAdjustmentManual'

const CURRENCY_FIELD_KEYS = ['payoutAdjustment', 'hourlyRateNonDispatch']

interface EditPayrollCellProps {
  fieldKey: EditableFieldKeys
  installerId: string
  databaseValue?: string | null
  isDisabled?: boolean
}

const EditPayrollCell = ({ fieldKey, installerId, databaseValue, isDisabled }: EditPayrollCellProps) => {
  const escapePressedRef = useRef(false)
  const [value, setValue] = useState<string | null>(databaseValue ?? null)

  const { weekOf: weekOfDate } = useParamsWeek()

  const { mutate, isPending } = useSubmitInstallerPayroll()

  React.useEffect(() => {
    setValue(prev => databaseValue ?? prev ?? null)
  }, [databaseValue])

  if (fieldKey === 'minutesAdjustmentManual') {
    return (
      <HoursMinutesInput minutes={!value ? null : parseInt(value)} onChangeCommit={onCommit} usedWithReadOnly isDisabled={isDisabled} />
    )
  }

  return (
    <TableCellNumberTextField
      prefix={CURRENCY_FIELD_KEYS.includes(fieldKey) ? '$' : undefined}
      suffix={fieldKey === 'hourlyRateNonDispatch' ? '/ hr' : undefined}
      isCompact
      value={value ?? ''}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={handleKeyDown}
      onFocus={onFocus}
      isDisabled={isPending || isDisabled}
    />
  )

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Escape') {
      escapePressedRef.current = true // Update ref synchronously
      setValue(databaseValue ?? null) // Reset value
      e.currentTarget.blur() // Trigger blur
    }
    if (e.key === 'Enter') e.currentTarget.blur()
  }

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value
    setValue(value)
  }

  function onBlur() {
    if (escapePressedRef.current) {
      escapePressedRef.current = false // Reset ref
      return // Skip normal onBlur logic
    }

    let compareValue = value
    if (value) {
      compareValue = parseFloat(`${value}`).toFixed(2)
      setValue(compareValue)
    }
    const bothBlank = (compareValue === undefined || compareValue === null) && (databaseValue === null || databaseValue === undefined)
    const weekOf = weekOfDate.toString()

    if (!bothBlank && compareValue !== String(databaseValue))
      mutate({
        weekOf,
        installerId,
        attribute: fieldKey,
        value: compareValue,
      })
  }

  function onCommit(newMinutes: number | null) {
    const weekOf = weekOfDate.toString()

    mutate({
      weekOf,
      installerId,
      attribute: 'minutesAdjustmentManual',
      value: `${newMinutes}`,
    })
  }

  function onFocus() {
    escapePressedRef.current = false // Reset ref when field regains focus
  }
}

export default EditPayrollCell
