import { useRef } from 'react'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import useStaffListQuery from '@/utils/queryHooks/useStaffListQuery'

import useDropZoneSidebars from './hooks/useDropZoneSidebars'

import InstallerCard from './components/InstallerCard'
import SidebarScrollableList from './components/SidebarScrollableList'

import { DRAGGABLE_INSTALLER_CARD_GAP } from '../dispatchConstants'
import { SidebarZone } from '../dispatchTypes'

const zone: SidebarZone = 'INSTALLER_SIDEBAR'
const SIDE = 'RIGHT'

const SidebarInstallers = () => {
  const { staffList, status, error: staffListError } = useStaffListQuery()
  const isLoading = status === 'pending'

  const sidebarRef = useRef<HTMLDivElement | null>(null)

  const isHovered = useDropZoneSidebars({ zone, sidebarRef })

  const installers = staffList
    .filter(staff => staff.isInstaller)
    .sort((a, b) => {
      if (a.isHourly === b.isHourly) {
        return a.firstName.localeCompare(b.firstName)
      }
      return a.isHourly ? 1 : -1
    })
  const headerText = `${installers.length} Installers `

  return (
    <SidebarScrollableList ref={sidebarRef} isHovered={isHovered} headerText={headerText} side={SIDE}>
      <div className='sidebar-scrollable-list'>
        {isLoading && <div>Loading...</div>}

        {staffListError && <div>Error loading installer: {staffListError.message}</div>}

        <ListWrapper>
          {staffList.length === 0 ? (
            <div className='sidebar-none-found'>No installer found</div>
          ) : (
            installers.map(staff => <InstallerCard key={staff.id} installerId={staff.id} />)
          )}
        </ListWrapper>
      </div>
    </SidebarScrollableList>
  )
}

export default SidebarInstallers

const ListWrapper = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1 auto;
  /* padding: 12px 32px 24px 24px; */
  gap: ${DRAGGABLE_INSTALLER_CARD_GAP}px;

  .sidebar-none-found {
    padding: 12px;
    font-size: 14px;
    font-weight: 500;
    color: ${token('color.text.subtlest')};
  }
`
