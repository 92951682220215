import { Temporal } from '@js-temporal/polyfill'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router'

import { checkValidMonthNumber, checkValidYearNumber } from '../../utils/utilities'

type StrictDisplayMonthYearResult = {
  month: number | null
  year: number | null
  cId: string | null
}

type NonStrictDisplayMonthYearResult = {
  month: number
  year: number
  cId: string
}

type DisplayMonthYearResult<T extends boolean> = T extends true ? StrictDisplayMonthYearResult : NonStrictDisplayMonthYearResult

const useDisplayMonthYear = <T extends boolean = false>(strict: T): DisplayMonthYearResult<T> => {
  const [searchParams] = useSearchParams()
  const cId = searchParams.get('cId')

  return useMemo(() => getMonthYearFromCalId({ cId, strict }), [cId, strict])
}

export default useDisplayMonthYear

type StrictGetMonthYearResult = {
  month: number | null
  year: number | null
  cId: string | null
}

type NonStrictGetMonthYearResult = {
  month: number
  year: number
  cId: string
}

type GetMonthYearResult<T extends boolean> = T extends true ? StrictGetMonthYearResult : NonStrictGetMonthYearResult

export function getMonthYearFromCalId<T extends boolean = false>({
  cId: originalCId,
  strict,
}: {
  cId: string | null | undefined
  strict?: T
}): GetMonthYearResult<T> {
  const today = Temporal.Now.plainDateISO()
  let month: number | null = null
  let year: number | null = null
  let cId: string | null = null
  if (originalCId) {
    const [yearStr, monthStr] = originalCId.split('-')
    month = Number(monthStr)
    year = Number(yearStr)
  }
  if (!checkValidMonthNumber(month)) month = null
  if (!checkValidYearNumber(year)) year = null

  if (month === null || year === null) {
    if (strict) cId = originalCId ?? null
    else {
      month = today.month
      year = today.year
      cId = `${year}-${month}`
    }
  } else cId = `${year}-${month}`

  return { month, year, cId } as GetMonthYearResult<T>
}
