import { Temporal } from '@js-temporal/polyfill'
import { useQuery } from '@tanstack/react-query'
import styled from 'styled-components'

import GET_PAYROLL_DAY_VEHICLES, { GET_PAYROLL_DAY_VEHICLES_QUERY_KEY } from '@/utils/api/getPayrollDayVehicles'
import useGraphQLClient from '@/utils/useAuthRequest'

import { GetPayrollDayVehiclesQuery } from '@/gql/graphql'

import ReconAppPage from '../ReconAppPage'
import useReconSearchParams from '../useReconSearchParams'

import ReconDispatch from './components/DispatchSection'
import VehicleSection from './components/VehicleSection'

type Vehicle = NonNullable<GetPayrollDayVehiclesQuery['vehiclesByDate']>[number]

const ReconDatePage = () => {
  const graphQLClient = useGraphQLClient()
  const { paramValues } = useReconSearchParams()
  const dateId = paramValues.get('dateId')
  const vehicleDateId = paramValues.get('vehicleDateId')
  const vehicleDispatchId = paramValues.get('vehicleDispatchId')

  const date = dateId ?? Temporal.Now.plainDateISO().toString()

  const { data, isPending } = useQuery({
    queryKey: [GET_PAYROLL_DAY_VEHICLES_QUERY_KEY, date],
    queryFn: async () => await graphQLClient.request(GET_PAYROLL_DAY_VEHICLES, { date }),
  })

  const vehicles = data?.vehiclesByDate ?? ([] as Vehicle[])

  const vehicle = vehicles.find(vehicle => vehicle.vehicleDate?.id === vehicleDateId)
  const selectedVehicleDispatch = !vehicleDispatchId
    ? null
    : vehicle?.vehicleDate?.vehicleDispatchAssignments?.find(assignment => assignment.id === vehicleDispatchId)
  const workOrderId = selectedVehicleDispatch?.dispatch?.workOrderId ?? undefined

  const title = `Daily Recon for ${date}`
  return (
    <ReconAppPage header={title} isLoading={isPending}>
      <title>{title} - Thermal Shop Admin</title>

      <PageContainer>
        {!vehicle ? 'Selected Vehicle' : <VehicleSection vehicle={vehicle} />}

        {!workOrderId ? null : <ReconDispatch workOrderId={workOrderId} dateString={date} />}
      </PageContainer>
    </ReconAppPage>
  )
}

export default ReconDatePage

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  position: relative;
  flex: 1 1 auto;
  max-width: 100%;
`
