import { graphql } from '@/gql'

export const GET_PAYROLL_DAY_VEHICLES_QUERY_KEY = 'GetPayrollDayVehicles'
export const GET_PAYROLL_DAY_VEHICLES = graphql(/* GraphQL */ `
  query GetPayrollDayVehicles($date: Date!) {
    vehiclesByDate(date: $date) {
      id
      name
      make
      model
      year
      vin
      licensePlate
      color
      isGhost

      vehicleDate {
        id
        sortedVehicleDispatchIds

        vehicleDispatchAssignments {
          id
          dispatch {
            workOrderId
            workOrder {
              id
              name
              tripName
              projectSiteAddress
              isDavisBacon
              customerName
              jobId
              estimateId
            }
          }

          installerAssignments {
            id
            installerId
            isApproved
            isCompleted
            installer {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`)

export default GET_PAYROLL_DAY_VEHICLES
