import { motion } from 'framer-motion'
import { useAtomValue } from 'jotai'
import { useRef } from 'react'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'
import useTripsQuery, { TRIPS_TO_IGNORE } from '@/utils/queryHooks/useTripsQuery'
import { CardColorOptions, TRIP_COLORS } from '@/utils/utilities'

import { dispatchIdsByTripAtom, SidebarZone } from '../dispatchTypes'

import { useAvailableDispatchesCount } from './hooks/useDispatchDateQuery'
import useDropZoneSidebars from './hooks/useDropZoneSidebars'

import DraggableDispatchCard from './components/DraggableDispatchCard'
import SidebarDispatchesActions from './components/SidebarDispatchActions'
import SidebarScrollableList from './components/SidebarScrollableList'

const zone: SidebarZone = 'DISPATCH_SIDEBAR'
const SIDE = 'LEFT'

interface SidebarDispatchesProps {
  dateString: string
}
const SidebarDispatches = ({ dateString }: SidebarDispatchesProps) => {
  const sidebarRef = useRef<HTMLDivElement | null>(null)
  const dispatchCount = useAvailableDispatchesCount()
  const headerText = `${dispatchCount} Dispatch${dispatchCount === 1 ? '' : 'es'}`
  const dispatchIdsByTrip = useAtomValue(dispatchIdsByTripAtom)
  const dispatchIdsByTripDateString = (dispatchIdsByTrip?.dateString ?? null) as string | null
  const wrongDate = dateString !== dispatchIdsByTripDateString
  const { trips } = useTripsQuery()

  const isHovered = useDropZoneSidebars({ zone, sidebarRef })

  return (
    <SidebarScrollableList
      ref={sidebarRef}
      isHovered={isHovered}
      headerText={headerText}
      side={SIDE}
      noPadding
      actions={<SidebarDispatchesActions />}
    >
      {trips.length === 0 ? (
        <div>No Dispatches Scheduled</div>
      ) : (
        trips
          .filter(x => !TRIPS_TO_IGNORE.includes(x.name))
          .map(({ name: trip }) => {
            if (trip === 'dateString') return
            const dispatchIds = (dispatchIdsByTrip?.[trip] ?? []) as string[]
            const accentColor: CardColorOptions = TRIP_COLORS?.[trip] ?? 'orange'

            return (
              <TripWrapper key={trip}>
                <TripHeader $accentColor={accentColor} layout layoutScroll transition={{ duration: 0.2, ease: 'easeInOut' }}>
                  <span>
                    {trip} <span className='dispatch-count'>({wrongDate ? 0 : dispatchIds.length})</span>
                  </span>
                </TripHeader>

                <StyledDispatchCardsContainer layout layoutScroll transition={{ duration: 0.2 }}>
                  {wrongDate || !dispatchIds
                    ? null
                    : dispatchIds.map(dispatchId => <DraggableDispatchCard key={dispatchId} dispatchId={dispatchId} />)}
                </StyledDispatchCardsContainer>
              </TripWrapper>
            )
          })
      )}
    </SidebarScrollableList>
  )
}

export default SidebarDispatches

interface TripHeaderProps {
  $accentColor: CardColorOptions
}
const TripHeader = styled(motion.div)<TripHeaderProps>`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  background-color: ${({ $accentColor }) =>
    token(!$accentColor ? `elevation.surface.raised` : `color.background.accent.${$accentColor}.subtlest`)};
  align-self: stretch;
  border-bottom: 1px solid ${({ $accentColor }) => token(!$accentColor ? `color.border` : `color.border.accent.${$accentColor}`)};
  padding: 2px 24px 2px 32px;

  span {
    font-size: 12px;
    font-weight: ${token('font.weight.semibold')};
    text-transform: uppercase;
    color: ${({ $accentColor }) => token(!$accentColor ? `color.text.subtlest` : `color.text.accent.${$accentColor}`)};

    .dispatch-count {
      font-family: ${MONOSPACE_FONT_FAMILY};
      font-size: 11px;
      opacity: 0.6;
    }
  }
`
const TripWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
`

const StyledDispatchCardsContainer = styled(motion.div)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${token('space.050')};
  padding: 8px 24px 16px 32px;
  overflow: hidden;
`
