import { graphql } from '@/gql'

const SUBMIT_DISPATCH_ASSIGNMENT = graphql(/* GraphQL */ `
  mutation SubmitDispatchAssignment(
    $sortedVehicleDispatchIds: [String!]
    $date: Date!
    $dispatchId: UUID
    $installerId: UUID
    $targetVehicleDispatchId: UUID
    $targetVehicleId: UUID
    $originVehicleDispatchId: UUID
    $originVehicleId: UUID
    $action: DispatchAssignmentAction!
  ) {
    submitDispatchAssignment(
      sortedVehicleDispatchIds: $sortedVehicleDispatchIds
      date: $date
      dispatchId: $dispatchId
      installerId: $installerId
      targetVehicleDispatchId: $targetVehicleDispatchId
      targetVehicleId: $targetVehicleId
      originVehicleDispatchId: $originVehicleDispatchId
      originVehicleId: $originVehicleId
      action: $action
    ) {
      success
      message
    }
  }
`)

export default SUBMIT_DISPATCH_ASSIGNMENT
