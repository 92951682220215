import { useSetAtom } from 'jotai'
import { useCallback } from 'react'

import { accessTokenAtom, accessTokenExpirationAtom, refreshTokenAtom, refreshTokenExpirationAtom } from './appAtoms'

export const useSignOut = () => {
  const setAccessToken = useSetAtom(accessTokenAtom)
  const setAccessTokenExpiration = useSetAtom(accessTokenExpirationAtom)
  const setRefreshToken = useSetAtom(refreshTokenAtom)
  const setRefreshTokenExpiration = useSetAtom(refreshTokenExpirationAtom)
  const signOut = useCallback(() => {
    setAccessToken('')
    setAccessTokenExpiration('')
    setRefreshToken('')
    setRefreshTokenExpiration('')
  }, [setAccessToken, setAccessTokenExpiration, setRefreshToken, setRefreshTokenExpiration])
  return signOut
}
