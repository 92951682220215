import { Temporal } from '@js-temporal/polyfill'
import { format } from 'date-fns'
import { useAtom } from 'jotai'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { ButtonGroup } from '@atlaskit/button'
import { IconButton } from '@atlaskit/button/new'
import { DatePicker } from '@atlaskit/datetime-picker'
import { Label } from '@atlaskit/form'
import { token } from '@atlaskit/tokens'

import EditorCollapseIcon from '@atlaskit/icon/glyph/editor/collapse'
import EditorExpandIcon from '@atlaskit/icon/glyph/editor/expand'

import { onlyMondaysFilter } from '@/utils/utilities'

import { isWeeksExpandedAtom } from '../atoms'

import DownloadCalendarButton from './DownloadCalendarButton'
import ShowUnscheduledButton from './ShowUnscheduledButton'

const CalendarPageActions = ({ firstWeekDate, lastWeekDate }: { firstWeekDate: Temporal.PlainDate; lastWeekDate: Temporal.PlainDate }) => {
  const navigate = useNavigate()

  const [isWeeksExpanded, setIsWeeksExpanded] = useAtom(isWeeksExpandedAtom)

  const firstWeekDateString = firstWeekDate.toString()
  const lastWeekDateString = lastWeekDate.toString()

  return (
    <div style={{ display: 'flex', gap: 12, alignItems: 'flex-end' }}>
      <DateWrapper>
        <Label id='week-range-start' htmlFor='datepicker-format'>
          From
        </Label>
        <DatePicker
          appearance='subtle'
          spacing='compact'
          onChange={handleFirstWeekDateChange}
          value={firstWeekDateString}
          disabledDateFilter={onlyMondaysFilter}
          formatDisplayLabel={dateString => {
            const dateObj = new Date(dateString + 'T00:00:00')
            const formattedDate = format(dateObj, 'MMM do')
            return formattedDate
          }}
        />
      </DateWrapper>

      <DateWrapper>
        <Label id='week-range-end' htmlFor='datepicker-format'>
          To
        </Label>
        <DatePicker
          appearance='subtle'
          spacing='compact'
          onChange={handleLastWeekDateChange}
          value={lastWeekDateString}
          disabledDateFilter={onlyMondaysFilter}
          formatDisplayLabel={date => format(new Date(date), 'MMM do')}
        />
      </DateWrapper>

      <ButtonGroup label='Calendar Actions'>
        <DownloadCalendarButton />

        <div style={{ transform: 'rotate(90deg)' }}>
          <IconButton
            isSelected={isWeeksExpanded}
            icon={isWeeksExpanded ? EditorCollapseIcon : EditorExpandIcon}
            label={isWeeksExpanded ? 'Shrink Weeks' : 'Show All'}
            isTooltipDisabled={false}
            onClick={handleExpandWeeks}
          />
        </div>
        <ShowUnscheduledButton />
      </ButtonGroup>
    </div>
  )
  function handleFirstWeekDateChange(date: string) {
    navigate(`/calendar/${date}/${lastWeekDateString}`)
  }
  function handleLastWeekDateChange(date: string) {
    navigate(`/calendar/${firstWeekDateString}/${date}`)
  }
  function handleExpandWeeks() {
    setIsWeeksExpanded(!isWeeksExpanded)
  }
}

export default CalendarPageActions

const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -12px;
  /* gap: 4px; */
  > div {
    width: 80px;
  }
  > div > div > div > div:nth-child(2) {
    display: none;
  }
  label {
    color: ${token('color.text.disabled')};
    transition: color 0.1s;
    padding-left: 9px;
    margin-bottom: 0px;
  }

  &:hover {
    label {
      color: ${token('color.text.subtlest')};
    }
  }
`
