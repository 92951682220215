import { Temporal } from '@js-temporal/polyfill'
import { useAtom, useSetAtom } from 'jotai'
import { useCallback } from 'react'
import styled from 'styled-components'

import Button from '@atlaskit/button/new'
import { token } from '@atlaskit/tokens'

import { MONTH_NAMES_LIST } from '@/utils/constants'

import { dateStringSelectedAtom, workOrderSelectedAtom } from '../atoms'
import { CALENDAR_DAY_HEADER_HEIGHT } from '../types'

interface CalendarDayHeaderProps {
  date: Temporal.PlainDate
  today: Temporal.PlainDate
}

const CalendarDayHeader = ({ date, today }: CalendarDayHeaderProps) => {
  const setWorkOrderSelected = useSetAtom(workOrderSelectedAtom)
  const [dateStringSelected, setDateStringSelected] = useAtom(dateStringSelectedAtom)

  const isToday = date.day === today.day && date.month === today.month && date.year === today.year
  const isPast = Temporal.PlainDate.compare(date, today) < 0
  const monthName = MONTH_NAMES_LIST[date.month - 1]

  const dateString = date.toString()
  const isSelected = dateStringSelected === dateString

  const isLastDayOfMonth = date.daysInMonth === date.day
  const isSaturday = date.dayOfWeek === 6
  const boldBorderRight = isLastDayOfMonth && !isSaturday

  const handleSelectDay = useCallback(() => {
    if (isSelected) setDateStringSelected(null)
    else {
      setDateStringSelected(dateString)
      setWorkOrderSelected(null)
    }
  }, [isSelected, setDateStringSelected, dateString, setWorkOrderSelected])

  return (
    <DayHeader className='eh-day-cell' $isPast={isPast} $isSelected={isSelected} $isToday={isToday} $boldBorderRight={boldBorderRight}>
      <div className='eh-month-name'>{date.day === 1 ? monthName : date.dayOfWeek === 7 ? monthName.slice(0, 3) : ''}</div>

      <Button
        onClick={handleSelectDay}
        spacing='compact'
        appearance={isSelected ? 'primary' : 'subtle'}
        isSelected={!isSelected && isToday}
      >
        {date.day}
      </Button>
    </DayHeader>
  )
}

export default CalendarDayHeader

interface DayHeaderProps {
  $isToday: boolean
  $isPast?: boolean
  $isSelected?: boolean
  $boldBorderRight?: boolean
}
const DayHeader = styled.div<DayHeaderProps>`
  box-sizing: border-box;
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1 1 0%;
  align-items: baseline;
  padding-left: ${token('space.050')};

  transition: background 0.08s;
  background: ${({ $isPast, $isSelected }) =>
    $isSelected ? token('color.background.selected') : $isPast ? token('elevation.surface.sunken') : token('elevation.surface')};

  overflow: hidden;

  height: ${CALENDAR_DAY_HEADER_HEIGHT}px;
  max-height: ${CALENDAR_DAY_HEADER_HEIGHT}px;

  z-index: 2;

  font-size: 20px;
  font-weight: ${({ $isToday }) => ($isToday ? '700' : '500')};
  color: ${({ $isToday, $isPast }) =>
    $isToday ? token('color.text.brand') : $isPast ? token('color.text.disabled') : token('color.text.subtlest')};

  /* ${({ $boldBorderRight }) => ($boldBorderRight ? `border-right:1px solid ${token('color.border.bold')}` : 'none')}; */
  > button {
    padding-right: 6px;
    padding-left: 7px;
    padding-top: 6px;
    padding-bottom: 6px;
    transition: background-color 120ms;
    font-size: 20px;
    line-height: 20px;
    font-weight: ${({ $isToday }) => ($isToday ? '700' : '500')};
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 3px;
    height: auto;
    min-width: 32px;

    color: ${({ $isToday, $isPast, $isSelected }) =>
      $isSelected
        ? token('color.text.inverse')
        : $isToday
          ? token('color.text.brand')
          : $isPast
            ? token('color.text.disabled')
            : token('color.text.subtlest')};
  }

  .eh-month-name {
    flex: 1;
    flex-shrink: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 2px;
    color: ${({ $isToday }) => ($isToday ? token('color.text.brand') : token('color.text.disabled'))};
  }
`
