import { useQuery } from '@tanstack/react-query'
import { ConfigProvider, Table } from 'antd'
import styled from 'styled-components'

import { token } from '@atlaskit/tokens'

import { MONOSPACE_FONT_FAMILY } from '@/utils/constants'
import useAntdTableTheme from '@/utils/useAntdTableTheme'
import useGraphQLClient from '@/utils/useAuthRequest'
import { formatCurrencyString } from '@/utils/utilities'

import { GetDispatchPayoutDetailsQuery } from '@/gql/graphql'

import { graphql } from '@/gql'

import DispatchPayoutTopTable from './DispatchPayoutTopTable/DispatchPayoutTopTable'
import InstallersTable from './InstallersTable/InstallersTable'

import type { TableProps } from 'antd'

const ReconDispatch = ({ workOrderId, dateString }: { workOrderId: string | undefined; dateString: string }) => {
  const graphQLClient = useGraphQLClient()
  const theme = useAntdTableTheme()

  const { data } = useQuery({
    queryKey: [GET_DISPATCH_PAYOUT_DETAILS_QUERY_KEY, workOrderId],
    queryFn: async () => {
      if (!workOrderId) throw new Error('Work Order ID is required')
      const response = await graphQLClient.request(GET_DISPATCH_PAYOUT_DETAILS, {
        workOrderId,
      })
      if (!response.workOrder) throw new Error('Work Order not found')
      return response
    },
    enabled: !!workOrderId,
  })

  const workOrder = data?.workOrder
  const dispatches = (workOrder?.dispatches ?? []).sort((a, b) => a.date.localeCompare(b.date))

  const selectedDispatch = dispatches.find(dispatch => dispatch.date === dateString)
  const isSomeCompleted = selectedDispatch?.installerAssignments.some(assignment => assignment.isCompleted)

  const workItems = workOrder?.workItems ?? []

  return (
    <>
      <DispatchPayoutTopTable workOrder={workOrder} dateString={dateString} isSomeCompleted={isSomeCompleted === true} />

      <hr />

      {selectedDispatch && <InstallersTable dispatch={selectedDispatch} isDavisBacon={workOrder?.isDavisBacon === true} />}

      <h4 style={{ paddingBottom: 8 }}>Work Order Items</h4>

      <AntdTableWrapper>
        <ConfigProvider theme={theme}>
          <Table<WorkItemType>
            size='small'
            dataSource={workItems}
            columns={workOrderItemsColumns}
            rowKey='id'
            rowClassName={(item, index) => {
              let className = index % 2 === 1 ? 'alt-row-style' : 'main-row-style'
              if (item.materialName.toLowerCase().includes('total')) className = className + ' total-row-style'
              return className
            }}
            pagination={false}
          />
        </ConfigProvider>
      </AntdTableWrapper>
    </>
  )
}

export default ReconDispatch

type WorkItemType = NonNullable<GetDispatchPayoutDetailsQuery['workOrder']>['workItems'][number]

const workOrderItemsColumns: TableProps<WorkItemType>['columns'] = [
  {
    key: 'workAreaName',
    dataIndex: 'workAreaName',
    title: 'Work Area',
  },
  {
    key: 'materialName',
    dataIndex: 'materialName',
    title: 'Material',
  },

  {
    key: 'quantity',
    dataIndex: 'quantity',
    title: 'Qty',
    align: 'right',
    className: 'right mono',
  },
  {
    key: 'unitOfMeasure',
    dataIndex: 'unitOfMeasure',
    title: 'Unit',
  },
  {
    key: 'needed',
    dataIndex: 'containersNeededFinal',
    title: 'Needed',
    align: 'right',
    className: 'right mono',
  },
  {
    key: 'containerLabel',
    dataIndex: 'containerLabel',
    title: 'Cont',
  },
  {
    key: 'laborCost',
    dataIndex: 'laborCostFinal',
    title: 'Labor Cost',
    align: 'right',
    className: 'right mono',

    render: text => formatCurrencyString(text),
  },
]

const AntdTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 100%;
  margin-bottom: 24px;
  padding-bottom: 24px;
  align-self: flex-start;
  overflow-x: auto;
  flex: 0 0 auto;

  .alt-row-style {
    background-color: ${token('color.background.neutral')};
  }

  .mono {
    font-family: ${MONOSPACE_FONT_FAMILY};
  }

  .ant-table {
    font-size: 13px !important;
    margin-bottom: 48px !important;
  }
`

export const GET_DISPATCH_PAYOUT_DETAILS_QUERY_KEY = 'GetDispatchPayoutDetails'
const GET_DISPATCH_PAYOUT_DETAILS = graphql(/* GraphQL */ `
  query GetDispatchPayoutDetails($workOrderId: ID!) {
    workOrder(pk: $workOrderId) {
      id
      name
      tripName
      tripId
      isDavisBacon

      totalMinutesWorked

      totalPaidAddons
      totalPaidPieceRate
      totalPaidLaborTotal
      totalPaidDavisBacon
      totalPaidPieceRateDavisBaconGap
      totalPaidDrivePay

      totalEnteredPieceRate
      totalEnteredDrivePay
      totalEnteredAddons

      remainingAddons
      remainingDrivePay
      remainingPieceRate
      remainingLaborTotal

      projectedAddons
      projectedDrivePay
      projectedPieceRate
      projectedLaborTotal

      estimateTitle
      estimateId
      jobId
      jobTitle
      projectSiteAddress
      customerName
      marginPercent

      dispatches {
        id
        sid
        date

        totalMinutesWorked
        percentPieceRateAllocated

        enteredPieceRate
        enteredAddons
        enteredPayTotal

        totalPaidHourly
        totalPaidAddons
        totalPaidDrivePay
        totalPaidPieceRate
        totalPaidPieceRateDavisBaconGap
        totalPaidDavisBacon
        totalPaidLaborTotal

        workOrderId

        vehicleAssignments {
          id
          sid
          vehicleDateId
        }

        payoutAddons {
          id
          reason
          payoutAmount
          note
        }

        installerAssignments {
          id
          sid

          isHourlyPayout
          isHourlyInstaller
          isDavisBacon
          isPayrollLocked

          calculatedMinutesOnSite

          payoutDrive
          payoutHourly

          payoutAddons
          canHourlyReceiveAddons
          calculatedPayoutPieceRate
          calculatedPayoutAddons
          payoutPieceRate
          payoutDavisBacon
          payoutPieceRateDavisBaconGap

          payoutTotal

          minutesWorked

          hourlyRate
          minutesOnSite

          minutesDriveActual
          calculatedMinutesDriveBuffer
          minutesDrivePayable
          minutesDrivePayableOverride
          hourlyRateDrive

          percentPieceRate
          isPercentLocked

          isCompleted
          isApproved

          vehicleName
          vehicleColor

          installerId
          installer {
            id
            sid
            isDriver
            isHourly
            hourlyRate
            fullName
          }
        }
      }
      workItems {
        id
        quantity
        containersNeededFinal
        isVisibleOnWorkOrder
        workAreaName
        tripName
        materialName
        materialNote
        itemCode
        containerLabel
        containersNeededFinal
        unitOfMeasure
        laborCostFinal
      }
    }
  }
`)
