import Tooltip from '@atlaskit/tooltip'

import HrMinCell, { HrMinCellValue } from '@/components/HrMinCell'
import NumberCell, { NumberCellValue } from '@/components/NumberCell'

import { PAYROLL_ROWS } from '../hooks/reconPayrollRowDefs'
import { usePayrollRecord } from '../hooks/useReconPayrollSelectors'
import { PayrollValueSelectorFn, ReconTableRowDef, ReconTableRowId, UsedWeeklyInstallerPayrollField } from '../reconPayrollTypes'

const rowsById = PAYROLL_ROWS.reduce(
  (acc, row) => {
    acc[row.id] = row
    return acc
  },
  {} as Record<string, ReconTableRowDef>
)

interface PayrollCellProps {
  id: ReconTableRowId
  installerId: string
  isLookup?: boolean
}

const PayrollCell = ({ id, installerId, isLookup = false }: PayrollCellProps): React.ReactNode => {
  const rowDef = rowsById[id]
  const selector = getSelector({ rowDef, isLookup })

  const renderFn = rowDef?.installerPayrollCellFn

  const installerPayroll = usePayrollRecord({ installerId })
  const value = !installerPayroll ? 'NO_PAYROLL' : selector(installerPayroll)
  const hideTooltip = !isLookup || !rowDef?.lookupTooltip

  if (renderFn && installerPayroll) {
    const rendered = renderFn(installerId, value, installerPayroll)
    if (hideTooltip) return rendered
    else
      return (
        <Tooltip content={rowDef.lookupTooltip} position='right-start'>
          {rendered}
        </Tooltip>
      )
  }
  if (rowDef.fieldType === 'hrMin') {
    return <HrMinCell value={value as HrMinCellValue} />
  }
  const fieldType = rowDef.fieldType ?? 'dollarAmount'
  const prefix = ['dollarAmount', 'hourlyRate'].includes(fieldType) ? '$' : undefined
  const suffix = fieldType === 'hourlyRate' ? '/ hr' : undefined
  const decimalPlaces = fieldType === 'int' ? 0 : 2
  return <NumberCell value={value as NumberCellValue} prefix={prefix} suffix={suffix} decimalPlaces={decimalPlaces} readOnly />
}

export default PayrollCell

interface GetSelectorFnProps {
  rowDef: ReconTableRowDef
  isLookup: boolean
}
function getSelector({ rowDef }: GetSelectorFnProps) {
  const fieldKey = rowDef.fieldKey ?? rowDef.id

  const fieldKeyLookup: PayrollValueSelectorFn = row => row.installerWeeklyPayroll?.[fieldKey as UsedWeeklyInstallerPayrollField] ?? null
  const selector = rowDef?.installerPayrollAccessorFn ?? fieldKeyLookup

  return selector as PayrollValueSelectorFn
}
