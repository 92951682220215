import styled from 'styled-components'

import Lozenge from '@atlaskit/lozenge'
import { token } from '@atlaskit/tokens'

import type { Staff } from '@/utils/queryHooks/useStaffListQuery'

interface InstallerTimecardTableNameCellProps {
  installer: Staff
}
const InstallerTimecardTableNameCell = ({ installer }: InstallerTimecardTableNameCellProps) => {
  return (
    <InstallersTableCell>
      <div>
        <span>{installer.fullName}</span>
      </div>
      <Lozenge appearance={installer.isHourly ? 'new' : 'moved'}>{installer.isHourly ? 'Hr' : 'P'}</Lozenge>
    </InstallersTableCell>
  )
}

export default InstallerTimecardTableNameCell

const InstallersTableCell = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  > span {
    flex-shrink: 0;
  }
  > div {
    flex: 1 1 auto;
    margin-right: 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: ${token('color.text')} !important;
      font-weight: 500;
    }
  }
`
